import React, { useContext } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ModelsContext from '../contexts/ModelsContext';
import create from '../_blueprints/create';
import update from '../_blueprints/update';
import update2 from '../_blueprints/update2';
import updateSingleton from '../_blueprints/updateSingleton';
import destroy from '../_blueprints/destroy';
import destroy2 from '../_blueprints/destroy2';

export function useQueryMutation(key, currentPayload, extra = {}) {
  const models = useContext(ModelsContext);
  const queryClient = useQueryClient();

  const [model, method] = key.split('.');

  const Model = models[model];

  const blueprint = {
    create: create,
    update: update,
    update2: update2,
    updateSingleton: updateSingleton,
    destroy: destroy,
    destroy2: destroy2
  }[method];

  const mutationParams = blueprint(key, {
    Model,
    queryClient,
    modelName: model,
    currentPayload: currentPayload
  });

  const mutation = useMutation({
    ...mutationParams,
    ...extra
  });

  // console.log('useMutation:after');
  // console.log(mutation);

  return {
    ...mutation,
    saving: mutation.isLoading,
    success: mutation.isSuccess,
    request: (
      mutation.isLoading ? mutation.context?.optimisticPayload :
      mutation.isError ? mutation.error :
      mutation.isSuccess ? mutation.data :
      null
    )
  };

  // return {
  //   ...(mutation.error || mutation.data),
  //   _isLoading: mutation.isLoading,
  //   _isError: mutation.isError,
  //   _isSuccess: mutation.isSuccess
  // };
}
