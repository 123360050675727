import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import { useConnect } from '@lore/query-connect';
import Network from './Network';
import { stringify } from 'query-string';
import usePageRouter from '../../../hooks/@lore/dialogs-routable/_hooks/usePageRouter';
import FormTemplate from '../../dialogs_common/FormTemplate';
import { NoSymbolIcon } from '@heroicons/react/24/outline';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';

Dialog.propTypes = {
  networkId: PropTypes.number
};

export default function Dialog(props) {
  const { networkId } = props;

  const { location, history } = usePageRouter();
  const currentUser = useUser();

  const networks = useConnect('network.find', {
    where: {
      eager: {
        $where: {
          'networkStewards.userId': !currentUser.data.isStaff ? currentUser.id : undefined
        }
      }
    }
  });

  return (
    <DialogTemplate className="w-screen max-w-screen-sm">
      <FormTemplate
        className="pb-8"
        title="Switch Network"
        subtitle={(
          <>
          <span className="block">
            Select the network you want to view.
          </span>
          </>
        )}
        onClose={props.onClose}
        showClose={true}
        body={(
          <div className="flex flex-col space-y-2">
            {currentUser.data.isStaff && (
              <Network
                key="no-network"
                selected={!networkId}
                network={{
                  data: {
                    name: 'None',
                    city: 'Nationwide',
                    state: 'USA'
                  }
                }}
                icon={(
                  <NoSymbolIcon className="w-12 h-12 object-contain text-cs-gray-400" />
                )}
                onClick={network => history.push({
                  pathname: location.pathname
                })}
              />
            )}
            {networks.state === PayloadStates.FETCHING ? (
              <Loader />
            ) : (
              <>
                {_.sortBy(networks.data, a => a.data.name).map(function(network) {
                  return (
                    <Network
                      key={network.id || network.cid}
                      network={network}
                      selected={networkId === network.id}
                      onClick={network => history.push({
                        pathname: location.pathname,
                        search: stringify({
                          networks: network.id
                        })
                      })}
                    />
                  );
                })}
              </>
            )}
          </div>
        )}
      />
    </DialogTemplate>
  );
};
