import React, { useState } from 'react';
import moment from 'moment-timezone';
import { PayloadStates } from '@lore/utils';
import PageHeading from '../../@communityshare/ui/PageHeading';
import ParentLayout from '../dashboard/Layout';
import Loader from '../../components/Loader';
import { Helmet } from 'react-helmet-async';

import useProjectsMapping from '../feed-filtered/projects/useMapping';
import useProjectNotesMapping from '../feed-filtered/project-notes/useMapping';
import useQuestionsMapping from '../feed-filtered/questions/useMapping';
import useEventsMapping from '../feed-filtered/events/useMapping';
import useEventNotesMapping from '../feed-filtered/event-notes/useMapping';
import useInvitationsMapping from '../feed-filtered/invitations/useMapping';
import useOffersMapping from '../feed-filtered/offers/useMapping';
import useOfferNotesMapping from '../feed-filtered/offer-notes/useMapping';
import useClaimsMapping from '../feed-filtered/claims/useMapping';

import usePartnersMapping from '../feed-filtered/partners/useMapping';
import usePartnerNotesMapping from '../feed-filtered/partner-notes/useMapping';
import useEducatorsMapping from '../feed-filtered/educators/useMapping';
import useEducatorNotesMapping from '../feed-filtered/educator-notes/useMapping';

import useEvaluationsProjectEducatorMapping from '../feed-filtered/evaluations-project-educator/useMapping';
import useEvaluationsProjectPartnerMapping from '../feed-filtered/evaluations-project-partner/useMapping';
import useEvaluationsEventEducatorMapping from '../feed-filtered/evaluations-event-educator/useMapping';
import useEvaluationsEventPartnerMapping from '../feed-filtered/evaluations-event-partner/useMapping';

import useMessagesMapping from '../feed-filtered/messages/useMapping';
import useQueryMeta from './useQueryMeta';
import { OutlineButton } from '../../@communityshare/ui/Button';
import MobileNavigation from './MobileNavigation';
import { PresentationChartLineIcon } from '@heroicons/react/24/outline';
import EmptyState from '../../@communityshare/ui/EmptyState';

const increment = 10;

export default function Layout(props) {
  const [maxCount, setMaxCount] = useState(increment);
  const { network, params, pagination } = useQueryMeta();

  const partners = usePartnersMapping({ network, params, pagination });
  const partnerNotes = usePartnerNotesMapping({ network, params, pagination });
  const educators = useEducatorsMapping({ network, params, pagination });
  const educatorNotes = useEducatorNotesMapping({ network, params, pagination });

  const projects = useProjectsMapping({ network, params, pagination });
  const projectNotes = useProjectNotesMapping({ network, params, pagination });
  const questions = useQuestionsMapping({ network, params, pagination });

  const events = useEventsMapping({ network, params, pagination });
  const eventNotes = useEventNotesMapping({ network, params, pagination });
  const invitations = useInvitationsMapping({ network, params, pagination });

  const offers = useOffersMapping({ network, params, pagination });
  const offerNotes = useOfferNotesMapping({ network, params, pagination });
  const claims = useClaimsMapping({ network, params, pagination });

  const messages = useMessagesMapping({ network, params, pagination });

  const evaluationProjectEducators = useEvaluationsProjectEducatorMapping({ network, params, pagination });
  const evaluationProjectPartners = useEvaluationsProjectPartnerMapping({ network, params, pagination });
  const evaluationEventEducators = useEvaluationsEventEducatorMapping({ network, params, pagination });
  const evaluationEventPartners = useEvaluationsEventPartnerMapping({ network, params, pagination });

  if (
    projectNotes.models.state === PayloadStates.FETCHING ||
    eventNotes.models.state === PayloadStates.FETCHING ||
    offerNotes.models.state === PayloadStates.FETCHING ||
    projects.models.state === PayloadStates.FETCHING ||
    events.models.state === PayloadStates.FETCHING ||
    offers.models.state === PayloadStates.FETCHING ||
    questions.models.state === PayloadStates.FETCHING ||
    invitations.models.state === PayloadStates.FETCHING ||
    claims.models.state === PayloadStates.FETCHING ||
    partners.models.state === PayloadStates.FETCHING ||
    partnerNotes.models.state === PayloadStates.FETCHING ||
    educators.models.state === PayloadStates.FETCHING ||
    educatorNotes.models.state === PayloadStates.FETCHING ||
    evaluationProjectEducators.models.state === PayloadStates.FETCHING ||
    evaluationProjectPartners.models.state === PayloadStates.FETCHING ||
    evaluationEventEducators.models.state === PayloadStates.FETCHING ||
    evaluationEventPartners.models.state === PayloadStates.FETCHING ||
    messages.models.state === PayloadStates.FETCHING
  ) {
    return (
      <ParentLayout>
        <div className="p-8 space-y-8">
          <Helmet>
            <title>Feed: All</title>
          </Helmet>
          <PageHeading
            breadcrumbs={['Feed']}
            title="All activity"
            description="List of notes, projects, events, offers, invitations, evaluations, and messages created over the last 4 weeks."
          />
          <Loader />
        </div>
      </ParentLayout>
    );
  }

  let items = [].concat(
    projects.entries,
    partners.entries,
    educators.entries,
    partnerNotes.entries,
    educatorNotes.entries,
    projectNotes.entries,
    eventNotes.entries,
    offerNotes.entries,
    events.entries,
    offers.entries,
    questions.entries,
    invitations.entries,
    claims.entries,
    evaluationProjectEducators.entries,
    evaluationProjectPartners.entries,
    evaluationEventEducators.entries,
    evaluationEventPartners.entries,
    messages.entries
  );

  items = _.sortBy(items, function(item) {
    return -moment(item.timestamp).unix();
  });

  const visibleItems = items.slice(0, maxCount);

  return (
    <ParentLayout>
      <div className="p-8 space-y-8">
        <Helmet>
          <title>Feed: All</title>
        </Helmet>
        <MobileNavigation />
        <PageHeading
          breadcrumbs={['Feed']}
          title="All activity"
          description="List of notes, projects, events, offers, invitations, evaluations, and messages created over the last 4 weeks."
        />
        {visibleItems.length === 0 && (
          <EmptyState
            icon={PresentationChartLineIcon}
            title="There is currently no matching activity."
            subtitle="But check back later and there might be."
          />
        )}
        {visibleItems.length > 0 && (
          <div className="max-w-3xl">
            <ul role="list" className="space-y-6">
              {visibleItems.map(function(item, index) {
                const last = items.length === (index + 1);
                return React.cloneElement(item.value, {
                  last: last
                });
              })}
              {maxCount < items.length && (
                <OutlineButton onClick={() => setMaxCount(maxCount + increment)}>
                  Show more ({items.length - visibleItems.length})
                </OutlineButton>
              )}
            </ul>
          </div>
        )}
      </div>
    </ParentLayout>
  );
}
