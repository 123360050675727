import { useUser } from '@lore/auth';
import usePageRouter from '../../../../hooks/@lore/dialogs-routable/_hooks/usePageRouter';
import useNetwork from '../../../hooks/useNetwork';
import getTimeFrameNavigationLinks from './timeFrameNavigationLinks';
import getFeedNavigationLinks from './pageNavigationLinks';

export default function useNavigationLinks(props) {
  const { location } = usePageRouter();

  const network = useNetwork();
  const currentUser = useUser();

  const timeFrameNavigationLinks = getTimeFrameNavigationLinks(network, currentUser);

  const currentTimeFrameNavigationLink = _.find(timeFrameNavigationLinks, function(timeFrame) {
    // return _.startsWith(location.pathname, timeFrame.href);
    const tokens = location.pathname.split(timeFrame.href);
    const remainder = tokens[1];
    return remainder ? remainder[0] === '/' : false;
  });

  const pageNavigationLinks = currentTimeFrameNavigationLink ?
    getFeedNavigationLinks(network, currentUser, currentTimeFrameNavigationLink) :
    [];

  return {
    pageLinks: pageNavigationLinks,
    timeFrameLinks: timeFrameNavigationLinks,
    currentTimeFrameLink: currentTimeFrameNavigationLink
  };
}
