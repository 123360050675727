import React from 'react';
import { useConnect } from '@lore/query-connect';
import PROJECT_REQUIRED_FIELDS from '../../projects/REQUIRED_FIELDS';
import EVENT_REQUIRED_FIELDS from '../../events/REQUIRED_FIELDS';
import { PayloadStates } from '@lore/utils';
import MetricLoader from '../MetricLoader';
import DeltaMetric from '../DeltaMetric';

export default function TotalAssociatedWithProjectOrEvent(props) {
  const { network, schoolStart, schoolEnd, titleOnly } = props;

  const allSchools = useConnect('site.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfProjects',
            relation: 'users.projects',
            $where: {
              $and: PROJECT_REQUIRED_FIELDS.$and,
              'creator.isEducator': true,
              networkId: network?.id
            }
          },
          {
            type: 'count',
            alias: 'numberOfEvents',
            relation: 'users.events',
            $where: {
              $and: EVENT_REQUIRED_FIELDS.$and,
              'creator.isEducator': true,
              networkId: network?.id
            }
          }
        ],
        $where: {
          $or: [
            {
              numberOfProjects: {
                $gte: 1
              }
            },
            {
              numberOfEvents: {
                $gte: 1
              }
            }
          ],
          createdAt: {
            $lte: schoolEnd
          }
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  const newSchools = useConnect('site.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfProjects',
            relation: 'users.projects',
            $where: {
              $and: PROJECT_REQUIRED_FIELDS.$and,
              'creator.isEducator': true,
              networkId: network?.id
            }
          },
          {
            type: 'count',
            alias: 'numberOfEvents',
            relation: 'users.events',
            $where: {
              $and: EVENT_REQUIRED_FIELDS.$and,
              'creator.isEducator': true,
              networkId: network?.id
            }
          }
        ],
        $where: {
          $or: [
            {
              numberOfProjects: {
                $gte: 1
              }
            },
            {
              numberOfEvents: {
                $gte: 1
              }
            }
          ],
          createdAt: {
            $lte: schoolEnd,
            $gte: schoolStart
          }
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  if (
    allSchools.state === PayloadStates.FETCHING ||
    newSchools.state === PayloadStates.FETCHING
  ) {
    return (
      <MetricLoader
        name="Total engaged with Project or Event"
      />
    );
  }

  return (
    <DeltaMetric
      name="Total engaged with Project or Event"
      value={allSchools.meta.totalCount}
      delta={newSchools.meta.totalCount}
      titleOnly={titleOnly}
    />
  );
}
