import React, { useState } from 'react';
import _ from 'lodash';
import { PayloadStates } from '@lore/utils';
import moment from 'moment-timezone';
import colors from 'tailwindcss/colors';
import LineChart from '../../dashboard/_charts/LineChart';
import ChartLoader from '../../dashboard/_charts/ChartLoader';
import useSchools from './_hooks/useSchools';
import { useConnect } from '@lore/query-connect';
import PROJECT_REQUIRED_FIELDS from '../../projects/REQUIRED_FIELDS';
import EVENT_REQUIRED_FIELDS from '../../events/REQUIRED_FIELDS';

export default function SchoolCumulativeGrowthByMonth(props) {
  const { network, timestamps, empty, schoolStart } = props;

  const [currentTimestamp] = useState(moment().toISOString());

  const schools = timestamps.map(function(timestamp, index) {
    if (moment(currentTimestamp).diff(timestamp.start) < 0) {
      return {
        ...timestamp,
        results: {
          schools: empty(),
          engaged: empty()
        }
      }
    }

    return {
      ...timestamp,
      results: {
        schools: useSchools(network, undefined, timestamp.end),
        engaged: useConnect('site.find', {
          where: {
            eager: {
              $aggregations: [
                {
                  type: 'count',
                  alias: 'numberOfProjects',
                  relation: 'projects',
                  $where: {
                    $and: PROJECT_REQUIRED_FIELDS.$and,
                    // 'creator.isEducator': true,
                    networkId: network?.id,
                    createdAt: {
                      $lte: timestamp.end,
                      // $gte: titleOnly ? undefined : previousSchoolStart
                    }
                  }
                },
                {
                  type: 'count',
                  alias: 'numberOfEvents',
                  relation: 'events',
                  $where: {
                    $and: EVENT_REQUIRED_FIELDS.$and,
                    // 'creator.isEducator': true,
                    networkId: network?.id,
                    createdAt: {
                      $lte: timestamp.end,
                      // $gte: titleOnly ? undefined : previousSchoolStart
                    }
                  }
                }
              ],
              $where: {
                $or: [
                  {
                    numberOfProjects: {
                      $gte: 1
                    }
                  },
                  {
                    numberOfEvents: {
                      $gte: 1
                    }
                  }
                ]
              }
            }
          },
          pagination: {
            pageSize: 1
          }
        })
      }
    }
  });

  const loading = _.reduce(schools, function(result, data) {
    return (
      result ||
      data.results.schools.state === PayloadStates.FETCHING ||
      data.results.engaged.state === PayloadStates.FETCHING
    );
  }, false);

  if (loading) {
    return (
      <ChartLoader
        title="Sites in your network"
        subtitle=""
      />
    );
  }

  const series = [
    {
      name: 'New Sites',
      data: schools.map(e => e.results.schools.meta.totalCount)
    },
    {
      name: 'Engaged with Project or Event',
      data: schools.map(e => e.results.engaged.meta.totalCount)
    }
  ];

  return (
    <LineChart
      title="Sites in your network"
      subtitle="Over school year"
      labels={schools.map(e => e.label)}
      series={series}
      colors={[
        colors.blue['400'],
        colors.emerald['400'],
        colors.yellow['400']
      ]}
    />
  );
}
