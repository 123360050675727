import React from 'react';
import { useConnect } from '@lore/query-connect';

export default function useCompletedEducatorProjectEvaluations(network, schoolStart, schoolEnd) {
  return useConnect('evaluationProjectEducator.find', {
    where: {
      eager: {
        $where: {
          completed: true,
          networkId: network?.id,
          createdAt: {
            $lte: schoolEnd,
            $gte: schoolStart
          }
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });
}
