import React from 'react';
import NavigationDropdown from '../dashboard/NavigationDropdown';
import NavigationLabel from '../_navigation/ThickNavigation/NavigationLabel';
import NavigationGroup from '../_navigation/ThickNavigation/NavigationGroup';
import LayoutSidebar from './LayoutSidebar';
import useNavigationLinks from '../states/Sidebar/useNavigationLinks';
import NavigationDropdownState from '../dashboard/NavigationDropdownState';

export default function LayoutSidebarDashboard(props) {
  const { children } = props;

  const { schoolYearLinks, pageLinks } = useNavigationLinks();

  return (
    <LayoutSidebar
      title="States"
      header={(
        <>
          <NavigationGroup>
            <NavigationLabel>
              School Year
            </NavigationLabel>
            <div className="-mx-3">
              <NavigationDropdown
                header="Switch school year to"
                navigationLinks={schoolYearLinks}
              />
            </div>
          </NavigationGroup>
          <NavigationGroup>
            <NavigationLabel>
              State
            </NavigationLabel>
            <div className="-mx-3">
              <NavigationDropdownState
                header="Switch state to"
                navigationLinks={pageLinks}
              />
            </div>
          </NavigationGroup>
        </>
      )}
      // getNavigationLinks={() => pageLinks}
      getNavigationLinks={() => []}
    >
      {children}
    </LayoutSidebar>
  );
}
