import useGetCount from '../useGetCount';

import messages from '../../feed-filtered/messages/useQuery';
import projects from '../../feed-filtered/projects/useQuery';
import events from '../../feed-filtered/events/useQuery';
import offers from '../../feed-filtered/offers/useQuery';
import invitationsAll from '../../feed-filtered/invitations-all/useQuery';
import partners from '../../feed-filtered/partners/useQuery';
import educators from '../../feed-filtered/educators/useQuery';
import notesAll from '../../feed-filtered/notes-all/useQuery';
import evaluationsAll from '../../feed-filtered/evaluations-all/useQuery';

export default function(network, currentUser, currentNavigationLink) {
  const root = `${currentNavigationLink.href}`;

  return [
    {
      name: `All`,
      href: `${root}/all`
    },
    {
      name: `Messages`,
      href: `${root}/messages`,
      count: useGetCount(messages)
    },
    {
      name: `Projects`,
      href: `${root}/projects`,
      count: useGetCount(projects)
    },
    {
      name: `Events`,
      href: `${root}/events`,
      count: useGetCount(events)
    },
    {
      name: `Offers`,
      href: `${root}/offers`,
      count: useGetCount(offers)
    },
    {
      name: `Invitations`,
      href: `${root}/invitations`,
      count: useGetCount(invitationsAll)
    },
    {
      name: `Partners`,
      href: `${root}/partners`,
      count: useGetCount(partners)
    },
    {
      name: `Educators`,
      href: `${root}/educators`,
      count: useGetCount(educators)
    },
    {
      name: `Notes`,
      href: `${root}/notes`,
      count: useGetCount(notesAll)
    },
    {
      name: `Evaluations`,
      href: `${root}/evaluations`,
      count: useGetCount(evaluationsAll)
    }
  ];
};
