import moment from 'moment-timezone';
import { useConfig } from '@lore/config';
import getYear from './getYear';

export default function getTimestampsForNetwork(network) {
  const config = useConfig();
  const startingYear = getYear(moment(network?.data.createdAt || config.custom.emptyNetworkCreatedAt));
  const endingYear = getYear(moment());

  const timestamps = [];

  // for (let year = endingYear; year >= startingYear; year--) {
  for (let year = startingYear; year <= endingYear; year++) {
    const start = moment(`${year}-07-01T12:00:00.000-00:00`).toISOString();
    const end = moment(`${year + 1}-07-01T12:00:00.000-00:00`).toISOString();

    timestamps.push({
      schoolStart: start,
      schoolEnd: end,
      label: `${year}-${year-1999} SY`,
      start,
      end
    });
  }

  return timestamps;
};
