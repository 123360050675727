import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../../forms/_common/FormField';
import { OutlineButton } from '../../../@communityshare/ui/Button';
import { SolidButton } from '../../../@communityshare/ui/Button';
import TextAreaField from '../../../forms/_fields_v2/TextAreaField';
import FormTemplate from './Template';
import { marked } from 'marked';
import Markdown from '../Markdown';
import Banner from '../Banner';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit
  } = props;

  return (
    <FormTemplate
      // title="Leave a note"
      // subtitle="This note will be visible to everyone on your team."
      alert={alert}
      body={(
        <>
          {data.partnerBanner && (
            <Banner>
              <Markdown white={true} text={data.partnerBanner} />
            </Banner>
          )}
          {/*<p*/}
          {/*  className={`space-y-2 ${system ? 'bg-yellow-50 border border-yellow-500 text-yellow-600 p-3 rounded-xl' : ''}`}*/}
          {/*  dangerouslySetInnerHTML={{__html: marked(data.educatorBanner)}}*/}
          {/*/>*/}
          <FormField name="partnerBanner" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <TextAreaField
                label="Partner Banner"
                placeholder="Banner text..."
                description="Example banner: Visit the website for [CommunityShare](https://www.communityshare.org) to learn more about what we do."
                rows={3}
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
        </>
      )}
      footer={(
        <div className="flex justify-end">
          <SolidButton onClick={onSubmit}>
            Save
          </SolidButton>
        </div>
      )}
    />
  );
}
