import React from 'react';
import { Helmet } from 'react-helmet-async';
import Networks from './Networks';
import useRouter from '../../hooks/useRouter';
import PageHeading from '../../@communityshare/ui/PageHeading';
import Results from '../_common_results/Results';
import Network from './Network';
import Filters from '../../forms/filters_projects';
import { BuildingOffice2Icon, FolderIcon } from '@heroicons/react/24/outline';
import MobileNavigation from './MobileNavigation';
import SecondaryAction from '../_components/actions/SecondaryAction';
import { DialogManager } from '../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { useUser } from '@lore/auth';
import NotAuthorizedEmptyState from './NotAuthorizedEmptyState';

export default function Layout(props) {
  const router = useRouter();
  const launch = useDialogLauncher();
  const currentUser = useUser();

  function onClick() {
    launch(DialogManager.NetworkCreate.Config());
  }

  if (!currentUser.data.isStaff) {
    return (
      <NotAuthorizedEmptyState />
    );
  }

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Manage: Networks</title>
      </Helmet>
      <MobileNavigation />
      <PageHeading
        breadcrumbs={['Manage']}
        title="Networks"
        description={(
          <>
            This page shows all networks. Use the filters to narrow the
            list. Find a filter you want to see again in the future? Bookmark the page
            or save a link to the URL.
          </>
        )}
      >
        <div>
          <SecondaryAction onClick={onClick} secret={true}>
            Create Network
          </SecondaryAction>
        </div>
      </PageHeading>
      <Filters router={router} />
      <Networks router={router}>
        {function(result) {
          return (
            <Results result={result} columns={1} resource="networks" emptyIcon={BuildingOffice2Icon}>
              {function(network, index) {
                return (
                  <Network
                    key={index}
                    network={network}
                  />
                );
              }}
            </Results>
          );
        }}
      </Networks>
    </div>
  );
}
