import React  from 'react';
import avatar from '../../assets/images/avatar.svg';

export default function UserAvatar(props) {
  const { user, large = false } = props;

  return (
    <img
      className={`${large ? 'h-12 w-12' : 'h-10 w-10'} rounded-full object-cover`}
      src={user.data.avatarUrl || avatar}
      alt="Avatar"
    />
  );
}
