import React from 'react';
import { PayloadStates } from '@lore/utils';
import ChartLoader from '../../states/_charts/ChartLoader';
import DonutChart from '../../states/_charts/DonutChart';
import colors from 'tailwindcss/colors';
import useInvitationsReal from './_hooks/useInvitationsReal';

export default function InvitationCreatorYear(props) {
  const { state, network, schoolStart, schoolEnd, subtitle } = props;

  const invitations = useInvitationsReal(state, network, schoolStart, schoolEnd, undefined, {
    event: {}
  });

  const _creatorData = invitations.data.filter(q => q.data.event.creatorId === q.data.creatorId);
  const creatorInvitations = {
    state: invitations.state,
    data: _creatorData,
    meta: {
      totalCount: _creatorData.length
    }
  };

  const _stewardData = invitations.data.filter(q => q.data.event.creatorId !== q.data.creatorId);
  const stewardInvitations = {
    state: invitations.state,
    data: _stewardData,
    meta: {
      totalCount: _stewardData.length
    }
  };

  const requests = [
    {
      label: 'Invited by Educator',
      invitations: creatorInvitations
    },
    {
      label: 'Invited by Steward',
      invitations: stewardInvitations
    }
  ]

  const loading = _.reduce(requests, function(result, request) {
    return (
      result ||
      request.invitations.state === PayloadStates.FETCHING
    );
  }, false)

  if (loading) {
    return (
      <ChartLoader
        title="Breakdown of who invited the partner"
        subtitle={subtitle || 'For school year'}
      />
    );
  }

  const series = requests.map(r => r.invitations.meta.totalCount);
  const labels = requests.map(r => r.label);

  return (
    <DonutChart
      title="Breakdown of who invited the partner"
      subtitle={subtitle || 'For school year'}
      labels={labels}
      series={series}
      colors={[
        colors.emerald['400'],
        colors.yellow['400']
      ]}
    />
  );
}
