import React from 'react';
// import Partners from '../../../pages/people-partners/Partners';
import Partners from '../../../pages/partners/Partners';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import useMissingFields from '../../event_manage/useMissingFields';
import EmptyState from '../../_common/EmptyState';
import Results from '../../../pages/_common_results/Results';
import User from '../../../pages/_common_user/EventUser';
import Filters from '../../../forms/filters_partners';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';

export default function Layout(props) {
  const { event, router } = props;

  const { missingFields } = useMissingFields(event);

  const site = useConnect('site.byId', {
    id: event.data.siteId
  });

  if (
    missingFields.state === PayloadStates.FETCHING ||
    site.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  if (missingFields.data.length > 0) {
    return (
      <EmptyState
        title="Event is missing required fields."
        subtitle="They must be provided before you can invite partners."
      />
    );
  }

  return (
    <div className="space-y-8">
      <Filters router={router} />
      <Partners router={router} site={site}>
        {function(result) {
          return (
            <Results result={result} resource="partners" emptyIcon={CalendarIcon}>
              {function(user, index) {
                return (
                  <User
                    key={index}
                    event={event}
                    user={user}
                  />
                );
              }}
            </Results>
          );
        }}
      </Partners>
    </div>
  );
};
