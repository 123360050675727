import React, { useState } from 'react';
import _ from 'lodash';
import { PayloadStates } from '@lore/utils';
import moment from 'moment-timezone';
import colors from 'tailwindcss/colors';
import LineChart from '../../dashboard/_charts/LineChart';
import ChartLoader from '../../dashboard/_charts/ChartLoader';
import useUsersWhoCreatedProjects from './_hooks/useUsersWhoCreatedProjects';
import useUsersWhoCreatedEvents from './_hooks/useUsersWhoCreatedEvents';
import useUsersWhoConnectedAboutOffer from './_hooks/useUsersWhoConnectedAboutOffer';

export default function EngagementByMonth(props) {
  const { network, timestamps, empty, schoolStart } = props;

  const [currentTimestamp] = useState(moment().toISOString());

  const educators = timestamps.map(function(timestamp, index) {
    if (moment(currentTimestamp).diff(timestamp.start) < 0) {
      return {
        ...timestamp,
        results: {
          createdProject: empty(),
          createdEvent: empty(),
          connectAboutOffer: empty()
        }
      }
    }

    return {
      ...timestamp,
      results: {
        createdProject: useUsersWhoCreatedProjects(network, timestamp),
        createdEvent: useUsersWhoCreatedEvents(network, timestamp),
        connectAboutOffer: useUsersWhoConnectedAboutOffer(network, timestamp)
      }
    }
  });

  const loading = _.reduce(educators, function(result, educator) {
    return (
      result ||
      educator.results.createdProject.state === PayloadStates.FETCHING ||
      educator.results.createdEvent.state === PayloadStates.FETCHING ||
      educator.results.connectAboutOffer.state === PayloadStates.FETCHING
    );
  }, false)

  if (loading) {
    return (
      <ChartLoader
        title="Educator Engagement"
        subtitle=""
      />
    );
  }

  const series = [
    {
      name: 'Created Project',
      data: educators.map(e => e.results.createdProject.meta.totalCount)
    },
    {
      name: 'Created Event',
      data: educators.map(e => e.results.createdEvent.meta.totalCount)
    },
    {
      name: 'Connected about Offer',
      data: educators.map(e => e.results.connectAboutOffer.meta.totalCount)
    }
  ];

  return (
    <LineChart
      title="Educator Engagement"
      subtitle="Over school year"
      labels={educators.map(e => e.label)}
      series={series}
      colors={[
        colors.blue['400'],
        colors.emerald['400'],
        colors.yellow['400']
      ]}
    />
  );
}
