import React from 'react';
import titleCase from '../../../utils/titleCase';
import SecondaryAction from '../../_components/actions/SecondaryAction';
import { useUser } from '@lore/auth';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useNetwork from '../../../hooks/useNetwork';

export default function Header(props) {
  const { networkSite, site } = props;

  const launch = useDialogLauncher();
  const currentUser = useUser();
  const network = useNetwork();

  function onClick() {
    launch(DialogManager.NetworkSiteDelete.Config(networkSite.id));
  }

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex justify-between">
        <div className="space-y-0.5">
          <div className="text-lg font-semibold text-cs-gray-900">
            {titleCase(site.data.name)}
          </div>
          <div className="text-base text-cs-gray-500 line-clamp-3">
            {titleCase(site.data.addressLine1)}, {titleCase(site.data.city)}, {site.data.state}
          </div>
        </div>
        {currentUser.data.isSuperuser && (
          <div className="self-startx">
            <SecondaryAction onClick={onClick} secret={true} disabled={!network}>
              Remove Site
            </SecondaryAction>
          </div>
        )}
      </div>
    </div>
  );
}
