import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormField from '../../forms/_common/FormField';
import { OutlineButton } from '../../@communityshare/ui/Button';
import { SolidButton } from '../../@communityshare/ui/Button';
import TextAreaField from '../../forms/_fields_v2/TextAreaField';
import FormTemplate from '../../dialogs_common/FormTemplate';
import { ClockIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';
import moment from 'moment-timezone';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import Loader from '../../components/Loader';
import UserAvatar from '../../components/UserAvatar';
import useNetwork from '../../hooks/useNetwork';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';
import User from './User';
import Card from '../../components/Card';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onClose,
    onBack
  } = props;

  const [timestamp] = useState(moment().toISOString());

  const user = useConnect('user.first', {
    where: {
      eager: {
        $where: {
          email: data.email,
          createdAt: {
            $lte: timestamp
          }
        }
      }
    }
  });

  if (user.state === PayloadStates.FETCHING) {
    return (
      <FormTemplate
        textAlign="left"
        title="Invite Staff"
        subtitle="Checking accounts for an email match..."
        onClose={onClose}
        alert={alert}
        body={(
          <div className="mt-4">
            <Loader />
          </div>
        )}
        footer={(
          <FormTemplateButtonFooter>
            <OutlineButton onClick={onBack}>
              Back
            </OutlineButton>
            <SolidButton onClick={() => {}} disabled={true}>
              Grant access
            </SolidButton>
          </FormTemplateButtonFooter>
        )}
      />
    );
  }

  // if (stewardInvitations.data.length > 0) {
  if (user.state === PayloadStates.RESOLVED && user.data.isStaff) {
    return (
      <FormTemplate
        textAlign="left"
        title="Account found but user is already staff."
        subtitle={`An account for ${user.data.firstName} ${user.data.lastName} was found with a matching email address, but this account already has staff access.`}
        onClose={onClose}
        alert={alert}
        body={(
          <User key={user.id} user={user} />
        )}
        footer={(
          <FormTemplateButtonFooter>
            <OutlineButton onClick={onClose}>
              Close
            </OutlineButton>
            <SolidButton onClick={onBack}>
              Select different person
            </SolidButton>
          </FormTemplateButtonFooter>
        )}
      />
    );
  }

  if (user.state === PayloadStates.RESOLVED && !user.data.isStaff) {
    return (
      <FormTemplate
        textAlign="left"
        title="Matching account found."
        subtitle="Do you want to grant this person staff access?"
        onClose={onClose}
        alert={alert}
        body={(
          <User key={user.id} user={user} />
        )}
        footer={(
          <FormTemplateButtonFooter>
            <OutlineButton onClick={onBack}>
              Back
            </OutlineButton>
            <SolidButton onClick={() => onSubmit(user)}>
              Grant access
            </SolidButton>
          </FormTemplateButtonFooter>
        )}
      />
    );
  }

  return (
    <FormTemplate
      textAlign="left"
      title="There is no user account linked to that email."
      subtitle="If you believe this is a mistake, contact the person that you want to have staff access and confirm which email address they are using."
      onClose={onClose}
      alert={alert}
      body={(
        <Card>
          <div className="flex items-center px-4 py-4">
            <div className="flex items-center text-sm text-gray-500 space-x-2">
              <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
              <div className="truncate">
                {data.email}
              </div>
            </div>
          </div>
        </Card>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onBack}>
            Back
          </OutlineButton>
          <SolidButton onClick={onBack}>
            Select different person
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
