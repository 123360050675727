import React from 'react';
import LazyMetrics from '../LazyMetrics';
import ProjectEvalResponseYear from './ProjectEvalResponseYear';
import { ResponseFields } from './_hooks/useProjectEvaluationEducatorResponse';
import ImpactYearChart from './ImpactYearChart';
import ImpactYear from './ImpactYear';
import EventEvalResponseYear from './EventEvalResponseYear';

export default function Students(props) {
  const { params } = props;

  return (
    <div className="space-y-12">
      <LazyMetrics
        // title="Student Impact"
        columns={1}
      >
        <ImpactYearChart {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <ImpactYear {...params} showTotals={false} />
        </div>
      </LazyMetrics>
      <LazyMetrics
        title="Projects - Impact on Students (as rated by educators)"
        columns={2}
      >
        <ProjectEvalResponseYear
          {...params}
          title="Students were more engaged"
          field={ResponseFields.EngagedRating}
        />
        <ProjectEvalResponseYear
          {...params}
          title="Students built social-emotional skills"
          field={ResponseFields.SkillsRating}
        />
        <ProjectEvalResponseYear
          {...params}
          title="Students increased awareness of future academic and career opportunities"
          field={ResponseFields.AwarenessRating}
        />
        <ProjectEvalResponseYear
          {...params}
          title="Students increased understanding of the real-world application of topic/content"
          field={ResponseFields.UnderstandingRating}
        />
        <ProjectEvalResponseYear
          {...params}
          title="Students practiced critical thinking, problem-solving, and/or working as a team"
          field={ResponseFields.PracticedRating}
        />
      </LazyMetrics>
      <LazyMetrics
        title="Events - Impact on Students (as rated by educators)"
        columns={2}
      >
        <EventEvalResponseYear
          {...params}
          title="Students were more engaged"
          field={ResponseFields.EngagedRating}
        />
        <EventEvalResponseYear
          {...params}
          title="Students built social-emotional skills"
          field={ResponseFields.SkillsRating}
        />
        <EventEvalResponseYear
          {...params}
          title="Students increased awareness of future academic and career opportunities"
          field={ResponseFields.AwarenessRating}
        />
        <EventEvalResponseYear
          {...params}
          title="Students increased understanding of the real-world application of topic/content"
          field={ResponseFields.UnderstandingRating}
        />
        <EventEvalResponseYear
          {...params}
          title="Students practiced critical thinking, problem-solving, and/or working as a team"
          field={ResponseFields.PracticedRating}
        />
      </LazyMetrics>
    </div>
  );
}
