import React from 'react';
import useQuery from '../../evaluations-project-educators-filtered/photos/useQuery';
import useSchoolYearParams from '../useSchoolYearParams';
import classNames from 'classnames';
import ProjectImageData from './ProjectImageData';
import EventImageData from './EventImageData';

export default function ImageChildBuilder(props) {
  const { upload } = props;

  const params = useSchoolYearParams();

  const PAGE_SIZE = 12;

  const result = useQuery({
    location,
    pageSize: PAGE_SIZE,
    network: params.network,
    schoolStart: params.schoolStart,
    schoolEnd: params.schoolEnd
  });

  if (!(
    upload.data.evaluationProjectEducatorId ||
    upload.data.evaluationEventEducatorId
  )) {
    return null;
  }

  // return (
  //   <div className={classNames(
  //     'group-hover:visible invisible absolute bottom-0',
  //     // 'absolute bottom-0',
  //     'w-full bg-white bg-opacity-80',
  //     'px-2 py-3',
  //     'break-all',
  //     'cursor-default'
  //   )}>
  //     {upload.data.evaluationProjectEducatorId && (
  //       <ProjectImageData upload={upload} />
  //     )}
  //     {upload.data.evaluationEventEducatorId && (
  //       <EventImageData upload={upload} />
  //     )}
  //   </div>
  // );

  return (
    <div className={classNames(
      'group-hover:visible invisible absolute bottom-0',
      // 'absolute bottom-0',
      'w-full',
      'px-1.5 pb-1.5',
      'cursor-default'
    )}>
      <div className={classNames(
        'w-full bg-white bg-opacity-95 rounded-cs-10 shadow-cs-flat',
        'px-2 py-3',
      )}>
        {upload.data.evaluationProjectEducatorId && (
          <ProjectImageData upload={upload} />
        )}
        {upload.data.evaluationEventEducatorId && (
          <EventImageData upload={upload} />
        )}
      </div>
    </div>
  );
}
