import React from 'react';
import LazyMetrics from '../LazyMetrics';
import CompositeEducatorProjectEvaluations from './CompositeEducatorProjectEvaluations';
import CompositeEducatorEventEvaluations from './CompositeEducatorEventEvaluations';
import CompositePartnerProjectEvaluations from './CompositePartnerProjectEvaluations';
import CompositePartnerEventEvaluations from './CompositePartnerEventEvaluations';
import EducatorProjectEvaluationsByMonth from './EducatorProjectEvaluationsByMonth';
import EducatorEventEvaluationsByMonth from './EducatorEventEvaluationsByMonth';
import PartnerProjectEvaluationsByMonth from './PartnerProjectEvaluationsByMonth';
import PartnerEventEvaluationsByMonth from './PartnerEventEvaluationsByMonth';

export default function Evaluations(props) {
  const { params } = props;

  return (
    <div className="space-y-12">
      <LazyMetrics
        title="Project Evaluations - Educators"
        columns={1}
      >
        <EducatorProjectEvaluationsByMonth {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <CompositeEducatorProjectEvaluations {...params} showTotals={false} />
        </div>
      </LazyMetrics>
      <LazyMetrics
        title="Project Evaluations - Partners"
        columns={1}
      >
        <PartnerProjectEvaluationsByMonth {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <CompositePartnerProjectEvaluations {...params} showTotals={false} />
        </div>
      </LazyMetrics>
      <LazyMetrics
        title="Event Evaluations - Educators"
        columns={1}
      >
        <EducatorEventEvaluationsByMonth {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <CompositeEducatorEventEvaluations {...params} showTotals={false} />
        </div>
      </LazyMetrics>
      <LazyMetrics
        title="Event Evaluations - Partners"
        columns={1}
      >
        <PartnerEventEvaluationsByMonth {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <CompositePartnerEventEvaluations {...params} showTotals={false} />
        </div>
      </LazyMetrics>
    </div>
  );
}
