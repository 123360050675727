import React from 'react';
import { parse, stringify } from 'query-string';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import useNetwork from '../../../hooks/useNetwork';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 12, returnRawQuery = false } = props;

  const network = useNetwork();

  const query = {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfQuestionsConnect',
            relation: 'questions',
            $where: {
              response: 'Connect',
              'project.site.networks.id': network?.id
            }
          },
          {
            type: 'count',
            alias: 'numberOfInvitationsYes',
            relation: 'invitations',
            $where: {
              response: 'Yes',
              'event.site.networks.id': network?.id
            }
          }
        ],
        $where: {
          isEducator: false,
          $or: {
            numberOfQuestionsConnect: {
              $gt: 0
            },
            numberOfInvitationsYes: {
              $gt: 0
            },
          }
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    }
  };

  const result = useConnect('user.find', query, {
    enabled: !returnRawQuery,
    keepPreviousData: true
  });

  if (returnRawQuery) {
    return query;
  }

  return result;
}
