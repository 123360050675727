import React from 'react';
import Date from '../../../dialogs_new/event_manage/Header/Date';
import Slots from '../../../dialogs_new/event_manage/Header/Slots';
import Filled from '../../../dialogs_new/event_manage/Header/Filled';
import Creator from '../../../dialogs_new/event_manage/Header/Creator';
import Partners from './Partners';
import Site from './Site';
import Title from './Title';
import StatusTags from './StatusTags';
import Description from './Description';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

export default function Header(props) {
  const { event, tags = true } = props;

  const launch = useDialogLauncher();

  function onClick(event) {
    launch(DialogManager.EventManage.Config(event.id));
  }

  return (
    <div className="flex flex-col p-4 space-y-2">
      <div className="space-y-2">
        {tags && (
          <StatusTags event={event} />
        )}
        <div className="cursor-pointer hover:text-gray-500 space-y-1 pr-10" onClick={() => onClick(event)}>
          <Title event={event} />
          <Description event={event} />
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex flex-wrap gap-x-4 gap-y-2">
          <Date event={event} />
          <Slots event={event} />
          <Filled event={event} />
          <Creator event={event} />
          <Site event={event} />
        </div>
        <Partners event={event} />
      </div>
    </div>
  );
}
