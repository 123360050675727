import React from 'react';
import LazyMetrics from '../../dashboard-year/LazyMetrics';
import TotalYear from '../../dashboard-year/Organizations/TotalYear';
import OrganizationGrowthByMonth from '../../dashboard-year/Organizations/OrganizationGrowthByMonth';
import CompositeOrganizationGrowth from '../../dashboard-year/Organizations/CompositeOrganizationGrowth';
import OrganizationCumulativeGrowthByMonth from '../../dashboard-year/Organizations/OrganizationCumulativeGrowthByMonth';

export default function Organizations(props) {
  const { params } = props;

  return (
    <>
      <LazyMetrics title="Organizations associated/affiliated with Partners">
        <TotalYear {...params} titleOnly={true} />
      </LazyMetrics>
      <LazyMetrics
        title="Growth"
        columns={1}
      >
        {/*<OrganizationGrowthByMonth {...params} />*/}
        <OrganizationCumulativeGrowthByMonth {...params} />
        {/*<div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">*/}
        {/*  <CompositeOrganizationGrowth {...params} titleOnly={true} />*/}
        {/*</div>*/}
      </LazyMetrics>
    </>
  );
}
