import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import usePageRouter from '../../../hooks/@lore/dialogs-routable/_hooks/usePageRouter';
import classNames from 'classnames';

export default function NavigationDropdownState(props) {
  const { header, navigationLinks = [] } = props;

  const { history, location } = usePageRouter();

  // const currentNavigationLink = _.find(navigationLinks, function(link) {
  //   // return _.startsWith(location.pathname, link.href);
  //   const tokens = location.pathname.split(link.href);
  //   const remainder = tokens[1];
  //   return remainder ? remainder[0] === '/' : false;
  // });

  const currentNavigationLink = _.find(navigationLinks, function(link) {
    // return _.startsWith(location.pathname, link.href);
    return location.pathname === link.href;
  });

  // function onSwitchSchoolYear(currentNavigationLink, nextNavigationLink) {
  //   // const extension = location.pathname.split(`/c/${network.data.subdomain}/`)[1];
  //   // const pathname = `/c/${nextNetwork.data.subdomain}/${extension}`;
  //   const tokens = location.pathname.split(currentNavigationLink.href);
  //   const nextUrl = `${nextNavigationLink.href}${tokens[1]}`;
  //   history.push(nextUrl);
  //
  //   // queryClient.invalidateQueries();
  // }

  function onSwitchSchoolYear(currentNavigationLink, nextNavigationLink) {
    history.push(nextNavigationLink.href);
  }

  return (
    <Menu as="div" className="relative inline-block text-left w-full">
      <div>
        <Menu.Button className={classNames(
          'group w-full bg-white rounded-cs-10 p-3',
          'shadow-cs-flat -m-px border border-cs-gray-200',
          'hover:bg-cs-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-cs-orange-500'
        )}>
          <div className="flex space-x-3 text-left">
            {/*<img*/}
            {/*  className="w-12 h-12 rounded-full"*/}
            {/*  // src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80"*/}
            {/*  src={currentUser.data.avatarUrl || avatar}*/}
            {/*  alt=""*/}
            {/*/>*/}
            <span className="flex-1 flex flex-col min-w-0">
              <span className="text-cs-gray-900 text-lg font-semibold">
                {currentNavigationLink?.name || 'Unknown state'}
              </span>
              {/*<span className="text-cs-gray-500 text-sm truncate">*/}
              {/*  School Year*/}
              {/*</span>*/}
            </span>
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="z-10 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
        >
          <div className="py-1">
            {header && (
              <div
                className={classNames(
                  'text-cs-gray-500',
                  'block px-4 py-2 text-base cursor-pointer'
                )}
              >
                {header}
              </div>
            )}
            {navigationLinks.map(function (navigationLink, index) {
              const isActive = _.startsWith(location.pathname, navigationLink.href)

              return (
                <Menu.Item key={index}>
                  {({active}) => (
                    <div
                      onClick={!isActive ? () => onSwitchSchoolYear(currentNavigationLink, navigationLink) : undefined}
                      className={classNames(
                        isActive ? 'bg-cs-orange-50' :
                        active ? 'bg-cs-gray-100 text-cs-gray-900' : 'text-cs-gray-900',
                        'block px-4 py-2 text-base cursor-pointer'
                      )}
                    >
                      <span className={classNames(
                        isActive ? 'text-cs-orange-500 font-semibold' :
                        '',
                      )}>
                        {navigationLink.name}
                      </span>
                    </div>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
