import React from 'react';
import { useConnect } from '@lore/query-connect';
import { MapPinIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import Bookmark from '../../_components/Bookmark';
import VirtualAvatar from '../../../components/User/VirtualAvatar';
import Card from '../../../components/Card';
import UserRoles from './UserRoles';
import UserJobs from './UserJobs';

const MAX_ROLES = 2;

export default function User(props) {
  const {
    user,
    selected = false,
    footer,
    children,
    onClick,
    distance
  } = props;

  const _roles = useConnect('role.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'createdAt asc'
    }
  });

  const roles = {
    ..._roles,
    data: _roles.data.slice(0, MAX_ROLES)
  };

  return (
    <Card
      className={classNames(
        // 'shadow-cs-flat rounded-2xl p-4 relative z-10 -m-0.5 border-2 border-cs-gray-100',
        // 'p-4 relative z-10',
        'relative z-10 h-full overflow-hidden flex flex-col justify-between',
        // selected ? 'bg-cs-orange-50' : 'bg-white hover:bg-cs-orange-50 cursor-pointer'
      )}
      // onClick={selected ? undefined : onClick}
    >
      <div className="absolute z-20 top-2 right-4">
        {/*<div className="absolute z-10 top-4 right-4 pt-0.5x pr-0.5x">*/}
        <Bookmark
          type="user"
          resource={user}
        />
      </div>
      <div
        className={classNames(
          'flex flex-col items-center h-full space-y-4 p-4 justify-between',
          selected ? 'bg-cs-orange-50' : 'bg-white hover:bg-cs-orange-50 cursor-pointer'
        )}
        onClick={selected ? undefined : onClick}
      >
        <div className="space-y-4">
          <VirtualAvatar size={24} user={user}/>
          <div className="flex-1 flex flex-col items-center space-y-0.5">
            <div className="text-cs-gray-900 text-lg font-semibold text-center">
              {user.data.firstName} {user.data.lastName}
            </div>
            {/*<div className="text-sm text-cs-gray-500 text-center">*/}
            {/*  {roles.data.map(function (role, index) {*/}
            {/*    return [*/}
            {/*      <UserRole key={`role-${index}`} role={role}/>,*/}
            {/*      index < roles.data.length - 1 ? <span key={`dot-${index}`}>{' • '}</span> : null*/}
            {/*    ];*/}
            {/*  })}*/}
            {/*</div>*/}
            {user.data.isEducator ? (
              <UserRoles user={user} />
            ) : (
              <UserJobs user={user} />
            )}
          </div>
        </div>
        {footer ? (
          <div className="text-lg text-cs-orange-500 font-semibold">
            {footer}
          </div>
        ) : (
          <div className="flex items-startx text-base">
            <div className="mt-0.5">
              <MapPinIcon className="text-cs-gray-500 mr-1 h-4 w-4"/>
            </div>
            {distance ? (
              <div className="text-sm text-cs-gray-500">
                {distance}
              </div>
            ) : (
              <div className="text-sm text-cs-gray-500">
                {user.data.city && user.data.state ?
                  `${user.data.city}, ${user.data.state}` :
                  user.data.zipCode
                }
              </div>
            )}
          </div>
        )}
      </div>
      {children}
    </Card>
  );
}
