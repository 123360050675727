import React from 'react';
import _ from 'lodash';
import { useConnect } from '@lore/query-connect';
import useStewardshipFilter from '../../../educators/useStewardshipFilter';

export default function useUsersWhoCreatedEvents(state, network, timestamp) {
  // const stewardshipFilter = useStewardshipFilter();

  return useConnect('user.find', {
    where: {
      eager: {
        $aggregations: _.remove([
          {
            type: 'count',
            alias: 'numberOfEvents',
            relation: 'events',
            $where: {
              createdAt: {
                $gte: timestamp.start,
                $lte: timestamp.end
              },
              activityId: {
                $not: null
              }
            }
          }
        ]),
        $where: {
          numberOfEvents: {
            $gte: 1
          },
          isEducator: true,
          'state': {
            $ilike: state
          },
          // $or: stewardshipFilter
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });
}
