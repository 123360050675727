import { extractArrayFromQuery, mapAttributes } from '../../../utils/query';

import networks from './networks';
import activities from './activities';
import status from './status';
import assignees from './assignees';
import createdDates from './createdDates';
import name from './name';
import published from './published';
import school from './school';
import district from './district';
import search from './search';
import stage from './stage';
import title from './title';
import locationCondition from './locationCondition';

function tag() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          'tagNodes.tagId': {
            $in_subquery: values
          }
        }
      }
    }
  };
}

function tagTree() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          'tagTrees.parentId': {
            $in: values
          }
        }
      }
    }
  };
}

const attributes = {
  networks,
  activities,
  ages: tag(),
  assignees,
  commitments: tag(),
  createdDates,
  days: tag(),
  expertiseTypes: tag(),
  groups: tag(),
  guidances: tag(),
  interactionTypes: tag(),
  locationCondition,
  name,
  participants: tag(),
  published,
  school,
  district,
  search,
  status,
  subjects: tag(),
  times: tag(),
  title,
  trainings: tag(),
};

export const attributeKeys = _.keys(attributes);

export default mapAttributes(attributes);
