import _ from 'lodash';
import {
  ClipboardDocumentCheckIcon,
  EnvelopeIcon,
  UsersIcon,
  FlagIcon,
  BanknotesIcon
} from '@heroicons/react/24/outline';

export default function getNavigationLinks(network, currentUser) {
  return _.remove([
    { name: 'Team', icon: UsersIcon, href: `/settings/team`, matches: [`/settings/team`]  },
    { name: 'Screening', icon: ClipboardDocumentCheckIcon, href: `/settings/screening` },
    { name: 'Training', icon: ClipboardDocumentCheckIcon, href: `/settings/training` },
    { name: 'Banner', icon: FlagIcon, href: `/settings/banner` },
    { name: 'Grants', icon: BanknotesIcon, href: `/settings/grants` }
  ]);
};
