import React, { useState } from 'react';
import _ from 'lodash';
import { PayloadStates } from '@lore/utils';
import moment from 'moment-timezone';
import colors from 'tailwindcss/colors';
import LineChart from '../../dashboard/_charts/LineChart';
import ChartLoader from '../../dashboard/_charts/ChartLoader';
import useEducators from './_hooks/useEducators';
import { MISSING_EDUCATOR_FIELDS } from '../../../utils/user/MISSING_FIELDS';
import { REQUIRED_EDUCATOR_FIELDS } from '../../../utils/user/REQUIRED_FIELDS';

export default function EducatorGrowthByMonth(props) {
  const { network, timestamps, empty, schoolStart } = props;

  const [currentTimestamp] = useState(moment().toISOString());

  const educators = timestamps.map(function(timestamp, index) {
    if (moment(currentTimestamp).diff(timestamp.start) < 0) {
      return {
        ...timestamp,
        results: {
          new: empty(),
          inactive: empty(),
          sleeping: empty(),
          incomplete: empty(),
          active: empty()
        }
      }
    }

    return {
      ...timestamp,
      results: {
        new: useEducators(network, timestamp.start, timestamp.end),
        inactive: useEducators(network, timestamp.start, timestamp.end, {
          inactive: true
        }),
        sleeping: useEducators(network, timestamp.start, timestamp.end, {
          sleepingStartingAt: {
            $lte: currentTimestamp
          },
          sleepingEndingAt: {
            $gte: currentTimestamp
          },
          inactive: false
        }),
        incomplete: useEducators(network, timestamp.start, timestamp.end, {
          inactive: false,
          $or: MISSING_EDUCATOR_FIELDS.$or,
          $and: {
            $or: [
              {
                sleepingStartingAt: {
                  $gte: currentTimestamp
                }
              },
              {
                sleepingEndingAt: {
                  $lte: currentTimestamp
                }
              }
            ]
          }
        }),
        active: useEducators(network, timestamp.start, timestamp.end, {
          ...REQUIRED_EDUCATOR_FIELDS,
          inactive: false,
          $or: [
            {
              sleepingStartingAt: {
                $gte: currentTimestamp
              }
            },
            {
              sleepingEndingAt: {
                $lte: currentTimestamp
              }
            }
          ]
        })
      }
    }
  });

  const loading = _.reduce(educators, function(result, data) {
    return (
      result ||
      data.results.new.state === PayloadStates.FETCHING ||
      data.results.inactive.state === PayloadStates.FETCHING ||
      data.results.sleeping.state === PayloadStates.FETCHING ||
      data.results.incomplete.state === PayloadStates.FETCHING ||
      data.results.active.state === PayloadStates.FETCHING
    );
  }, false);

  if (loading) {
    return (
      <ChartLoader
        title="Educator signups"
        subtitle=""
      />
    );
  }

  const series = [
    {
      name: 'Signups',
      data: educators.map(e => e.results.new.meta.totalCount)
    },
    {
      name: 'Active',
      data: educators.map(e => e.results.active.meta.totalCount)
    },
    {
      name: 'Sleeping',
      data: educators.map(e => e.results.sleeping.meta.totalCount)
    },
    {
      name: 'Inactive',
      data: educators.map(e => e.results.inactive.meta.totalCount)
    },
    {
      name: 'Incomplete',
      data: educators.map(e => e.results.incomplete.meta.totalCount)
    }
  ];

  return (
    <LineChart
      title="Educator signups"
      subtitle="Over school year"
      labels={educators.map(e => e.label)}
      series={series}
      colors={[
        colors.gray['400'],
        colors.emerald['400'],
        colors.blue['400'],
        colors.red['400'],
        colors.yellow['400']
      ]}
    />
  );
}
