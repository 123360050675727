import React from 'react';
import { CalendarIcon } from '@heroicons/react/24/outline';
import IconSection from '../../../../@communityshare/ui/card/IconSection';
import { formatDate, formatDateRange } from '../../../../utils/dateTimeUtils';
import { useConnect } from '@lore/query-connect';

export default function Date(props) {
  const { job } = props;

  const user = useConnect('user.byId', {
    id: job.data.userId
  });

  return (
    <div className="flex-1 text-sm text-cs-gray-500 text-right">
      Last Active: {formatDate(user.data.updatedAt)}
    </div>
  );

  return (
    <IconSection
      icon={CalendarIcon}
      title={formatDateRange(user.data.createdAt, user.data.updatedAt)}
    />
  );
}
