import React, { useState } from 'react';
import Table from './Table';
import { parse, stringify } from 'query-string';
import useRouter from '../../hooks/useRouter';
import initialColumns from './columns';
import useNetwork from '../../hooks/useNetwork';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';

export default function Team(props) {
  const { network } = props;

  const { location, history } = useRouter();

  const PAGE_SIZE = 20;

  const [columns, setColumns] = useState(initialColumns);

  const selectedColumns = columns.filter(column => column.selected);

  const networkStewards = useConnect('networkSteward.find', {
    where: {
      eager: {
        $where: {
          networkId: network.id
        },
        user: {}
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    }
  });

  const stewardInvitations = useConnect('stewardInvitation.find', {
    where: {
      eager: {
        $where: {
          networkId: network.id
        },
        user: {}
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    }
  });

  if (
    networkStewards.state === PayloadStates.FETCHING ||
    stewardInvitations.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  const stewardMap = {};

  networkStewards.data.forEach(function(networkSteward) {
    const email = networkSteward.data.user.email;
    stewardMap[email] = stewardMap[email] || {};
    stewardMap[email].networkSteward = networkSteward;
    stewardMap[email].user = networkSteward.data.user;
  });

  stewardInvitations.data.forEach(function(stewardInvitation) {
    const email = stewardInvitation.data.email;
    stewardMap[email] = stewardMap[email] || {};
    stewardMap[email].stewardInvitation = stewardInvitation;
    stewardMap[email].user = stewardMap[email].user || stewardInvitation.data.user;
  });

  const stewards = _.reduce(stewardMap, function(result, value, key) {
    result.push(value);
    return result;
  }, []);

  return (
    <Table
      pageSize={PAGE_SIZE}
      columns={selectedColumns}
      select={function (getState) {
        return {
          state: PayloadStates.RESOLVED,
          data: stewards
        };
      }}
      row={function (data, index) {
        return (
          <tr key={index}>
            {selectedColumns.map(function (column) {
              if (!column.row) {
                return null;
              }

              return (
                <column.row
                  key={column.id}
                  {...data}
                />
              );
            })}
          </tr>
        );
      }}
    />
  );
}
