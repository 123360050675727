import React from 'react';
import { useConnect } from '@lore/query-connect';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import IconSection from '../../../@communityshare/ui/card/IconSection';

export default function School(props) {
  const { project } = props;

  const site = useConnect('site.byId', {
    id: project.data.siteId
  }, {
    enabled: !!project.data.siteId
  });

  if (!project.data.siteId) {
    return null;
  }

  return (
    <IconSection
      icon={BuildingOfficeIcon}
      title={site.data.name}
      tooltip="Site"
    />
  );
}
