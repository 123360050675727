import { extractArrayFromQuery } from '../../../utils/query';

let currentNetwork = null;

export function setCurrentNetwork(network) {
  currentNetwork = network;
}

export default {
  param: extractArrayFromQuery,
  query: function (values) {
    if (
      values.length > 0 &&
      values[0] === 0
    ) {
      return;
    }

    if (values.length > 0) {
      return {
        'networkId': {
          $in: values
        }
      }
    }

    if (currentNetwork) {
      return {
        networkId: currentNetwork.id
      }
    }
  }
}
