import React from 'react';
import { useConnect } from '@lore/query-connect';
import REQUIRED_FIELDS from '../../events/REQUIRED_FIELDS';
import { PayloadStates } from '@lore/utils';
import MetricLoader from '../MetricLoader';
import DeltaMetric from '../DeltaMetric';

export default function TotalYear(props) {
  const { state, network, schoolStart, schoolEnd, titleOnly } = props;

  const allEvents = useConnect('event.find', {
    where: {
      eager: {
        $where: {
          $and: REQUIRED_FIELDS.$and,
          // networkId: network?.id,
          'site.state': {
            $ilike: state
          },
          createdAt: {
            $lte: schoolEnd
          }
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  const newEvents = useConnect('event.find', {
    where: {
      eager: {
        $where: {
          $and: REQUIRED_FIELDS.$and,
          // networkId: network?.id,
          'site.state': {
            $ilike: state
          },
          createdAt: {
            $lte: schoolEnd,
            $gte: schoolStart
          }
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  if (
    allEvents.state === PayloadStates.FETCHING ||
    newEvents.state === PayloadStates.FETCHING
  ) {
    return (
      <MetricLoader
        name="Total"
      />
    );
  }

  return (
    <DeltaMetric
      name="Total"
      value={allEvents.meta.totalCount}
      delta={newEvents.meta.totalCount}
      titleOnly={titleOnly}
    />
  );
}
