import React from 'react';
import Students from '../Students';
import Educators from '../Educators';
import Partners from '../Partners';
import Projects from '../Projects';
import Events from '../Events';
import Offers from '../Offers';
import Evaluations from '../Evaluations';

export default function State(props) {
  const { params } = props;

  return (
    <>
      <Students params={params} />
      <Educators params={params} />
      <Partners params={params} />
      <Projects params={params} />
      <Events params={params} />
      <Offers params={params} />
      <Evaluations params={params} />
    </>
  );
}
