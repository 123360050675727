import React, { useState } from 'react';
import moment from 'moment-timezone';
import PayloadStates from '../../constants/PayloadStates';
import useNetwork from '../../hooks/useNetwork';
import PageHeading from '../../@communityshare/ui/PageHeading';
import { useParams } from 'react-router-dom';
import useTags from '../../hooks/useTags';
import TagTypes from '../../constants/TagTypes';
import Loader from '../../components/Loader';
import { Helmet } from 'react-helmet-async';
import MobileNavigation from '../states/MobileNavigation';

export default function ParentLayout(props) {
  const { title, children } = props;

  const routeParams = useParams();

  const [currentTimestamp] = useState(moment().toISOString());

  const network = useNetwork();
  const activities = useTags(TagTypes.Activity);
  const subjects = useTags(TagTypes.Subject);

  const year = Number(routeParams.year); // 2021

  const start = moment(`${year}-07-01T12:00:00.000-00:00`).toISOString();
  const end = moment(`${year + 1}-07-01T12:00:00.000-00:00`).toISOString();

  const timestamps = [0,1,2,3,4,5,6,7,8,9,10,11].map(function(value) {
    // const timestamps = [0].map(function(value) {
    return {
      schoolStart: start,
      schoolEnd: end,
      start: moment(start).add(value, 'months').toISOString(),
      end: moment(start).add(value + 1, 'months').toISOString(),
      label: moment(start).add(value, 'months').format('MMM')
    }
  });

  function empty() {
    return {
      state: PayloadStates.RESOLVED,
      data: [],
      meta: {
        totalCount: 0
      }
    }
  }

  const params = {
    network,
    schoolStart: start,
    schoolEnd: end,
    timestamps,
    empty,
    activities,
    subjects,
    currentTimestamp,
    state: routeParams.state
  };

  if (
    activities.state === PayloadStates.FETCHING ||
    subjects.state === PayloadStates.FETCHING
  ) {
    return (
      <div
        key={`${routeParams.state}:${routeParams.year}:${title}`}
        className="p-8 space-y-8"
      >
        <Helmet>
          <title>States: {`${year}-${year-1999} School Year: ${title}`}</title>
        </Helmet>
        <PageHeading
          breadcrumbs={['States', `${year}-${year-1999} School Year`]}
          title={title}
        />
        <Loader />
      </div>
    );
  }

  return (
    <div key={`${routeParams.state}:${routeParams.year}:${title}`} className="p-8 space-y-8">
      <Helmet>
        <title>States: {`${year}-${year-1999} School Year: ${title}`}</title>
      </Helmet>
      <MobileNavigation />
      <PageHeading
        breadcrumbs={['States', `${year}-${year-1999} School Year`]}
        title={title}
      />
      {children(params)}
    </div>
  );
}
