import React from 'react';
import moment from 'moment-timezone';
import { useConfig } from '@lore/config';
import getYear from '../getYear';

export default function(network) {
  const config = useConfig();
  const startingYear = getYear(moment(network?.data.createdAt || config.custom.emptyNetworkCreatedAt));
  const endingYear = getYear(moment());

  const years = [];

  for (let year = endingYear; year >= startingYear; year--) {
    years.push({
      name: `${year}-${year-1999} School Year`,
      href: `/dashboard/year/${year}`
    })
  }

  years.push({
    name: 'All School Years',
    href: `/dashboard/overview`
  });

  return years;
};
