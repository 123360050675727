import React, { useState } from 'react';
import _ from 'lodash';
import { PayloadStates } from '@lore/utils';
import moment from 'moment-timezone';
import colors from 'tailwindcss/colors';
import LineChart from '../../states/_charts/LineChart';
import ChartLoader from '../../states/_charts/ChartLoader';
import useSchools from './_hooks/useSchools';

export default function SchoolGrowthByMonth(props) {
  const { network, timestamps, empty, schoolStart } = props;

  const [currentTimestamp] = useState(moment().toISOString());

  const schools = timestamps.map(function(timestamp, index) {
    if (moment(currentTimestamp).diff(timestamp.start) < 0) {
      return {
        ...timestamp,
        results: {
          schools: empty()
        }
      }
    }

    return {
      ...timestamp,
      results: {
        schools: useSchools(network, timestamp.start, timestamp.end)
      }
    }
  });

  const loading = _.reduce(schools, function(result, data) {
    return (
      result ||
      data.results.schools.state === PayloadStates.FETCHING
    );
  }, false);

  if (loading) {
    return (
      <ChartLoader
        title="Schools in your network"
        subtitle=""
      />
    );
  }

  const series = [
    {
      name: 'New Schools',
      data: schools.map(e => e.results.schools.meta.totalCount)
    }
  ];

  return (
    <LineChart
      title="Schools in your network"
      subtitle="Over school year"
      labels={schools.map(e => e.label)}
      series={series}
      colors={[
        colors.blue['400'],
        colors.emerald['400'],
        colors.yellow['400']
      ]}
    />
  );
}
