import React from 'react';
import useNetwork from '../../../hooks/useNetwork';
import useGetCount from '../../explore/useGetCount';
import allSites from '../../sites/useQuery';
import unlinkedProjects from '../../sites-filtered/unlinked-projects/useQuery';
import unlinkedEvents from '../../sites-filtered/unlinked-events/useQuery';
import missingRoles from '../../sites-filtered/missing-roles/useQuery';

export default function getNavigationLinks() {
  return [
    { name: 'All', href: `/sites`, count: useGetCount(allSites) },
    { name: 'Unlinked Projects', href: `/sites/unlinked-projects`, count: useGetCount(unlinkedProjects) },
    { name: 'Unlinked Events', href: `/sites/unlinked-events`, count: useGetCount(unlinkedEvents) },
    { name: 'Orgs/Roles to cleanup', href: `/sites/missing-roles`, count: useGetCount(missingRoles) }
  ];
}
