import React  from 'react';
import { useConnect } from '@lore/query-connect';

export default function Projects(props) {
  const { site } = props;

  const completed = useConnect('project.find', {
    where: {
      eager: {
        $where: {
          'organization.schoolId': site.data.schoolId,
          completed: true
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  const cancelled = useConnect('project.find', {
    where: {
      eager: {
        $where: {
          'organization.schoolId': site.data.schoolId,
          cancelled: true
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  const other = useConnect('project.find', {
    where: {
      eager: {
        $where: {
          'organization.schoolId': site.data.schoolId,
          completed: false,
          cancelled: false
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  const completedEvents = useConnect('event.find', {
    where: {
      eager: {
        $where: {
          'organization.schoolId': site.data.schoolId,
          completed: true
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  const cancelledEvents = useConnect('event.find', {
    where: {
      eager: {
        $where: {
          'organization.schoolId': site.data.schoolId,
          cancelled: true
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  const otherEvents = useConnect('event.find', {
    where: {
      eager: {
        $where: {
          'organization.schoolId': site.data.schoolId,
          completed: false,
          cancelled: false
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  return (
    <div className="whitespace-nowrap space-x-2">
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
        {cancelled.meta?.totalCount} / {cancelledEvents.meta?.totalCount}
      </span>
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
        {other.meta?.totalCount} / {otherEvents.meta?.totalCount}
      </span>
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
        {completed.meta?.totalCount} / {completedEvents.meta?.totalCount}
      </span>
    </div>
  );
}
