import tucson from '../assets/images/logos/tucson_network.png';
import lascruces from '../assets/images/logos/lascruces_network.png';
import yumaabec from '../assets/images/logos/yumaabec_network.png';
import westcler from '../assets/images/logos/westcler_network.png';
import summit from '../assets/images/logos/summit_network.jpg';
import hcs from '../assets/images/logos/hcs_network.png';
import ednium from '../assets/images/logos/ednium_network.jpg';
import flagstaff from '../assets/images/logos/flagstaff_network.png';

export default {

  /**
   * Email Addresses
   */

  email: {
    support: 'support@communityshare.org',
    team: 'team@communityshare.org'
  },

  networkLogo: {
    staging: tucson,
    pima: tucson,
    tucson,
    lascruces,
    yumaabec,
    westcler,
    'summit-ca': summit,
    'summit-wa': summit,
    hcs: hcs,
    ednium,
    flagstaff,
    wnc: 'https://cdn.filestackcontent.com/jeCTj0f4QZap8hFfr8cy',
    wps: 'https://cdn.filestackcontent.com/i4nTxA3SAqT7KnwutI4m'
  },

  networkName: {
    staging:     'Pima County School Superintendent',
    tucson:      'Pima County School Superintendent',
    pima:        'Pima County School Superintendent',
    lascruces:   'Las Cruces',
    yumaabec:    'Yuma ABEC',
    westcler:    'West Clermont',
    'summit-ca': 'Summit Public Schools CA',
    'summit-wa': 'Summit Public Schools WA',
    hcs:         'Hamilton County Schools',
    ednium:      'Ednium: The Alumni Collective',
    flagstaff:   'Flagstaff Unified School District',
    wnc: 'WNC STEM Leaders',
    wps: 'North Shore'
  }

};
