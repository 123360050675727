import React from 'react';
import LazyMetrics from '../../dashboard-year/LazyMetrics';
import EventActivityYear from '../../dashboard-year/Events/EventActivityYear';
import EventResponseYear from '../../dashboard-year/Events/EventResponseYear';
import EventStatusYear from '../../dashboard-year/Events/EventStatusYear';
import TotalYear from '../../dashboard-year/Events/TotalYear';
import StudentsYear from '../../dashboard-year/Events/StudentsYear';
import InvitationsByMonth from '../../dashboard-year/Events/InvitationsByMonth';
import InvitationCreatorYear from '../../dashboard-year/Events/InvitationCreatorYear';
import InvitationDeclinerYear from '../../dashboard-year/Events/InvitationDeclinerYear';
import InvitationDeclineReasonYear from '../../dashboard-year/Events/InvitationDeclineReasonYear';

export default function Events(props) {
  const { params } = props;

  return (
    <>
      <LazyMetrics
        // title="Events"
        columns={1}
      >
        {/*<EventActivityYear {...params} />*/}
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
          <EventResponseYear {...params} />
          <EventStatusYear {...params} />
        </div>
        <EventActivityYear {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <TotalYear {...params} titleOnly={true} />
          <StudentsYear {...params} titleOnly={true} />
        </div>
      </LazyMetrics>
      <LazyMetrics
        title="Invitations - Events"
        columns={1}
      >
        <InvitationsByMonth {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
          <InvitationCreatorYear {...params} />
          <InvitationDeclinerYear {...params} />
        </div>
        <InvitationDeclineReasonYear {...params} />
      </LazyMetrics>
    </>
  );
}
