import useRouter from '../../hooks/useRouter';
import useQueryMeta from './useQueryMeta';

export default function useGetCount(query) {
  const { location } = useRouter();
  const { network, params, pagination } = useQueryMeta();

  const result = query({ network, params, pagination });

  return result.meta ? result.meta.totalCount : '...';
}
