import React from 'react';
import _ from 'lodash';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import REQUIRED_FIELDS from '../../projects/REQUIRED_FIELDS';
import ChartLoader from '../../dashboard/_charts/ChartLoader';
import LegendChart from '../../dashboard/_charts/LegendChart';

export default function ProjectActivityYear(props) {
  const { network, schoolStart, schoolEnd, subtitle, activities } = props;

  const allProjects = useConnect('project.find', {
    where: {
      eager: {
        $where: {
          $and: REQUIRED_FIELDS.$and,
          createdAt: {
            $lte: schoolEnd,
            $gte: schoolStart
          },
          networkId: network?.id
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  const requests = _.sortBy(activities.data, at => at.data.name).map(function(activity) {
    return {
      label: activity.data.name,
      projects: useConnect('project.find', {
        where: {
          eager: {
            $where: {
              $and: REQUIRED_FIELDS.$and,
              'activity.id': activity.id,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              },
              networkId: network?.id
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      })
    };
  });

  const loading = _.reduce(requests, function(result, request) {
    return result || request.projects.state === PayloadStates.FETCHING;
  }, false)

  if (loading || allProjects.state === PayloadStates.FETCHING) {
    return (
      <ChartLoader
        title="Projects by Activity"
        subtitle={subtitle || 'For school year'}
      />
    );
  }

  const sortedRequests = _.sortBy(requests, r => -r.projects.meta.totalCount);

  const series = sortedRequests.map(r => r.projects.meta.totalCount);
  const labels = sortedRequests.map(r => r.label);

  return (
    <LegendChart
      title="Projects by Activity"
      subtitle={subtitle || 'For school year'}
      labels={labels}
      series={series}
    />
  );
}
