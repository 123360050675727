import React from 'react';
import classNames from 'classnames';
import Header from './Header';
import District from './District';
import Networks from './Networks';
import Projects from './Projects';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

export default function Site(props) {
  const { site, className, children } = props;

  const launch = useDialogLauncher();

  function onClick() {
    launch(DialogManager.SiteUpdate.Config(site.id));
  }

  return (
    <div className={classNames(
      'flex flex-col bg-white',
      'shadow-cs-flat rounded-cs-10',
      '-m-0.5 border-2 border-cs-gray-100',
      'relative overflow-hidden',
      'hover:bg-cs-orange-50 cursor-pointer',
      className
    )} onClick={onClick}>
      <div className="flex flex-col py-4 px-4 space-y-2">
        <div className="pr-10">
          <Header site={site} />
        </div>
        <div className="flex flex-col sm:flex-row items-start justify-between">
          <div className="flex flex-1 flex-wrap gap-x-4 gap-y-2 mb-2 sm:mb-0">
            <District site={site} />
            <Networks site={site} />
          </div>
          <Projects site={site}/>
        </div>
      </div>
      {children}
    </div>
  );
}
