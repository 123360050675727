import React from 'react';
import { PayloadStates } from '@lore/utils';
import MetricLoader from '../MetricLoader';
import DeltaMetric from '../DeltaMetric';
import useEducators from './_hooks/useEducators';
import moment from 'moment-timezone';
import ComparisonMetric from '../ComparisonMetric';
import useInactiveEducators from './_hooks/useInactiveEducators';
import useSleepingEducators from './_hooks/useSleepingEducators';
import useIncompleteEducators from './_hooks/useIncompleteEducators';
import useActiveEducators from './_hooks/useActiveEducators';

export default function CompositeEducatorGrowth(props) {
  const { network, schoolStart, schoolEnd, titleOnly, currentTimestamp, showTotals = true } = props;

  const previousSchoolStart = titleOnly ? undefined : moment(schoolStart).subtract(1, 'year').toISOString();

  const educators = {
    new: {
      total: useEducators(network, undefined, schoolEnd),
      previous: useEducators(network, previousSchoolStart, schoolStart),
      new: useEducators(network, schoolStart, schoolEnd)
    },
    inactive: {
      total: useInactiveEducators(network, undefined, schoolEnd, currentTimestamp),
      previous: useInactiveEducators(network, previousSchoolStart, schoolStart, currentTimestamp),
      new: useInactiveEducators(network, schoolStart, schoolEnd, currentTimestamp)
    },
    sleeping: {
      total: useSleepingEducators(network, undefined, schoolEnd, currentTimestamp),
      previous: useSleepingEducators(network, previousSchoolStart, schoolStart, currentTimestamp),
      new: useSleepingEducators(network, schoolStart, schoolEnd, currentTimestamp)
    },
    incomplete: {
      total: useIncompleteEducators(network, undefined, schoolEnd, currentTimestamp),
      previous: useIncompleteEducators(network, previousSchoolStart, schoolStart, currentTimestamp),
      new: useIncompleteEducators(network, schoolStart, schoolEnd, currentTimestamp)
    },
    active: {
      total: useActiveEducators(network, undefined, schoolEnd, currentTimestamp),
      previous: useActiveEducators(network, previousSchoolStart, schoolStart, currentTimestamp),
      new: useActiveEducators(network, schoolStart, schoolEnd, currentTimestamp)
    }
  };

  if (
    educators.new.total.state === PayloadStates.FETCHING ||
    educators.new.previous.state === PayloadStates.FETCHING ||
    educators.new.new.state === PayloadStates.FETCHING ||
    educators.inactive.total.state === PayloadStates.FETCHING ||
    educators.inactive.previous.state === PayloadStates.FETCHING ||
    educators.inactive.new.state === PayloadStates.FETCHING ||
    educators.sleeping.total.state === PayloadStates.FETCHING ||
    educators.sleeping.previous.state === PayloadStates.FETCHING ||
    educators.sleeping.new.state === PayloadStates.FETCHING ||
    educators.incomplete.total.state === PayloadStates.FETCHING ||
    educators.incomplete.previous.state === PayloadStates.FETCHING ||
    educators.incomplete.new.state === PayloadStates.FETCHING ||
    educators.active.total.state === PayloadStates.FETCHING ||
    educators.active.previous.state === PayloadStates.FETCHING ||
    educators.active.new.state === PayloadStates.FETCHING
  ) {
    return (
      <>
        {showTotals && (
          <>
            <MetricLoader
              name="Total educator signups"
            />
            <MetricLoader
              name="Total active educators"
            />
            <MetricLoader
              name="Total sleeping educators"
            />
            <MetricLoader
              name="Total inactive educators"
            />
            <MetricLoader
              name="Total incomplete educators"
            />
          </>
        )}
        {!titleOnly && (
          <>
            <MetricLoader
              name="Educator signups"
            />
            <MetricLoader
              name="Active educators"
            />
            <MetricLoader
              name="Sleeping educators"
            />
            <MetricLoader
              name="Inactive educators"
            />
            <MetricLoader
              name="Incomplete educators"
            />
          </>
        )}
      </>
    );
  }

  return (
    <>
      {showTotals && (
        <>
          <DeltaMetric
            name="Total educator signups"
            value={educators.new.total.meta.totalCount}
            delta={educators.new.new.meta.totalCount}
            titleOnly={titleOnly}
          />
          <DeltaMetric
            name="Total active educators"
            value={educators.active.total.meta.totalCount}
            delta={educators.active.new.meta.totalCount}
            titleOnly={titleOnly}
          />
          <DeltaMetric
            name="Total sleeping educators"
            value={educators.sleeping.total.meta.totalCount}
            delta={educators.sleeping.new.meta.totalCount}
            titleOnly={titleOnly}
          />
          <DeltaMetric
            name="Total inactive educators"
            value={educators.inactive.total.meta.totalCount}
            delta={educators.inactive.new.meta.totalCount}
            titleOnly={titleOnly}
          />
          <DeltaMetric
            name="Total incomplete educators"
            value={educators.incomplete.total.meta.totalCount}
            delta={educators.incomplete.new.meta.totalCount}
            titleOnly={titleOnly}
          />
        </>
      )}
      {!titleOnly && (
        <>
          <ComparisonMetric
            name="Educator signups"
            value={educators.new.new.meta.totalCount}
            previousValue={educators.new.previous.meta.totalCount}
            titleOnly={titleOnly}
          />
          <ComparisonMetric
            name="Active educators"
            value={educators.active.new.meta.totalCount}
            previousValue={educators.active.previous.meta.totalCount}
            titleOnly={titleOnly}
          />
          <ComparisonMetric
            name="Sleeping educators"
            value={educators.sleeping.new.meta.totalCount}
            previousValue={educators.sleeping.previous.meta.totalCount}
            titleOnly={titleOnly}
          />
          <ComparisonMetric
            name="Inactive educators"
            value={educators.inactive.new.meta.totalCount}
            previousValue={educators.inactive.previous.meta.totalCount}
            titleOnly={titleOnly}
          />
          <ComparisonMetric
            name="Incomplete educators"
            value={educators.incomplete.new.meta.totalCount}
            previousValue={educators.incomplete.previous.meta.totalCount}
            titleOnly={titleOnly}
          />
        </>
      )}
    </>
  );
}
