import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import useNetwork from '../../../../src/hooks/useNetwork';
import { stringify } from 'query-string';

export default function usePageRouter() {
  const navigate = useNavigate();
  const location = window.location;
  const params = useParams();
  const network = useNetwork();

  return {
    navigate: navigate,
    history: {
      // push: navigate
      push: function() {
        if (_.isString(arguments[0])) {
          if (!!network) {
            const pathname = arguments[0];
            return navigate({
              pathname: pathname,
              search: stringify({
                networks: network.id
              })
            });
          }
        }
        return navigate.apply(null, arguments);
      }
    },
    location,
    match: {
      params
    }
  };
}
