import React from 'react';
import PropTypes from 'prop-types';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Tooltip from '../../../components/Tooltip';

export default function Organization(props) {
  const { user } = props;

  const organization = useConnect('organization.first', {
    where: {
      eager: {
        $where: {
          'jobs.userId': user.id
        }
      }
    }
  });

  const site = useConnect('site.first', {
    where: {
      eager: {
        $where: {
          'roles.userId': user.id
        }
      }
    }
  });

  return (
    <div className="flex space-x-1 items-center text-gray-500 text-sm">
      <Tooltip tooltip={user.data.isEducator ? 'School' : 'Organization'} icon={false}>
        <BuildingOfficeIcon className="h-6 w-6" />
      </Tooltip>
      <span>
        {(
          organization.state === PayloadStates.FETCHING ||
          site.state === PayloadStates.FETCHING
        ) ?
          '...' :
          organization.data.name || site.data.name || '[None]'
        }
      </span>
    </div>
  );
};
