import React  from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import ClampableText from '../../components/ClampableText';
import Avatar from '../../components/Avatar';
import logo from '../../../assets/images/logo.png';
import { getDistanceFromLatLonInMiles } from '../../components/User/utils';
import TagTypes from '../../constants/TagTypes';
import useEventTags from '../../hooks/useEventTags';

export default function useEventInviteEmailPreview(event, partner, customMessage, currentUser, network) {
  const educator = useConnect('user.byId', {
    id: event.data.creatorId
  });
  const distance = getDistanceFromLatLonInMiles(partner.data, educator.data);

  const activity = useConnect('tag.byId', {
    id: event.data.activityId
  });

  const eventInteractionTypes = useEventTags(event, TagTypes.InteractionType);

  if (
    educator.state === PayloadStates.FETCHING ||
    activity.state === PayloadStates.FETCHING ||
    eventInteractionTypes.state === PayloadStates.FETCHING
  ) {
    return '...';
  }

  function getActivity() {
    return {
      title: `${activity.data.name}`,
      text: `${activity.data.description}`
    };
  }

  function getSummary() {
    return {
      title: `${event.data.title || activity.data.name}`,
      text: `${event.data.interactionDescription || activity.data.description}`
    };
  }

  function getDate() {
    if (event.data.date) {
      return {
        title: `Date`,
        text: `${moment(event.data.date).format('MMM Do, YYYY')}`
      };
    }

    return {
      title: `Date`,
      text: `(not provided)`
    };
  }

  function getTime() {
    if (
      event.data.startTime &&
      event.data.endTime
    ) {
      const startTime = moment(event.data.startTime).tz(educator.data.timezone).format('h:mm a');
      const endTime = moment(event.data.endTime).tz(educator.data.timezone).format('h:mm a');
      const timezone = moment().tz(educator.data.timezone).format('z');

      return {
        title: `Time`,
        text: `${startTime} - ${endTime} ${timezone}`
      };
    }

    return {
      title: `Time`,
      text: `(not provided)`
    };
  }

  function getInPerson() {
    return !!_.find(eventInteractionTypes.data, function(eventInteractionType) {
      return eventInteractionType.data.name === 'In-person';
    });
  }

  function getVirtual() {
    return !!_.find(eventInteractionTypes.data, function(eventInteractionType) {
      return eventInteractionType.data.name === 'Virtual';
    });
  }

  const data = {
    activity: getActivity(),
    summary: getSummary(),
    date: getDate(),
    time: getTime(),
    inPerson: getInPerson(),
    virtual: getVirtual()
  };

  return (
    <div className="bg-cs-gray-old p-6">
      <div className="h-1 bg-accent w-full mb-8"/>
      <div className="p-6 mb-8">
        <img
          className="w-24 mx-auto"
          src={logo}
          alt="logo"
        />
      </div>
      <div className="mb-8">
        <div className="text-base text-gray-500">
          Hi {partner.data.firstName},
        </div>
        <div className="my-3 text-base text-gray-500">
          <ClampableText markup>
            {customMessage}
          </ClampableText>
        </div>
        <div className="mb-3">
          <div className="text-base text-gray-500">
            Cheers,
          </div>
        </div>
        <div className="flex items-center space-x-3">
          <div>
            <Avatar size={12} src={currentUser.data.avatarUrl} />
          </div>
          <div>
            <div className="text-lg text-gray-700 font-semibold">
              {currentUser.data.firstName} {currentUser.data.lastName}
            </div>
            <div className="text-sm text-gray-500">
              Steward for {currentUser.data.isStaff ? 'CommunityShare' : network?.data.name || 'CommunityShare'}
            </div>
          </div>
        </div>
      </div>
      <div className="mb-8">
        <div className="flex flex-col text-center justify-center bg-accent rounded-xl p-6">
          <div className="text-2xl text-white font-bold mb-2">
            Are you available?
          </div>
          <div className="text-base text-white mb-4">
            Let {educator.data.firstName} know whether you plan to attend, have questions,
            or have to send your regrets this time.
          </div>
          <div className="bg-white rounded-lg w-56 text-center py-3 m-auto">
            <div className="text-base text-accent uppercase font-bold">
              RSVP Now
            </div>
          </div>
        </div>
      </div>
      <div className="mb-8 space-y-4">
        <div className="text-2xl font-bold">
          Details
        </div>
        <div className="bg-white rounded-lg p-6 space-y-8">
          <div className="text-2xl font-semibold">
            {data.summary.title}
          </div>
          <div>
            <div className="text-xl font-semibold">
              {data.date.title}
            </div>
            <div className="text-gray-500">
              <ClampableText markup>
                {data.date.text}
              </ClampableText>
            </div>
          </div>
          <div>
            <div className="text-xl font-semibold">
              {data.time.title}
            </div>
            <div className="text-gray-500">
              <ClampableText markup>
                {data.time.text}
              </ClampableText>
            </div>
          </div>
          <div>
            <div className="text-xl font-semibold">
              Collaboration Options
            </div>
            {!data.virtual && !data.inPerson && (
              <div>
                <div className="text-gray-700">
                  (not provided)
                </div>
                <div className="text-xs text-gray-500">
                  {distance <= 1 ? '1 mile away' : `${distance} miles away`}
                </div>
              </div>
            )}
            {data.virtual && (
              <div>
                <div className="text-gray-700 mb-1">
                  Virtual
                </div>
              </div>
            )}
            {data.inPerson && (
              <div>
                <div className="text-gray-700">
                  In-person
                </div>
                <div className="text-xs text-gray-500">
                  {distance <= 1 ? '1 mile away' : `${distance} miles away`}
                </div>
              </div>
            )}
          </div>
          <div>
            <div className="text-xl font-semibold">
              Description
            </div>
            <div className="text-base text-gray-500">
              <ClampableText markup>
                {data.summary.text}
              </ClampableText>
            </div>
          </div>
        </div>
        <div className="text-2xl font-bold">
          Activity
        </div>
        <div className="bg-white rounded-lg p-6">
          <div className="text-xl font-semibold">
            {data.activity.title}
          </div>
          <div className="text-gray-500">
            {data.activity.text}
          </div>
        </div>
      </div>
      <div className="mb-8">
        <div className="flex flex-col text-center justify-center bg-accent rounded-xl p-6">
          <div className="text-2xl text-white font-bold mb-2">
            Are you available?
          </div>
          <div className="text-base text-white mb-4">
            Let {educator.data.firstName} know whether you plan to attend, have questions,
            or have to send your regrets this time.
          </div>
          <div className="bg-white rounded-lg w-56 text-center py-3 m-auto">
            <div className="text-base text-accent uppercase font-bold">
              RSVP Now
            </div>
          </div>
        </div>
      </div>
      <div className="h-1 bg-accent w-full"/>
    </div>
  );
};
