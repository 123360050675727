import React from 'react';
import titleCase from '../../../utils/titleCase';

export default function Header(props) {
  const { site } = props;

  return (
    <div className="flex flex-col space-y-2">
      <div className="space-y-0.5">
        <div className="text-lg font-semibold text-cs-gray-900">
          {titleCase(site.data.name)}
        </div>
        <div className="text-base text-cs-gray-500 line-clamp-3">
          {titleCase(site.data.addressLine1)}, {titleCase(site.data.city)}, {site.data.state}
        </div>
      </div>
    </div>
  );
}
