import React from 'react';
import useEducators from './useEducators';
import { REQUIRED_EDUCATOR_FIELDS } from '../../../../utils/user/REQUIRED_FIELDS';

export default function useActiveEducators(state, network, schoolStart, schoolEnd, currentTimestamp) {
  return useEducators(state, network, schoolStart, schoolEnd, {
    ...REQUIRED_EDUCATOR_FIELDS,
    inactive: false,
    $or: [
      {
        sleepingStartingAt: {
          $gte: currentTimestamp
        }
      },
      {
        sleepingEndingAt: {
          $lte: currentTimestamp
        }
      }
    ]
  });
}
