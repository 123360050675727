import React from 'react';
import { useConnect } from '@lore/query-connect';

export default function useClaimsReal(state, network, schoolStart, schoolEnd, extra = {}, eager = {}) {
  return useConnect('claim.find', {
    where: {
      eager: {
        $where: {
          // 'offer.networkId': network?.id,
          'offer.creator.state': {
            $ilike: state
          },
          createdAt: {
            $lte: schoolEnd,
            $gte: schoolStart
          },
          ...extra
        },
        ...eager
      }
    },
    pagination: {
      pageSize: 1000
    }
  });
}
