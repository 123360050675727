import useRouter from '../../hooks/useRouter';

export default function useGetCount(query, params = {}) {
  const { location } = useRouter();

  const result = query({
    location,
    network: params.network,
    schoolStart: params.schoolStart,
    schoolEnd: params.schoolEnd
  });

  return result.meta ? result.meta.totalCount : '...';
}
