import React from 'react';
import classNames from 'classnames';

export default function Banner(props) {
  const { children } = props;

  if (children) {
    return (
      <div className={classNames(
        'bg-cs-blue-500 text-center flex justify-center items-center text-white py-2 px-6',
        // 'mx-6 mt-4',
        'rounded-cs-10 shadow-cs-flat'
      )}>
        {children}
      </div>
    );
  }

  return null;
};
