import React from 'react';
import { PayloadStates } from '@lore/utils';
import MetricLoader from '../MetricLoader';
import DeltaMetric from '../DeltaMetric';
import useProjectStudentImpact from './_hooks/useProjectStudentImpact';
import useEventStudentImpact from './_hooks/useEventStudentImpact';
import _ from 'lodash';
import ChartLoader from '../../dashboard/_charts/ChartLoader';
import LineChart from '../../dashboard/_charts/LineChart';
import colors from 'tailwindcss/colors';

export default function ImpactYearChart(props) {
  const { network, timestamps, empty, schoolStart, schoolEnd } = props;

  const results = timestamps.map(function(timestamp) {
    const [projects, projectCount] = useProjectStudentImpact(network, timestamp.start, timestamp.end);
    const [events, eventCount] = useEventStudentImpact(network, timestamp.start, timestamp.end);

    return {
      label: timestamp.label,
      projects: {
        state: projects.state,
        data: projects.data,
        count: projectCount
      },
      events: {
        state: events.state,
        data: events.data,
        count: eventCount
      }
    };
  });

  const loading = _.reduce(results, function(result, data) {
    return (
      result ||
      data.projects.state === PayloadStates.FETCHING ||
      data.events.state === PayloadStates.FETCHING
    );
  }, false);

  if (loading) {
    return (
      <ChartLoader
        title="Number of students impacted (as reported by educators)"
        subtitle="Over school year"
      />
    );
  }

  const series = [
    {
      name: 'Project impact',
      data: results.map(r => r.projects.count)
    },
    {
      name: 'Event impact',
      data: results.map(r => r.events.count)
    }
  ];

  return (
    <LineChart
      title="Number of students impacted (as reported by educators)"
      subtitle="Over school year"
      labels={results.map(e => e.label)}
      series={series}
      colors={[
        colors.blue['400'],
        colors.emerald['400'],
        colors.yellow['400']
      ]}
      // legend={false}
    />
  );
}
