import { parse, stringify } from 'query-string';
import useStewardshipFilter from '../../events/useStewardshipFilter';
import { useConnect } from '../../../../hooks/@lore/query-connect';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 10, extra = {} } = props;

  const stewardshipFilter = useStewardshipFilter();

  return useConnect('event.find', {
    where: {
      eager: {
        $where: {
          activityId: {
            $exists: true
          },
          siteId: {
            $exists: false
          },
          $or: stewardshipFilter,
          ...extra
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    }
  }, {
    keepPreviousData: true
  });
}
