import React from 'react';
import PropTypes from 'prop-types';
import classNames from '../../utils/classNames';
import Card from '../../components/Card';

Site.propTypes = {
  site: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

export default function Site(props) {
  const { site, onClick, selected = false } = props;

  return (
    <Card
      className="p-4 hover:bg-gray-50 cursor-pointer"
      // background={false}
      // border={false}
      // className={classNames(
      //   'flex p-6 space-x-4 cursor-pointer hover:bg-cs-orange-50',
      //   selected ? '-m-0.5 border-2 border-cs-orange-500 bg-white' : '-m-px border border-cs-gray-200 bg-white'
      // )}
      onClick={() => onClick(site)}
    >
      <div className="flex-1">
        <div className="flex justify-between">
          <h2 className="text-base font-semibold text-cs-gray-900">
            {site.data.name}
          </h2>
        </div>
        {/*<div className="text-sm text-cs-gray-500">*/}
        {/*  {site.data.addressLine1}, {site.data.city}, {site.data.state}*/}
        {/*</div>*/}
        <div className="flex justify-between">
          <div className="text-sm text-cs-gray-500">
            {site.data.addressLine1}
          </div>
          <div className="text-sm text-cs-gray-500">
            {site.data.city}, {site.data.state}
          </div>
        </div>
      </div>
    </Card>
);
};
