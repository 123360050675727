import React from 'react';
import LazyMetrics from '../../dashboard-year/LazyMetrics';
import EngagementByMonth from '../../dashboard-year/Educators/EngagementByMonth';
import SharedProject from '../../dashboard-year/Educators/SharedProject';
import SharedEvent from '../../dashboard-year/Educators/SharedEvent';
import InvitedToOffer from '../../dashboard-year/Educators/InvitedToOffer';
import InvitedToOfferAndResponded from '../../dashboard-year/Educators/InvitedToOfferAndResponded';
import CreatedInviteToOffer from '../../dashboard-year/Educators/CreatedInviteToOffer';
import EducatorGrowthByMonth from '../../dashboard-year/Educators/EducatorGrowthByMonth';
import CompositeEducatorGrowth from '../../dashboard-year/Educators/CompositeEducatorGrowth';
import EducatorStatusYear from '../../dashboard-year/Educators/EducatorStatusYear';

export default function Educators(props) {
  const { params } = props;

  return (
    <>
      <LazyMetrics
        // title="Growth"
        columns={1}
      >
        {/*<EducatorGrowthByMonth {...params} />*/}
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
          <EducatorStatusYear {...params} />
        </div>
        <EducatorGrowthByMonth {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <CompositeEducatorGrowth {...params} titleOnly={true} />
        </div>
      </LazyMetrics>
      <LazyMetrics title="Engagement" columns={1}>
        <EngagementByMonth {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <SharedProject {...params} titleOnly={true} />
          <SharedEvent {...params} titleOnly={true} />
          <InvitedToOffer {...params} titleOnly={true} />
          <InvitedToOfferAndResponded {...params} titleOnly={true} />
          <CreatedInviteToOffer {...params} titleOnly={true} />
        </div>
      </LazyMetrics>
    </>
  );
}
