import { useState } from 'react';
import { useConnect } from '@lore/query-connect';
import { parse } from 'query-string';
import moment from 'moment-timezone';
import useStewardshipFilter from './useStewardshipFilter';
import getQueryUtils, { attributeKeys } from './queryUtils';

export default function useQuery(props) {
  const {
    search = {},
    pageSize = 10
  } = props;

  const stewardshipFilter = useStewardshipFilter();

  const queryUtils = getQueryUtils(search, attributeKeys);

  return useConnect('site.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: `numberOfProjectsCompleted`,
            relation: 'projects',
            $where: {
              activityId: {
                $exists: true
              },
              completed: true
            }
          },
          {
            type: 'count',
            alias: `numberOfProjectsCancelled`,
            relation: 'projects',
            $where: {
              activityId: {
                $exists: true
              },
              cancelled: true
            }
          },
          {
            type: 'count',
            alias: `numberOfProjectsActive`,
            relation: 'projects',
            $where: {
              activityId: {
                $exists: true
              },
              completed: false,
              cancelled: false
            }
          },
          {
            type: 'count',
            alias: `numberOfEventsCompleted`,
            relation: 'events',
            $where: {
              activityId: {
                $exists: true
              },
              completed: true
            }
          },
          {
            type: 'count',
            alias: `numberOfEventsCancelled`,
            relation: 'events',
            $where: {
              activityId: {
                $exists: true
              },
              cancelled: true
            }
          },
          {
            type: 'count',
            alias: `numberOfEventsActive`,
            relation: 'events',
            $where: {
              activityId: {
                $exists: true
              },
              completed: false,
              cancelled: false
            }
          }
        ],
        $where: {
          $and: [...queryUtils.where],
          networks: {
            $count_gt: 0
          },
          $or: stewardshipFilter
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: pageSize,
      order: search.order || 'createdAt desc'
    }
  }, {
    keepPreviousData : true
  });
}
