import React from 'react';
import State from './State';
import ParentLayout from './ParentLayout';
import { useParams } from 'react-router-dom';
import stateMap from '../states/stateMap';

export default function Layout(props) {
  const routeParams = useParams();

  const title = stateMap[routeParams.state.toUpperCase()];

  if (!title) {
    return (
      <ParentLayout title="Unknown state">
        {() => {}}
      </ParentLayout>
    );
  }

  return (
    <ParentLayout title={title}>
      {function(params) {
        return (
          <>
            <State params={params} />
          </>
        );
      }}
    </ParentLayout>
  );
}
