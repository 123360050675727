import stateMap from '../stateMap';
import _ from 'lodash';

export default function(network, currentUser, currentNavigationLink) {
  const root = `${currentNavigationLink.href}`;

  return _.reduce(stateMap, function(result, value, key) {
    result.push({
      name: value,
      href: `${root}/state/${key}`,
      // icon: CalculatorIcon
    });
    return result;
  }, []);
};
