import React, { useState } from 'react';
import { parse, stringify } from 'query-string';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';
import TagTypes from '../../constants/TagTypes';
import SearchFilter from '../../forms/_fields_v2/SearchFilter';
import DisclosureTagFilter from '../../forms/_fields_v2/DisclosureTagFilter';
// import SearchTags from '../../components/SearchTags';
import ClearAllFilters from '../../components/ClearAllFilters';
import { getSearchUtils } from '../../utils/query-utils';
import useQuery from '../../pages/partners-engagement/useQuery';
import useFilterCount from '../../hooks/useFilterCount';
import useStewardshipFilter from './useStewardshipFilter';
import useInteractionTypesFilterParam from './useInteractionTypesFilterParam';
import useDistanceFilterParam from './useDistanceFilterParam';
// import REQUIRED_FIELDS_PUBLIC from '../../pages/partners/REQUIRED_FIELDS_PUBLIC';
import { REQUIRED_PARTNER_FIELDS } from '../../utils/user/REQUIRED_FIELDS';
import { SolidButton } from '../../@communityshare/ui/Button';
import DisclosureFilter from '../../forms/_fields_v2/DisclosureFilter';
import { attributeKeys } from '../../pages/partners/queryUtils';
import _ from 'lodash';
import DisclosureBaseFilter from '../../forms/_fields_v2/DisclosureBaseFilter';
import PayloadStates from '../../constants/PayloadStates';
import moment from 'moment-timezone';
import useNetworkFilter from './useNetworkFilter';

export default function PartnerFiltersDialog(props) {
  const { router } = props;

  const { location, history } = router;

  const [search, setSearch] = useState(parse(location.search));

  const [timestamp] = useState(new Date().toISOString());

  const networkFilter = useNetworkFilter(search);
  // const stewardshipFilter = useStewardshipFilter(search);
  // const interactionTypesFilter = useInteractionTypesFilterParam(search);
  // const distanceFilter = useDistanceFilterParam(search);

  const filterFields = {
    name: 'name',
    organization: 'organization',
    searchPartner: 'searchPartner',

    expertiseTypes: 'expertiseTypes',
    ages: 'ages',
    activities: 'activities',
    experiences: 'experiences',
    guidances: 'guidances',
    groups: 'groups',
    genders: 'genders',
    ethnicities: 'ethnicities',
    createdDates: 'createdDates',
    hours: 'hours',
    contributions: 'contributions'
  };

  const filterCount = useFilterCount(search, _.keys(filterFields));

  function onSubmit() {
    history.push({
      pathname: location.pathname,
      search: stringify({
        ...search,
        page: 1
      })
    });
    props.onClose();
  }

  function onChange(nextSearch) {
    setSearch(nextSearch);
  }

  function getTagParams(tag) {
    return {
      where: {
        eager: {
          $where: {
            ...networkFilter,
            // ...stewardshipFilter,
            // ...interactionTypesFilter,
            // ...distanceFilter,
            'tagNodes.tagId': tag.id,
            isEducator: false,
            // $and: REQUIRED_FIELDS_PUBLIC.$and
          },
        }
      },
      pagination: {
        pageSize: 1
      }
    };
  }

  function getParentTagParams(tag) {
    return {
      where: {
        eager: {
          $where: {
            ...networkFilter,
            // ...stewardshipFilter,
            // ...interactionTypesFilter,
            // ...distanceFilter,
            'tagTrees.parentId': tag.id,
            isEducator: false,
            // $and: REQUIRED_FIELDS_PUBLIC.$and
          },
        }
      },
      pagination: {
        pageSize: 1
      }
    };
  }

  const searchUtils = getSearchUtils(search, onChange);

  const result = useQuery({
    search
  });

  return (
    <DialogTemplate className="w-screen max-w-screen-sm h-[calc(100dvh-32px)] max-h-[calc(720px)]">
      <CloseDialogButton onClick={props.onClose} />
      <div className="flex flex-col space-y-8x h-full">
        <div className="space-y-1 p-6 border-b border-cs-gray-200">
          <h2 className="text-cs-gray-900 text-2xl font-semibold">
            Filters
          </h2>
          <div className="text-cs-gray-500 text-sm">
            Narrow your search results.
          </div>
          {/*<div className="pt-4">*/}
          {/*  <SearchTags*/}
          {/*    search={search}*/}
          {/*    onChange={setSearch}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        <div className="px-6 py-8 space-y-8 overflow-y-auto h-full">
          <div className="space-y-4">
            <DisclosureFilter
              label="Search"
              value={[
                searchUtils(filterFields.name).value,
                searchUtils(filterFields.organization).value,
                searchUtils(filterFields.searchPartner).value
              ]}
            >
              <SearchFilter
                label="Name"
                description="Find partners whose first or last name matches any of the words you enter."
                value={searchUtils(filterFields.name).value}
                onChange={searchUtils(filterFields.name).onChangeValue}
              />
              <SearchFilter
                label="Organization"
                description="Find partners affiliated with an organization whose name contains the phrase you type."
                value={searchUtils(filterFields.organization).value}
                onChange={searchUtils(filterFields.organization).onChangeValue}
              />
              <SearchFilter
                label="Profile"
                description="Find partners that have a specific phrase in their biography, or in the description of their skills and experience."
                value={searchUtils(filterFields.searchPartner).value}
                onChange={searchUtils(filterFields.searchPartner).onChangeValue}
              />
            </DisclosureFilter>
          </div>
          <DisclosureTagFilter
            modelName="user"
            type={TagTypes.ExpertiseType}
            label="Skills and experience"
            getParams={getParentTagParams}
            value={searchUtils(filterFields.expertiseTypes).value}
            onChange={searchUtils(filterFields.expertiseTypes).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="user"
            type={TagTypes.Age}
            label="Age groups"
            getParams={getTagParams}
            value={searchUtils(filterFields.ages).value}
            onChange={searchUtils(filterFields.ages).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="user"
            type={TagTypes.Activity}
            label="Activites"
            getParams={getTagParams}
            value={searchUtils(filterFields.activities).value}
            onChange={searchUtils(filterFields.activities).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="user"
            type={TagTypes.Experience}
            label="Life experience"
            getParams={getTagParams}
            value={searchUtils(filterFields.experiences).value}
            onChange={searchUtils(filterFields.experiences).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="user"
            type={TagTypes.Guidance}
            label="Professional competencies"
            getParams={getTagParams}
            value={searchUtils(filterFields.guidances).value}
            onChange={searchUtils(filterFields.guidances).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="user"
            type={TagTypes.Group}
            label="Youth types"
            getParams={getTagParams}
            value={searchUtils(filterFields.groups).value}
            onChange={searchUtils(filterFields.groups).onChangeArray}
          />
          <DisclosureBaseFilter
            modelName="user"
            label="Gender"
            getParams={function getGenderParams(model) {
              return {
                where: {
                  eager: {
                    $where: {
                      gender: model.data.name,
                      isEducator: false,
                      // $or: stewardshipFilter
                      ...networkFilter,
                    }
                  }
                },
                pagination: {
                  pageSize: 1
                }
              };
            }}
            value={searchUtils(filterFields.genders).value}
            onChange={searchUtils(filterFields.genders).onChangeArray}
            tags={{
              state: PayloadStates.RESOLVED,
              data: [
                'Man',
                'Woman',
                'Agender',
                'Gender Fluid',
                'Gender Non-conforming',
                'Genderqueer',
                'Non-binary',
                'Questioning',
                'Self Identify Another Option',
                'Prefer Not to Say'
              ].map(function(value, index) {
                return {
                  id: index + 1,
                  data: {
                    name: value
                  }
                }
              })
            }}
          />
          <DisclosureBaseFilter
            modelName="user"
            label="Ethnicity"
            getParams={function getEthnicityParams(model) {
              return {
                where: {
                  eager: {
                    $where: {
                      ethnicity: model.data.name,
                      isEducator: false,
                      // $or: stewardshipFilter
                      ...networkFilter,
                    }
                  }
                },
                pagination: {
                  pageSize: 1
                }
              };
            }}
            value={searchUtils(filterFields.ethnicities).value}
            onChange={searchUtils(filterFields.ethnicities).onChangeArray}
            tags={{
              state: PayloadStates.RESOLVED,
              data: [
                'Native American / American Indian or Alaska Native',
                'Asian or Asian American',
                'Black or African American',
                'Hispanic or Latino',
                'Native Hawaiian or Other Pacific Islander',
                'White or Caucasian',
                'Multiracial',
                'Other',
                'Prefer Not to Say'
              ].map(function(value, index) {
                return {
                  id: index + 1,
                  data: {
                    name: value
                  }
                }
              })
            }}
          />
          <DisclosureBaseFilter
            modelName="user"
            label="Joined"
            getParams={function getJoinedParams(model) {
              // const weeks = Number(model.id.split('_')[1]);
              const weeks = model.id;

              return {
                where: {
                  eager: {
                    $where: {
                      createdAt: {
                        $gte: moment(timestamp).subtract(weeks, 'weeks').toISOString(),
                      },
                      isEducator: false,
                      // $or: stewardshipFilter
                      ...networkFilter,
                    }
                  }
                },
                pagination: {
                  pageSize: 1
                }
              };
            }}
            value={searchUtils(filterFields.createdDates).value}
            onChange={searchUtils(filterFields.createdDates).onChangeArray}
            tags={{
              state: PayloadStates.RESOLVED,
              data: [
                // ['lte_1_weeks', 'Within 1 week'],
                // ['lte_2_weeks', 'Within 2 weeks'],
                // ['lte_4_weeks', 'Within 1 month'],
                // ['lte_8_weeks', 'Within 2 months'],
                // ['lte_13_week', 'Within 3 months'],
                // ['lte_26_week', 'Within 6 months'],
                // ['lte_52_week', 'Within the year']
                [1, 'Within 1 week'],
                [2, 'Within 2 weeks'],
                [4, 'Within 1 month'],
                [8, 'Within 2 months'],
                [13, 'Within 3 months'],
                [26, 'Within 6 months'],
                [52, 'Within the year']
              ].map(function(value) {
                return {
                  id: value[0],
                  data: {
                    name: value[1]
                  }
                }
              })
            }}
          />
          <DisclosureBaseFilter
            modelName="user"
            label="Hours served"
            getParams={function getHoursParams(model) {
              const hours = {
                1: 0,
                2: 1
              }[model.id] || Number(model.id);

              return {
                where: {
                  eager: {
                    $aggregations: [
                      {
                        type: 'sum',
                        alias: 'numberOfHoursServed',
                        field: 'hours',
                        relation: 'partnerProjectEvaluations'
                      }
                    ],
                    $where: {
                      numberOfHoursServed: model.id === 1 ? 0 : {
                        $gte: hours,
                      },
                      isEducator: false,
                      // $or: stewardshipFilter
                      ...networkFilter,
                    }
                  }
                },
                pagination: {
                  pageSize: 1
                }
              };
            }}
            value={searchUtils(filterFields.hours).value}
            onChange={searchUtils(filterFields.hours).onChangeArray}
            tags={{
              state: PayloadStates.RESOLVED,
              data: [
                [1, '0'],
                [2, '1+'],
                [5, '5+'],
                [10, '10+'],
                [25, '25+'],
                [50, '50+'],
                [100, '100+']
              ].map(function(value) {
                return {
                  id: value[0],
                  data: {
                    name: value[1]
                  }
                }
              })
            }}
          />
          <DisclosureBaseFilter
            modelName="user"
            label="Total in-kind contributions"
            getParams={function getContributionParams(model) {
              const total = {
                1: 0,
                2: 1
              }[model.id] || Number(model.id);

              return {
                where: {
                  eager: {
                    $aggregations: [
                      {
                        type: 'sum',
                        alias: 'totalOfContributionsProvided',
                        field: 'contributionValue',
                        relation: 'partnerProjectEvaluations'
                      }
                    ],
                    $where: {
                      totalOfContributionsProvided: model.id === 1 ? 0 : {
                        $gte: total,
                      },
                      isEducator: false,
                      // $or: stewardshipFilter
                      ...networkFilter,
                    }
                  }
                },
                pagination: {
                  pageSize: 1
                }
              };
            }}
            value={searchUtils(filterFields.contributions).value}
            onChange={searchUtils(filterFields.contributions).onChangeArray}
            tags={{
              state: PayloadStates.RESOLVED,
              data: [
                [1, '$0'],
                [2, '$1+'],
                [5, '$5+'],
                [10, '$10+'],
                [25, '$25+'],
                [50, '$50+'],
                [100, '$100+'],
                [250, '$250+'],
                [500, '$500+'],
                [1000, '$1000+']
              ].map(function(value) {
                return {
                  id: value[0],
                  data: {
                    name: value[1]
                  }
                }
              })
            }}
          />
        </div>
        <div className="p-6 flex items-center justify-between border-t border-cs-gray-200">
          <ClearAllFilters
            show={true}
            className="hover:opacity-70"
            onClick={() => setSearch(_.omit(search, _.keys(filterFields)))}
            count={filterCount}
          />
          <SolidButton onClick={onSubmit}>
            Show {result.meta?.totalCount} results
          </SolidButton>
        </div>
      </div>
    </DialogTemplate>
  );
};
