import React from 'react';
import useDialogConfig from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogConfig';
import Dialog from '../csv_download';
import useNetwork from '../../hooks/useNetwork';

export default function Launcher(props) {
  const config = useDialogConfig();

  const modelName = config.params.modelName;
  const query = JSON.parse(config.params.query);

  // Force the network onto the $where object so the server doesn't reject the call
  const network = useNetwork();
  if (!query.where.eager.$where.networkId) {
    query.where.eager.$where.networkId = network?.id;
  }

  return (
    <Dialog
      modelName={modelName}
      query={query}
      onClose={props.onClose}
    />
  );
};
