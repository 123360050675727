import React from 'react';
import PropTypes from 'prop-types';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';
import Tooltip from '../../../components/Tooltip';

export default function Site(props) {
  const { event } = props;

  let site = useConnect('site.byId', {
    id: event.data.siteId
  }, {
    enabled: !!event.data.siteId
  });

  if (!event.data.siteId) {
    site = {
      data: {
        name: '[missing]'
      }
    };
  }

  return (
    <div className="flex space-x-1 items-center text-gray-500 text-sm">
      <Tooltip tooltip="School" icon={false}>
        <BuildingOfficeIcon className="h-6 w-6" />
      </Tooltip>
      <span>
        {site.data.name}
      </span>
    </div>
  );
};
