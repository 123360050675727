import React from 'react';
import { useConnect } from '@lore/query-connect';
import EvaluationTemplate from '../../feed/EvaluationTemplate';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

export default function EvaluationEventPartner(props) {
  const { evaluation } = props;

  const event = useConnect('event.byId', {
    id: evaluation.data.eventId
  });

  const activity = useConnect('tag.byId', {
    id: event.data.activityId
  }, {
    enabled: !!event.data.activityId
  });

  const launch = useDialogLauncher();

  function onClickEvent() {
    launch(DialogManager.EventManage.Config(event.id));
  }

  function onClickEvaluation() {
    launch(DialogManager.EvaluationEventPartner.Config(evaluation.id));
  }

  return (
    <EvaluationTemplate
      evaluation={evaluation}
      evaluationRole="partner"
      onClickEvaluation={onClickEvaluation}
      title={event.data.title || activity.data.name}
      opportunityType="event"
      onClickOpportunity={onClickEvent}
      last={props.last}
    />
  );
}
