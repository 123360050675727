import React from 'react';
import EmptyState from '../../@communityshare/ui/EmptyState';
import { GlobeAltIcon } from '@heroicons/react/24/outline';

export default function NoNetworkSelected(props) {
  return (
    <div className="p-8">
      <EmptyState
        icon={GlobeAltIcon}
        title="No network selected"
        subtitle="You must select a network in order to view this page."
      />
    </div>
  );
}
