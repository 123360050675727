import React from 'react';
import Network from '../../../components/Network';
import Account from '../../Account';
import NavigationLogo from '../ThinNavigation/NavigationLogo';
import ThickNavigationLink from './NavigationLink2';
import NavigationGroup from './NavigationGroup';
import usePageRouter from '../../../../hooks/@lore/dialogs-routable/_hooks/usePageRouter';
import isActiveChecker from '../ThinNavigation/isActiveChecker';
import useMainNavigationLinks from '../ThinNavigation/useMainNavigationLinks';

export default function ThickNavigation() {
  const { location, history } = usePageRouter();

  const navigationLinks = useMainNavigationLinks();

  const currentRoute = location.pathname;

  return (
    <div className="space-y-12 bg-white">
      <div className="flex">
        <div className="-mx-3">
          <NavigationGroup>
            <NavigationLogo />
          </NavigationGroup>
        </div>
      </div>
      <div className="flex">
        <div className="-mx-3">
          <NavigationGroup>
            <Network showName={true} />
          </NavigationGroup>
        </div>
      </div>
      <NavigationGroup>
        <div className="-mx-3">
          <Account />
        </div>
      </NavigationGroup>
      <div className="space-y-8">
        <NavigationGroup className="flex-grow flex flex-col">
          <div className="space-y-1">
            {navigationLinks.map((item) => (
              <ThickNavigationLink
                key={item.name}
                name={item.name}
                icon={item.icon}
                onClick={() => history.push(item.to)}
                isActive={item.startsWithOneOf ?
                  isActiveChecker.startsWithOneOf(currentRoute, item.startsWithOneOf) :
                  isActiveChecker.startsWith(currentRoute, item.to)
                }
              />
            ))}
          </div>
        </NavigationGroup>
      </div>
    </div>
  )
}
