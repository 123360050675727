import React from 'react';
import useDialogConfig from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogConfig';
import Dialog from '../network_create';
import { useQueryClient } from '@tanstack/react-query';

export default function Launcher(props) {
  const config = useDialogConfig();
  const queryClient = useQueryClient();

  return (
    <Dialog
      onClose={props.onClose}
      onSubmit={function(request) {
        queryClient.invalidateQueries({ queryKey: ['network'] });
      }}
    />
  );
};
