import React, { useState } from 'react';
import classNames from 'classnames';
import getUploadInfo from '../../../utils/getUploadInfo';

export default function Image(props) {
  const { image, imageHeight, imageChild } = props;

  const [height, setHeight] = useState(image.data.height);
  const [width, setWidth] = useState(image.data.width);

  const info = getUploadInfo(image.data.name);

  function onLoad(event) {
    setHeight(event.target.naturalHeight);
    setWidth(event.target.naturalWidth);
  }

  if (!info.isImage) {
    return (
      <div className={classNames(
        'flex flex-col relative',
        imageHeight || 'h-64',
        'bg-cs-gray-500 rounded-2xl overflow-hidden',
        'shadow-cs-flat -m-px border border-cs-gray-100',
        'cursor-pointer hover:bg-cs-orange-50x',
        'group'
      )}>
        <a
          href={image.data.imageUrl}
          data-pswp-width={width}
          data-pswp-height={height}
          target="_blank"
          rel="noreferrer"
          className="h-full"
        >
          <div className="flex flex-col items-center justify-center h-full">
            <info.icon.hack className="h-20 w-20 text-white"/>
            <div className="text-white">
              {info.extension ? `.${info.extension}` : '[filetype unknown]'}
            </div>
          </div>
        </a>
        {imageChild ? imageChild(image) : null}
      </div>
    );
  }

  return (
    <div className={classNames(
      'flex flex-col relative',
      imageHeight || 'h-64',
      'bg-cs-gray-500 rounded-2xl overflow-hidden',
      'shadow-cs-flat -m-px border border-cs-gray-100',
      'cursor-pointer hover:bg-cs-orange-50x',
      'group'
    )}>
      <a
        href={image.data.imageUrl}
        data-pswp-width={width}
        data-pswp-height={height}
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="absolute left-0 top-0 object-cover object-center h-full w-full"
          src={image.data.imageUrl}
          alt="image"
          onLoad={onLoad}
        />
      </a>
      {imageChild ? imageChild(image) : null}
      {/*<div className={classNames(*/}
      {/*  'group-hover:visible invisible absolute bottom-0',*/}
      {/*  // 'absolute bottom-0',*/}
      {/*  'w-full bg-white bg-opacity-70 px-2 py-3',*/}
      {/*  'break-all'*/}
      {/*)}>*/}
      {/*  {image.data.name}*/}
      {/*</div>*/}
    </div>
  );
};
