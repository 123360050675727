import React from 'react';
import { PayloadStates } from '@lore/utils';
import MetricLoader from '../MetricLoader';
import DeltaMetric from '../DeltaMetric';
import useSchools from './_hooks/useSchools';
import moment from 'moment-timezone';
import ComparisonMetric from '../ComparisonMetric';

export default function CompositeSchoolGrowth(props) {
  const { network, schoolStart, schoolEnd, titleOnly, showTotals = true } = props;

  const previousSchoolStart = titleOnly ? undefined : moment(schoolStart).subtract(1, 'year').toISOString();

  const totalSchools = useSchools(network, undefined, schoolEnd);
  const previousSchools = useSchools(network, previousSchoolStart, schoolStart);
  const newSchools = useSchools(network, schoolStart, schoolEnd);
  if (
    totalSchools.state === PayloadStates.FETCHING ||
    previousSchools.state === PayloadStates.FETCHING ||
    newSchools.state === PayloadStates.FETCHING
  ) {
    return (
      <>
        {showTotals && (
          <>
            <MetricLoader
              name="Total site growth"
            />
          </>
        )}
        {!titleOnly && (
          <>
            <MetricLoader
              name="School growth"
            />
          </>
        )}
      </>
    );
  }

  return (
    <>
      {showTotals && (
        <>
          <DeltaMetric
            name="Total site growth"
            value={totalSchools.meta.totalCount}
            delta={newSchools.meta.totalCount}
            titleOnly={titleOnly}
          />
        </>
      )}
      {!titleOnly && (
        <>
          <ComparisonMetric
            name="School growth"
            value={newSchools.meta.totalCount}
            previousValue={previousSchools.meta.totalCount}
            titleOnly={titleOnly}
          />
        </>
      )}
    </>
  );
}
