import React from 'react';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import IconSection from '../../../../@communityshare/ui/card/IconSection';
import { useConnect } from '@lore/query-connect';
import { DialogManager } from '../../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import classNames from 'classnames';

export default function Creator(props) {
  const { job } = props;

  const launch = useDialogLauncher();

  const user = useConnect('user.byId', {
    id: job.data.userId
  });

  function onClick() {
    launch(DialogManager.UserManage.Config(user.id));
  }

  return (
    <div className={classNames(
      'flex-1 text-sm text-cs-gray-500',
      'space-x-1'
    )}>
      <span className="text-cs-orange-500 hover:text-orange-300 cursor-pointer" onClick={onClick}>
        {user.data.firstName} {user.data.lastName}
      </span>
      <span>·</span>
      <span>{job.data.title}</span>
    </div>
  );

  return (
    <IconSection
      icon={UserCircleIcon}
      title={`${user.data.firstName} ${user.data.lastName}`}
    />
  );
}
