import React from 'react';
import PropTypes from 'prop-types';
import { OutlineButton } from '../../@communityshare/ui/Button';
import { SolidButton } from '../../@communityshare/ui/Button';
import StringField from '../../forms/_fields_v2/StringField';
import TextAreaField from '../../forms/_fields_v2/TextAreaField';
import FormTemplate from '../../dialogs_common/FormTemplate';
import FormField from '../../forms/_common/FormField';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';
import SelectField from '../../forms/_fields_v2/SelectField';
import ImageField from '../../forms/_fields_v2/ImageField';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onClose,
    network,
    site
  } = props;

  return (
    <FormTemplate
      textAlign="left"
      title="Remove site from network"
      subtitle={(
        <>
          <span className="block">
            Are you sure you want to remove the
            site <span className="font-semibold text-cs-gray-700">{site.data.name}</span> from
            the network <span className="font-semibold text-cs-gray-700">{network.data.name}</span>?
          </span>
        </>
      )}
      onClose={onClose}
      alert={alert}
      // body={(
      //   <div className="space-y-4">
      //     <FormField name="name" data={data} errors={showErrors && errors} onChange={onChange}>
      //       {(field) => (
      //         <StringField
      //           label="Name"
      //           placeholder="Name..."
      //           // description="Some text about a thing I like"
      //           value={field.value}
      //           error={field.error}
      //           onChange={field.onChange}
      //         />
      //       )}
      //     </FormField>
      //   </div>
      // )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onClose}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Remove site
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
