import { useState } from 'react';
import { useConnect } from '@lore/query-connect';
import { parse } from 'query-string';
import moment from 'moment-timezone';
import useStewardshipFilter from '../../evaluations-project-partners/useStewardshipFilter';
import getQueryUtils, { attributeKeys } from '../../evaluations-project-partners/queryUtils';

export default function useQuery(props) {
  const {
    search = {},
    pageSize = 10,
    network,
    schoolStart,
    schoolEnd
  } = props;

  const [timestamp] = useState(moment().toISOString());
  const stewardshipFilter = useStewardshipFilter();

  const queryUtils = getQueryUtils(search, attributeKeys, timestamp);

  return useConnect('evaluationProjectPartner.find', {
    where: {
      eager: {
        $where: {
          $and: [...queryUtils.where],
          completed: true,
          $or: stewardshipFilter,
          createdAt: {
            $lte: schoolEnd,
            $gte: schoolStart
          }
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: pageSize,
      order: search.order || 'createdAt desc'
    }
  }, {
    keepPreviousData : true
  });
}
