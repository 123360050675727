import React from 'react';
import { useConnect } from '../../../../hooks/@lore/query-connect';

export default function useQuery(props) {
  const {
    network,
    pagination = {},
    params = {}
  } = props;

  return useConnect('evaluationProjectEducator.find', {
    where: {
      eager: {
        $where: {
          'project.networkId': network?.id,
          ...params
        }
      }
    },
    ...pagination
  }, {
    keepPreviousData: true
  });
}
