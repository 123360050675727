import React from 'react';
import classNames from 'classnames';
import { useConfig } from '@lore/config';
import Header from './Header';
import Sites from './Sites';
import Stewards from './Stewards';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

export default function Network(props) {
  const { network, className, children } = props;

  const { tenant } = useConfig();
  const launch = useDialogLauncher();

  function onClick() {
    launch(DialogManager.NetworkUpdate.Config(network.id));
  }

  return (
    <div className={classNames(
      'flex flex-col bg-white',
      'shadow-cs-flat rounded-cs-10',
      '-m-0.5 border-2 border-cs-gray-100',
      'relative overflow-hidden',
      'hover:bg-cs-orange-50 cursor-pointer',
      className
    )} onClick={onClick}>
      <div className="flex py-4 px-4 space-x-4">
        <div>
          <img
            src={network.data.logo}
            // src={network.data.logo || tenant.networkLogo[network.data.subdomain]}
            className="w-12 h-12 object-contain"
            alt="Logo"
          />
        </div>
        <div className="flex flex-col space-y-2">
          <div className="pr-10">
            <Header network={network} />
          </div>
          <div className="flex flex-col sm:flex-row items-start justify-between">
            <div className="flex flex-1 flex-wrap gap-x-4 gap-y-2 mb-2 sm:mb-0">
              <Sites network={network} />
              <Stewards network={network} />
            </div>
            {/*<Projects network={network}/>*/}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}
