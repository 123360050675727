import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import validators from '../../utils/validators';
import Overlay from '../../forms/_common/Overlay';
import FormErrors from '../../forms/_common/FormErrors';
import RequestError from '../../forms/_common/RequestError';
import ErrorMessage from '../../forms/_common/ErrorMessage';
import SuccessMessage from '../../forms/_common/SuccessMessage';
import Form from './Form';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Confirmation from './Confirmation';
import { useQueryMutation } from '@lore/query-connect';

Dialog.propTypes = {
  site: PropTypes.object.isRequired
};

export default function Dialog(props) {
  const { site } = props;

  const [data, setData] = useState({
    name: site.data.name,
    phoneNumber: site.data.phoneNumber,
    website: site.data.website,
    addressLine1: site.data.addressLine1,
    city: site.data.city,
    state: site.data.state,
    zipCode: site.data.zipCode,
    latitude: site.data.latitude,
    longitude: site.data.longitude
  });
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('site.update', site);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      // props.onClose();
      props.onSubmit && props.onSubmit(request);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;
    setData(nextData);
  }

  return (
    <DialogTemplate className="w-screen max-w-screen-sm">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          name: [validators.isRequired],
          phoneNumber: [validators.isRequired],
          website: [validators.isRequired],
          addressLine1: [validators.isRequired],
          city: [validators.isRequired],
          state: [validators.isRequired],
          zipCode: [validators.isRequired],
          latitude: [validators.number.isRequired],
          longitude: [validators.number.isRequired]
        }}>
          {({errors, hasError}) => {
            if (success) {
              return (
                <Confirmation
                  data={data}
                  onClose={() => {
                    props.onClose();
                    setTimeout(function() {
                      props.onSubmit && props.onSubmit(request)
                    }, 300);
                  }}
                />
              );
            }

            return (
              <Form
                data={data}
                errors={errors}
                showErrors={showErrors}
                onChange={onChange}
                onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
                onClose={props.onClose}
                alert={error ? (
                  <RequestError request={request}/>
                ) : showErrors ? (
                  <ErrorMessage>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : (false && success) ? (
                  <SuccessMessage>
                    Site updated.
                  </SuccessMessage>
                ) : null}
                site={site}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
