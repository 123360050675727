import React from 'react';
import PropTypes from 'prop-types';
import classNames from '../../utils/classNames';
import { useConfig } from '@lore/config';
import Card from '../../components/Card';
import InactiveBadge from '../../components/User/InactiveBadge';

Network.propTypes = {
  network: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

export default function Network(props) {
  const { network, onClick, icon, selected = false } = props;

  const { tenant } = useConfig();

  return (
    <Card
      background={false}
      border={false}
      className={classNames(
        'flex p-6 space-x-4 cursor-pointer hover:bg-cs-orange-50',
        selected ? '-m-0.5 border-2 border-cs-orange-500 bg-white' : '-m-px border border-cs-gray-200 bg-white'
      )}
      onClick={() => onClick(network)}
    >
      <div>
        {icon || (
          <img
            src={network.data.logo || tenant.networkLogo[network.data.subdomain]}
            className="w-12 h-12 object-contain"
            alt="Logo"
          />
        )}
      </div>
      <div className="flex-1">
        <div className="flex justify-between">
          <h2 className="text-base font-semibold text-cs-gray-900">
            {network.data.name}
          </h2>
          {(network.id && !network.data.isActive) && (
            <InactiveBadge />
          )}
        </div>
        <div className="text-sm text-cs-gray-500">
          {network.data.city}, {network.data.state}
        </div>
      </div>
    </Card>
  );
};
