import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import validators from '../../utils/validators';
import Overlay from '../../forms/_common/Overlay';
import FormErrors from '../../forms/_common/FormErrors';
import RequestError from '../../forms/_common/RequestError';
import ErrorMessage from '../../forms/_common/ErrorMessage';
import SuccessMessage from '../../forms/_common/SuccessMessage';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import useProjectEmailPreview from '../../utils/project/useProjectEmailPreview';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';
import useNetwork from '../../hooks/useNetwork';
import Form from './Form';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';

InviteDialog.propTypes = {
  user: PropTypes.object.isRequired
};

const customMessageMap = {
  // 0: `My students and I are planning a project and a basic summary of the project is below for context. We did have a quick question. My students and I were curious about...`,
  1: `My students and I are in the planning stages of a project. We want to check the viability of this idea, know if this is reasonable to ask of a community partner, and understand if we're framing our guiding question in a relevant way. A basic summary of our project is below, but we specifically wanted feedback on...`,
  2: `My students and I have mapped out an idea of how we'd like to engage with you. We've included the outline of what we're thinking below, but we're flexible based on your interests, expertise, and availability. I'd love to answer any questions you have or talk more about this possibility with you. Thank you for considering us!`,
  3: `My students and I are planning a project and a basic summary of the project is below to give you a little context. We haven't found the exact expert to help us yet. From your profile, it seemed like you might be close! When you read our project outline, do any of your colleagues come to mind? We'd love to make a new connection. Thank you!`
};

const reasonMap = {
  1: 'Feedback',
  2: 'Participation',
  3: 'Referral'
};

export default function InviteDialog(props) {
  const { user, project } = props;

  const currentUser = useUser();
  const network = useNetwork();

  const creator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  const [data, setData] = useState({
    customMessage: customMessageMap[2],
    reason: 2
  });
  const [showErrors, setShowErrors] = useState(false);

  const customMessage = `I saw this project and thought you would be a great partner! Would you take a look and let ${creator.data.firstName} know if you have any questions or ideas on how you could work together? You can also send a quick note letting ${creator.data.firstName} know why it's not a good fit. Thanks for being an essential part of CommunityShare.`;

  // const emailPreview = useProjectEmailPreview(project, user, data.customMessage);
  const emailPreview = useProjectEmailPreview(project, user, customMessage, currentUser, network);

  const mutation = useQueryMutation('question.create');
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      props.onClose();
      props.onSubmit && props.onSubmit(request);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate({
      creatorId: currentUser.id,
      userId: user.id,
      projectId: project.id,
      request: reasonMap[data.reason],
      requestMessage: data.customMessage
    });
  }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;

    if (name === 'reason') {
      nextData.customMessage = customMessageMap[value];
    }

    setData(nextData);
  }

  onChange = onChange.bind(data);

  return (
    <DialogTemplate className="w-full max-w-screen-sm">
      <CloseDialogButton onClick={props.onClose} />
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          customMessage: [validators.isRequired],
          reason: [validators.number.isRequired]
        }}>
          {({errors, hasError}) => (
            <Form
              data={data}
              errors={errors}
              showErrors={showErrors}
              onChange={onChange}
              onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
              onClose={props.onClose}
              alert={error ? (
                <RequestError request={request}/>
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : (false && success) ? (
                <SuccessMessage>
                  Idea created.
                </SuccessMessage>
              ) : null}
              user={user}
              creator={creator}
              emailPreview={emailPreview}
            />
          )}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
