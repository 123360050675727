import React, { useState } from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import REQUIRED_FIELDS from '../../projects/REQUIRED_FIELDS';
import REQUIRED_FIELDS_PUBLIC from '../../projects/REQUIRED_FIELDS_PUBLIC';
import _ from 'lodash';
import moment from 'moment-timezone';
import ChartLoader from '../../states/_charts/ChartLoader';
import DonutChart from '../../states/_charts/DonutChart';
import colors from 'tailwindcss/colors';

export default function ProjectStatusYear(props) {
  const { state, network, schoolStart, schoolEnd, subtitle } = props;

  const [timestamp] = useState(moment().toISOString());

  const requests = [
    {
      label: 'Completed',
      projects: useConnect('project.find', {
        where: {
          eager: {
            $where: {
              $and: REQUIRED_FIELDS.$and,
              // networkId: network?.id,
              'site.state': {
                $ilike: state
              },
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              },
              completed: true
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      })
    },
    {
      label: 'Canceled',
      projects: useConnect('project.find', {
        where: {
          eager: {
            $where: {
              $and: REQUIRED_FIELDS.$and,
              // networkId: network?.id,
              'site.state': {
                $ilike: state
              },
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              },
              cancelled: true
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      })
    },
    {
      label: 'Active',
      projects: useConnect('project.find', {
        where: {
          eager: {
            $where: {
              $and: REQUIRED_FIELDS_PUBLIC.$and,
              // networkId: network?.id,
              'site.state': {
                $ilike: state
              },
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              }
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      })
    },
    {
      label: 'Expired',
      projects: useConnect('project.find', {
        where: {
          eager: {
            $where: {
              $and: REQUIRED_FIELDS.$and,
              // networkId: network?.id,
              'site.state': {
                $ilike: state
              },
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              },
              completed: false,
              cancelled: false,
              endDate: {
                $lt: timestamp
              }
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      })
    }
  ];

  const loading = _.reduce(requests, function(result, request) {
    return result || request.projects.state === PayloadStates.FETCHING;
  }, false);

  if (loading) {
    return (
      <ChartLoader
        title="Project Status"
        subtitle={subtitle || 'For school year'}
      />
    );
  }

  const series = requests.map(r => r.projects.meta.totalCount);
  const labels = requests.map(r => r.label);

  return (
    <DonutChart
      title="Project Status"
      subtitle={subtitle || 'For school year'}
      labels={labels}
      series={series}
      colors={[
        colors.emerald['400'],
        colors.red['400'],
        colors.blue['400'],
        colors.yellow['400']
      ]}
    />
  );
}
