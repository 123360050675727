import React from 'react';
import { useConnect } from '@lore/query-connect';

export default function usePartners(state, network, schoolStart, schoolEnd, extra = {}, eager = {}) {
  return useConnect('user.find', {
    where: {
      eager: {
        $where: {
          isEducator: false,
          // networkId: network?.id,
          'state': {
            $ilike: state
          },
          createdAt: {
            $lte: schoolEnd,
            $gte: schoolStart
          },
          ...extra
        },
        ...eager
      },
    },
    pagination: {
      pageSize: 1
    }
  });
}
