import React from 'react';
import _ from 'lodash';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import REQUIRED_FIELDS from '../../events/REQUIRED_FIELDS';
import LegendChart from '../../states/_charts/LegendChart';
import ChartLoader from '../../states/_charts/ChartLoader';

export default function EventActivityYear(props) {
  const { state, network, schoolStart, schoolEnd, subtitle, activities } = props;

  const allEvents = useConnect('event.find', {
    where: {
      eager: {
        $where: {
          $and: REQUIRED_FIELDS.$and,
          createdAt: {
            $lte: schoolEnd,
            $gte: schoolStart
          },
          // networkId: network?.id,
          'site.state': {
            $ilike: state
          },
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  const requests = _.sortBy(activities.data, at => at.data.name).map(function(activity) {
    return {
      label: activity.data.name,
      events: useConnect('event.find', {
        where: {
          eager: {
            $where: {
              $and: REQUIRED_FIELDS.$and,
              'activity.id': activity.id,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              },
              // networkId: network?.id,
              'site.state': {
                $ilike: state
              },
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      })
    };
  });

  const loading = _.reduce(requests, function(result, request) {
    return result || request.events.state === PayloadStates.FETCHING;
  }, false)

  if (loading || allEvents.state === PayloadStates.FETCHING) {
    return (
      <ChartLoader
        title="Events by Activity"
        subtitle={subtitle || 'For school year'}
      />
    );
  }

  const sortedRequests = _.sortBy(requests, r => -r.events.meta.totalCount);

  const series = sortedRequests.map(r => r.events.meta.totalCount);
  const labels = sortedRequests.map(r => r.label);

  return (
    <LegendChart
      title="Events by Activity"
      subtitle={subtitle || 'For school year'}
      labels={labels}
      series={series}
    />
  );
}
