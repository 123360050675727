import React from 'react';
import TextColumn from '../../_components/table/columns/TextColumn';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';

export default function Revoke(props) {
  const { user } = props;

  const launch = useDialogLauncher();

  function onClick() {
    launch(DialogManager.StaffRevoke.Config(user.id));
  }

  return (
    <TextColumn>
      <span className="text-accent underline hover:text-primary cursor-pointer" onClick={onClick}>
        Revoke
      </span>
    </TextColumn>
  );
}
