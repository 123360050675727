import React from 'react';
import { useConnect } from '@lore/query-connect';
import REQUIRED_FIELDS from '../../events/REQUIRED_FIELDS';
import { PayloadStates } from '@lore/utils';
import MetricLoader from '../MetricLoader';
import ComparisonMetric from '../ComparisonMetric';
import moment from 'moment-timezone';

export default function StudentsYear(props) {
  const { network, schoolStart, schoolEnd, titleOnly } = props;

  const previousSchoolStart = moment(schoolStart).subtract(1, 'year').toISOString();

  const previousEvents = useConnect('event.find', {
    where: {
      eager: {
        $where: {
          $and: REQUIRED_FIELDS.$and,
          networkId: network?.id,
          completed: true,
          students: {
            $gte: 0
          },
          createdAt: {
            $lte: schoolStart,
            $gte: titleOnly ? undefined : previousSchoolStart
          }
        }
      }
    },
    pagination: {
      pageSize: 1000
    }
  });

  const newEvents = useConnect('event.find', {
    where: {
      eager: {
        $where: {
          $and: REQUIRED_FIELDS.$and,
          networkId: network?.id,
          completed: true,
          students: {
            $gte: 0
          },
          createdAt: {
            $lte: schoolEnd,
            $gte: schoolStart
          }
        }
      }
    },
    pagination: {
      pageSize: 1000
    }
  });

  if (
    previousEvents.state === PayloadStates.FETCHING ||
    newEvents.state === PayloadStates.FETCHING
  ) {
    return (
      <MetricLoader
        name="Students Impacted"
      />
    );
  }

  const previousCount = _.reduce(previousEvents.data, function(result, event) {
    return result + event.data.students;
  }, 0);

  const newCount = _.reduce(newEvents.data, function(result, event) {
    return result + event.data.students;
  }, 0);

  return (
    <ComparisonMetric
      name="Students Impacted"
      value={newCount}
      previousValue={previousCount}
      titleOnly={titleOnly}
    />
  );
}
