import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Educator from './Educator';
import useQuery from './useQuery';
import useQueryMeta from '../../feed/useQueryMeta';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const { network, params, pagination } = useQueryMeta({ pageSize: PAGE_SIZE });

  const result = useQuery({
    network,
    pagination,
    params,
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Feed']}
      title="Educators"
      description="List of educators created over the specified time frame."
      pageSize={PAGE_SIZE}
      result={result}
      row={function(user, index) {
        return (
          <Educator
            key={user.id}
            user={user}
            last={result.data.length === index + 1}
          />
        );
      }}
    />
  );
}
