import React from 'react';
import Card from '../../../components/Card';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import Title from '../../events/Row/Title';
import Description from '../../events/Row/Description';
import Date from '../../../dialogs_new/event_manage/Header/Date';
import Creator from '../../../dialogs_new/event_manage/Header/Creator';
import Site from '../../events/Row/Site';
import Partners from '../../events/Row/Partners';
import Testimonials from './Testimonials';
import Images from './Images';

export default function Evaluation(props) {
  const { evaluation } = props;

  const event = useConnect('event.byId', {
    id: evaluation.data.eventId
  });

  const launch = useDialogLauncher();

  function onClick(event) {
    launch(DialogManager.EvaluationEventEducatorStory.Config(evaluation.id));
  }

  const partnerEvaluations = useConnect('evaluationEventPartner.find', {
    where: {
      eager: {
        $where: {
          eventId: event.id,
          completed: true
        }
      }
    }
  });

  if (event.state === PayloadStates.FETCHING) {
    return (
      <Card>
        <Loader />
      </Card>
    );
  }

  return (
    <Card className="overflow-hidden divide-y divide-gray-200">
      <div className="flex flex-col p-4 space-y-2">
        <div className="space-y-2">
          <div className="cursor-pointer hover:text-gray-500 space-y-1 pr-10" onClick={() => onClick(event)}>
            <Title event={event} />
            <Description event={event} />
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex flex-wrap gap-x-4 gap-y-2">
            <Date event={event} />
            <Creator event={event} />
            <Site event={event} />
            <Images evaluation={evaluation} />
            <Testimonials event={event} />
          </div>
          <Partners event={event} />
        </div>
      </div>
    </Card>
  );
}
