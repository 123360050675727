import { extractArrayFromQuery, mapAttributes } from '../../../utils/query';

import networks from './networks';
import contributions from './contributions';
import educator from './educator';
import partner from './partner';
import recommendEducators from './recommendEducators';
import recommendPlatforms from './recommendPlatforms';
import school from './school';
import district from './district';
import statuses from './statuses';
import title from './title';
import trainings from './trainings';
import statements from './statements';
import activities from './activities';

function projectTag() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          'tagNodes.tagId': {
            $in_subquery: values
          }
        }
      }
    }
  };
}

function tagTree() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          'tagTrees.parentId': {
            $in: values
          }
        }
      }
    }
  };
}

const attributes = {
  networks,
  contributions,
  educator,
  partner,
  recommendEducators,
  recommendPlatforms,
  school,
  district,
  statuses,
  title,
  trainings,

  offerStatements: statements('offerStatement'),
  learnStatements: statements('learnStatement'),
  understandingStatements: statements('understandingStatement'),
  connectionStatements: statements('connectionStatement'),
  volunteerStatements: statements('volunteerStatement'),

  activities,
  ages: projectTag(),
  commitments: projectTag(),
  // days: projectTag(),
  expertiseTypes: projectTag(),
  groups: projectTag(),
  guidances: projectTag(),
  interactionTypes: projectTag(),
  // participants: projectTag(),
  subjects: projectTag(),
  // times: projectTag(),
};

export const attributeKeys = _.keys(attributes);

export default mapAttributes(attributes);
