import React from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import useNetwork from '../../hooks/useNetwork';
import useRouter from '../../hooks/useRouter';
import REQUIRED_FIELDS from './REQUIRED_FIELDS';

export default function useStewardshipFilter(props) {
  const { location } = useRouter();
  const query = parse(location.search);

  const network = useNetwork();

  if (query.networks === '0') {
    return [
      { networkId: network.id },
    ];
  }

  if (network) {
    return [
      { networkId: network.id }
    ];
  }
};
