import React from 'react';
import useNetwork from '../../../hooks/useNetwork';
import useGetCount from '../../explore/useGetCount';
import allNetworks from '../../manage-networks/useQuery';

export default function getNavigationLinks() {
  return [
    { name: 'All', href: `/networks`, count: useGetCount(allNetworks) }
  ];
}
