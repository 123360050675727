import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import useNetwork from '../../hooks/useNetwork';
import REQUIRED_FIELDS_PUBLIC from '../../pages/offers/REQUIRED_FIELDS_PUBLIC';
import Offer from '../../pages/offers/Offer';
import classNames from 'classnames';
import ClaimChecker from './ClaimChecker';
import Loader from '../../components/Loader';

export default function Offers(props) {
  const { name = '', title = '', onClick, user } = props;

  const title_words = title.split(' ');
  const name_words = name.split(' ');

  const network = useNetwork();

  const offers = useConnect('offer.find', {
    where: {
      eager: {
        $where: {
          networkId: network.id,
          $and: _.remove([
            ...REQUIRED_FIELDS_PUBLIC.$and,
            title && {
              $or: title_words.map(function(word){
                return {
                  title: {
                    $ilike: `%${word}%`
                  }
                };
              })
              // .concat(title_words.map(function(word){
              //   return {
              //     'activity.name': {
              //       $ilike: `%${word}%`
              //     }
              //   };
              // }))
            },
            name && {
              $or: name_words.map(function(word){
                return {
                  'creator.firstName': {
                    $ilike: `%${word}%`
                  }
                };
              }).concat(name_words.map(function(word){
                return {
                  'creator.lastName': {
                    $ilike: `%${word}%`
                  }
                };
              }))
            }
          ])
        }
      }
    },
    pagination: {
      pageSize: 5,
      order: 'createdAt desc'
    }
  });

  const claims = useConnect('claim.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    },
    pagination: {
      pageSize: 1000,
      order: 'createdAt desc'
    }
  });

  if (offers.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (offers.data.length === 0) {
    return (
      <div className="text-base text-cs-gray-500">
        There are no active offers that match the search criteria.
      </div>
    );
  }

  return (
    <div className={classNames(
      offers._isPreviousData && 'opacity-50',
      'w-full grid grid-cols-1 gap-4',
    )}>
      {offers.data.map((offer) => {
        return (
          <Offer
            key={offer.id || offer.cid}
            offer={offer}
            onClick={() => onClick(offer)}
          >
            <ClaimChecker
              offer={offer}
              user={user}
              claims={claims}
            />
          </Offer>
        );
      })}
    </div>
  );
}
