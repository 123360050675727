import Name from './_columns/Name';
import Sent from './_columns/Sent';
import Accepted from './_columns/Accepted';
import Revoke from './_columns/Revoke';
import Impersonate from './_columns/Impersonate';

export default [
  { id: 'name',        name: 'Steward',         row: Name,        selected: true },
  // { id: 'impersonate', name: 'Impersonate',     row: Impersonate, selected: true },
  { id: 'date_sent',   name: 'Invitation Sent', row: Sent,        selected: true },
  { id: 'accepted',    name: 'Accepted',        row: Accepted,    selected: true },
  { id: 'actions',     name: 'Actions',         row: Revoke,      selected: true }
];
