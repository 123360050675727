import React from 'react';
import PageHeading from '../../@communityshare/ui/PageHeading';
import ParentLayout from '../settings/Layout';
import useNetwork from '../../hooks/useNetwork';
import { Helmet } from 'react-helmet-async';
import MobileNavigation from '../settings/MobileNavigation';
import useDialogLauncher from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../dialogs_new/DialogManager';
import SecondaryAction from '../_components/actions/SecondaryAction';
import Team from './Team';
import NoNetworkSelected from '../settings/NoNetworkSelected';

export default function Layout(props) {
  const network = useNetwork();
  const launch = useDialogLauncher();

  function onClick() {
    launch(DialogManager.StewardInvitationInvite.Config());
  }

  if (!network) {
    return (
      <NoNetworkSelected />
    );
  }

  return (
    <ParentLayout>
      <div className="p-4 space-y-8">
        <Helmet>
          <title>Settings: Team</title>
        </Helmet>
        <MobileNavigation />
        <PageHeading
          breadcrumbs={['Settings']}
          title="Team"
          description={(
            <>
              This is a list of stewards on your team. You can invite new stewards by email. When
              that person signs up using the email you listed they will automatically be granted
              steward access to your network.
            </>
          )}
        >
          <div>
            <SecondaryAction onClick={onClick} secret={true}>
              Invite Steward
            </SecondaryAction>
          </div>
        </PageHeading>
      </div>
      <Team network={network} />
    </ParentLayout>
  );
}
