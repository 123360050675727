import React, { useState } from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import REQUIRED_FIELDS from '../../offers/REQUIRED_FIELDS';
import REQUIRED_FIELDS_PUBLIC from '../../offers/REQUIRED_FIELDS_PUBLIC';
import _ from 'lodash';
import moment from 'moment-timezone';
import ChartLoader from '../../dashboard/_charts/ChartLoader';
import DonutChart from '../../dashboard/_charts/DonutChart';
import colors from 'tailwindcss/colors';

export default function OfferStatusYear(props) {
  const { network, schoolStart, schoolEnd, subtitle } = props;

  const [timestamp] = useState(moment().toISOString());

  const requests = [
    {
      label: 'Active',
      offers: useConnect('offer.find', {
        where: {
          eager: {
            $where: {
              $and: REQUIRED_FIELDS_PUBLIC.$and,
              networkId: network?.id,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              }
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      })
    },
    // {
    //   label: 'Completed',
    //   offers: useConnect('offer.find', {
    //     where: {
    //       eager: {
    //         $where: {
    //           $and: REQUIRED_FIELDS.$and,
    //           networkId: network?.id,
    //           createdAt: {
    //             $lte: schoolEnd,
    //             $gte: schoolStart
    //           },
    //           completed: true
    //         }
    //       }
    //     },
    //     pagination: {
    //       pageSize: 1
    //     }
    //   })
    // },
    {
      label: 'Canceled',
      offers: useConnect('offer.find', {
        where: {
          eager: {
            $where: {
              $and: REQUIRED_FIELDS.$and,
              networkId: network?.id,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              },
              cancelled: true
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      })
    },
    {
      label: 'Expired',
      offers: useConnect('offer.find', {
        where: {
          eager: {
            $where: {
              $and: REQUIRED_FIELDS.$and,
              networkId: network?.id,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              },
              completed: false,
              cancelled: false,
              endDate: {
                $lt: timestamp
              }
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      })
    }
  ];

  const loading = _.reduce(requests, function(result, request) {
    return result || request.offers.state === PayloadStates.FETCHING;
  }, false);

  if (loading) {
    return (
      <ChartLoader
        title="Offers Status"
        subtitle={subtitle || 'For school year'}
      />
    );
  }

  const series = requests.map(r => r.offers.meta.totalCount);
  const labels = requests.map(r => r.label);

  return (
    <DonutChart
      title="Offers Status"
      subtitle={subtitle || 'For school year'}
      labels={labels}
      series={series}
      colors={[
        colors.blue['400'],
        colors.red['400'],
        colors.yellow['400']
      ]}
    />
  );
}
