import moment from 'moment-timezone';
import useNetwork from '../../hooks/useNetwork';
import useFeedContext from '../../hooks/useFeedContext';
import { parse } from 'query-string';
import usePageRouter from '../../../hooks/@lore/dialogs-routable/_hooks/usePageRouter';

export default function useQueryMeta(props = {}) {
  const {
    pageSize = 1000
  } = props;

  const { location } = usePageRouter();

  const { weeks, timestamp } = useFeedContext();
  const search = parse(location.search);

  const network = useNetwork();

  const params = {
    createdAt: {
      $gte: moment(timestamp).subtract(weeks, 'weeks').toISOString()
    }
  };

  const pagination = {
    pagination: {
      page: search.page || '1',
      pageSize: pageSize,
      order: 'createdAt desc'
    }
  };

  return {
    weeks,
    timestamp,
    network,
    params,
    pagination
  };
}
