import React from 'react';
import LazyMetrics from '../../dashboard-year/LazyMetrics';
import OfferActivityYear from '../../dashboard-year/Offers/OfferActivityYear';
import OfferResponseYear from '../../dashboard-year/Offers/OfferResponseYear';
import OfferStatusYear from '../../dashboard-year/Offers/OfferStatusYear';
import TotalYear from '../../dashboard-year/Offers/TotalYear';
import ClaimsByMonth from '../../dashboard-year/Offers/ClaimsByMonth';
import ClaimCreatorYear from '../../dashboard-year/Offers/ClaimCreatorYear';
import ClaimDeclinerYear from '../../dashboard-year/Offers/ClaimDeclinerYear';
import ClaimDeclineReasonYear from '../../dashboard-year/Offers/ClaimDeclineReasonYear';

export default function Offers(props) {
  const { params } = props;

  return (
    <>
      <LazyMetrics
        // title="Offers"
        columns={1}
      >
        {/*<OfferActivityYear {...params} />*/}
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
          <OfferResponseYear {...params} />
          <OfferStatusYear {...params} />
        </div>
        <OfferActivityYear {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <TotalYear {...params} titleOnly={true} />
        </div>
      </LazyMetrics>
      <LazyMetrics
        title="Invitations - Offers"
        columns={1}
      >
        <ClaimsByMonth {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
          <ClaimCreatorYear {...params} />
          <ClaimDeclinerYear {...params} />
        </div>
        <ClaimDeclineReasonYear {...params} />
      </LazyMetrics>
    </>
  );
}
