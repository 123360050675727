import React from 'react';
import { PayloadStates } from '@lore/utils';
import MetricLoader from '../MetricLoader';
import DeltaMetric from '../DeltaMetric';
import useCompletedPartnerEventEvaluations from '../Students/_hooks/useCompletedPartnerEventEvaluations';
import usePartnerEventEvaluations from '../Students/_hooks/usePartnerEventEvaluations';
import moment from 'moment-timezone';
import ComparisonMetric from '../ComparisonMetric';

export default function CompositePartnerEventEvaluations(props) {
  const { network, schoolStart, schoolEnd, titleOnly, showTotals = true } = props;

  const previousSchoolStart = titleOnly ? undefined : moment(schoolStart).subtract(1, 'year').toISOString();

  const totalEvaluations = usePartnerEventEvaluations(network, undefined, schoolEnd);
  const previousEvaluations = usePartnerEventEvaluations(network, previousSchoolStart, schoolStart);
  const newEvaluations = usePartnerEventEvaluations(network, schoolStart, schoolEnd);

  const totalEvaluationsCompleted = useCompletedPartnerEventEvaluations(network, undefined, schoolEnd);
  const previousEvaluationsCompleted = useCompletedPartnerEventEvaluations(network, previousSchoolStart, schoolStart);
  const newEvaluationsCompleted = useCompletedPartnerEventEvaluations(network, schoolStart, schoolEnd);

  if (
    totalEvaluations.state === PayloadStates.FETCHING ||
    previousEvaluations.state === PayloadStates.FETCHING ||
    newEvaluations.state === PayloadStates.FETCHING ||
    totalEvaluationsCompleted.state === PayloadStates.FETCHING ||
    previousEvaluationsCompleted.state === PayloadStates.FETCHING ||
    newEvaluationsCompleted.state === PayloadStates.FETCHING
  ) {
    return (
      <>
        {showTotals && (
          <>
            <MetricLoader
              name="Total partner event evaluations sent"
            />
            <MetricLoader
              name="Total partner event evaluations completed"
            />
          </>
        )}
        {!titleOnly && (
          <>
            <MetricLoader
              name="Partner event evaluations sent"
            />
            <MetricLoader
              name="Partner event evaluations completed"
            />
          </>
        )}
      </>
    );
  }

  return (
    <>
      {showTotals && (
        <>
          <DeltaMetric
            name="Total partner event evaluations sent"
            value={totalEvaluations.meta.totalCount}
            delta={newEvaluations.meta.totalCount}
            titleOnly={titleOnly}
          />
          <DeltaMetric
            name="Total partner event evaluations completed"
            value={totalEvaluationsCompleted.meta.totalCount}
            delta={newEvaluationsCompleted.meta.totalCount}
            titleOnly={titleOnly}
          />
        </>
      )}
      {!titleOnly && (
        <>
          <ComparisonMetric
            name="Partner event evaluations sent"
            value={newEvaluations.meta.totalCount}
            previousValue={previousEvaluations.meta.totalCount}
            titleOnly={titleOnly}
          />
          <ComparisonMetric
            name="Partner event evaluations completed"
            value={newEvaluationsCompleted.meta.totalCount}
            previousValue={previousEvaluationsCompleted.meta.totalCount}
            titleOnly={titleOnly}
          />
        </>
      )}
    </>
  );
}
