import React from 'react';
import LazyMetrics from '../LazyMetrics';
import TotalYear from './TotalYear';
import OrganizationGrowthByMonth from './OrganizationGrowthByMonth';
import CompositeOrganizationGrowth from './CompositeOrganizationGrowth';

export default function Organizations(props) {
  const { params } = props;

  return (
    <>
      <LazyMetrics
        title="Organizations associated/affiliated with Partners"
      >
        <TotalYear {...params} />
      </LazyMetrics>
      <LazyMetrics
        title="Growth"
        columns={1}
      >
        <OrganizationGrowthByMonth {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <CompositeOrganizationGrowth {...params} showTotals={false} />
        </div>
      </LazyMetrics>
    </>
  );
}
