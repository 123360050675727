import React from 'react';
import { useConnect } from '@lore/query-connect';
import { GlobeAltIcon } from '@heroicons/react/24/outline';
import IconSection from '../../../@communityshare/ui/card/IconSection';

export default function Network(props) {
  const { offer } = props;

  const network = useConnect('network.byId', {
    id: offer.data.networkId
  }, {
    enabled: !!offer.data.networkId
  });

  if (!offer.data.networkId) {
    return (
      <IconSection
        icon={GlobeAltIcon}
        title="None"
        tooltip="Network"
      />
    );
  }

  return (
    <IconSection
      icon={GlobeAltIcon}
      title={network.data.name || '...'}
      tooltip="Network"
    />
  );
}
