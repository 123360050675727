import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogConfig';
import Dialog from '../select_network';

export default function Launcher(props) {
  const config = useDialogConfig();

  // const network = useConnect('network.byId', {
  //   id: config.params.networkId
  // }, {
  //   enabled: !!config.params.networkId
  // });

  return (
    <Dialog
      networkId={config.params.networkId}
      onClose={props.onClose}
    />
  );
};
