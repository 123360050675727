import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { useUser } from '@lore/auth';
import Avatar from '../../../../components/Avatar';
import Loader from '../../../../components/Loader';
import UserPhone from './UserPhone';
import UserEmail from './UserEmail';
import Messages from './Messages';
import Reminders from './Reminders';
import MessageDeliveryStatus from '../../../../components/MessageDeliveryStatus';
import useDialogLauncher from '../../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../DialogManager';
import Card from '../../../../components/Card';

export default function Claim(props) {
  const { claim, children, alignChildren = 'bottom' } = props;

  const currentUser = useUser();

  const recipient = useConnect('user.byId', {
    id: claim.data.userId
  });

  const creator = useConnect('user.byId', {
    id: claim.data.creatorId
  });

  const offer = useConnect('offer.byId', {
    id: claim.data.offerId
  });

  const launch = useDialogLauncher();

  function onSendReminder() {
    launch(DialogManager.ReminderClaimRespond.Config(claim.id));
  }

  function onDeclineInvitation() {
    launch(DialogManager.ClaimDecline.Config(claim.id));
  }

  if (
    recipient.state === PayloadStates.FETCHING ||
    offer.state === PayloadStates.FETCHING ||
    creator.state === PayloadStates.FETCHING
  ) {
    return (
      <Card>
        <Loader />
      </Card>
    );
  }

  const daysIgnored = moment().diff(claim.data.createdAt, 'days');
  const DAYS_THRESHOLD = 10;

  return (
    <Card
      border={false}
      className={classNames(
        'divide-y divide-gray-200 overflow-hidden',
        (
          claim.data.response === 'Pending' &&
          daysIgnored > DAYS_THRESHOLD
        ) ? '-m-[2px] border-2 border-cs-orange-600' : '-m-px border border-cs-gray-200'
      )}
    >
      {alignChildren === 'top' && children}
      <div className="flex justify-between p-4">
        <div className="flex flex-1 items-center space-x-4">
          <Avatar size={12} src={recipient.data.avatarUrl}/>
          <div className="flex flex-col">
            <div className="text-lg font-semibold">
              {recipient.data.firstName} {recipient.data.lastName}
            </div>
            <div className="text-sm text-gray-500 space-x-1">
              {creator.id === recipient.id ? (
                <span>Reached out</span>
              ) : (
                <span>Invited by {creator.data.firstName} {creator.data.lastName} {offer.data.creatorId !== creator.id ? '(Steward)' : ''}</span>
              )}
              <span>·</span>
              <span>{moment(claim.data.createdAt).fromNow()}</span>
            </div>
          </div>
        </div>
        {claim.data.response === 'Connect' && (
          <div className="flex flex-col text-right">
            <div className="text-lg font-semibold text-green-600">
              Connected
            </div>
            <div className="flex text-sm text-gray-500 space-x-6">
              <UserPhone user={recipient} />
              <UserEmail user={recipient} />
            </div>
          </div>
        )}
        {claim.data.response === 'Pending' && (
          <div className="flex flex-col text-right">
            {currentUser.data.isSuperuser ? (
              <div
                className="text-lg font-semibold text-orange-700 hover:text-orange-500 cursor-pointer"
                onClick={onSendReminder}
              >
                <MessageDeliveryStatus message={claim} />
              </div>
            ) : (
              <div className="text-lg font-semibold text-orange-600">
                <MessageDeliveryStatus message={claim} />
              </div>
            )}
            <div className="flex text-sm text-gray-500 space-x-6">
              <UserPhone user={recipient} />
              <UserEmail user={recipient} />
            </div>
          </div>
        )}
        {claim.data.response === 'Decline' && (
          <div className="flex flex-col text-right">
            <div className="text-lg font-semibold text-red-500">
              Declined
            </div>
            <div className="flex text-sm text-gray-500">
              {claim.data.responseMessage}
            </div>
          </div>
        )}
      </div>
      {claim.data.response === 'Connect' && (
        <div className="flex p-4">
          <Messages
            offer={offer}
            claim={claim}
          />
        </div>
      )}
      {false && claim.data.response === 'Pending' && (
        <div className="flex p-4">
          <Reminders
            claim={claim}
          />
        </div>
      )}
      {(
        claim.data.response === 'Pending' &&
        daysIgnored > DAYS_THRESHOLD
      ) && (
        <div className="flex flex-col px-4 py-3 bg-orange-100 space-y-4">
          <div className="space-y-0.5">
            <div className="text-base font-semibold text-orange-700">
              Invitation has been pending for {daysIgnored} days.
            </div>
            <div className="text-sm text-orange-700 space-x-1">
              <span>
                It is unlikely that the educator will respond. We recommend that you
              </span>
              <span className="underline hover:text-orange-500 cursor-pointer" onClick={onDeclineInvitation}>
                decline the invitation
              </span>.
            </div>
          </div>
          {/*<Reminders*/}
          {/*  claim={claim}*/}
          {/*/>*/}
        </div>
      )}
      {alignChildren === 'bottom' && children && (
        <div className="flex p-4">
          {children}
        </div>
      )}
      {/*<div className="bg-gray-50 p-4">*/}
      {/*  <Notes claim={claim} />*/}
      {/*</div>*/}
    </Card>
  );
};
