import React from 'react';
import ReactApexChart  from 'react-apexcharts';
import ChartHeader from './ChartHeader';
import DonutChartLegend from './DonutChartLegend';
import Card from '../../../components/Card';
import defaultColors from './defaultColors';

export default function DonutChart(props) {
  const {
    title,
    subtitle,
    labels,
    series,
    colors = defaultColors
  } = props;

  const extra = {};

  if (props.total >= 0) {
    extra.formatter = () => props.total;
  }

  const options = {
    // title: {
    //   text: title,
    //   // text: ['Educator Eval:'].concat(title),
    //   align: 'center'
    // },
    // subtitle: {
    //   text: subtitle || 'For school year',
    //   align: 'center',
    //   offsetY: 20 * title.length
    // },
    labels: labels,
    dataLabels: {
      enabled: false
    },
    chart: {
      type: 'donut',
      toolbar: {
        show: false
      }
    },
    colors: colors,
    legend: {
      show: false,
      position: 'bottom',
      horizontalAlign: 'center',
      floating: false,
      offsetY: 0
    },
    plotOptions: {
      pie: {
        customScale: 1,
        donut: {
          size: '70%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '13px',
              fontFamily: 'Figtree Variable',
              // label: 'name.label',
              // formatter: function() {
              //   console.log(arguments);
              //   return 'name.formatter';
              // },
            },
            value: {
              show: true,
              // label: 'total.label',
              fontSize: '31px',
              fontFamily: 'Figtree Variable',
              // formatter: function() {
              //   console.log(arguments);
              //   return 'total.formatter';
              // },
            },
            total: {
              show: true,
              color: '#8F92A1',
              // label: 'total.label',
              // fontSize: '32px',
              fontFamily: 'Figtree Variable',
              ...extra,
              // formatter: function() {
              //   console.log(arguments);
              //   return 'total.formatter';
              // },
            }
          }
        }
      }
    }
  };

  return (
    <Card className="pt-2 pb-4 px-4 relative space-y-6">
      <ChartHeader
        title={title}
        subtitle={subtitle}
      />
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height={224}
      />
      <DonutChartLegend
        labels={options.labels}
        colors={options.colors}
        series={series}
        total={props.total}
      />
    </Card>
  );
}
