import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import MetricLoader from '../MetricLoader';
import DeltaMetric from '../DeltaMetric';

export default function TotalYear(props) {
  const { network, schoolStart, schoolEnd, titleOnly, name = 'Total' } = props;

  const allSchools = useConnect('site.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfEducators',
            relation: 'users',
            $where: {
              isEducator: true,
              // 'networks.id': network?.id
              networkId: network?.id,
              createdAt: {
                $lte: schoolEnd
              }
            }
          }
        ],
        $where: {
          numberOfEducators: {
            $gt: 0
          },
          // createdAt: {
          //   $lte: schoolEnd
          // }
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  // const newSchools = useConnect('site.find', {
  //   where: {
  //     eager: {
  //       $aggregations: [
  //         {
  //           type: 'count',
  //           alias: 'numberOfEducators',
  //           relation: 'users',
  //           $where: {
  //             isEducator: true,
  //             // 'networks.id': network?.id
  //             networkId: network?.id,
  //             createdAt: {
  //               $lte: schoolEnd,
  //               $gte: schoolStart
  //             }
  //           }
  //         }
  //       ],
  //       $where: {
  //         numberOfEducators: {
  //           $gt: 0
  //         },
  //         // createdAt: {
  //         //   $lte: schoolEnd,
  //         //   $gte: schoolStart
  //         // }
  //       }
  //     }
  //   },
  //   pagination: {
  //     pageSize: 1
  //   }
  // });

  const previousSchools = useConnect('site.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfEducators',
            relation: 'users',
            $where: {
              isEducator: true,
              // 'networks.id': network?.id
              networkId: network?.id,
              createdAt: {
                $lte: schoolStart
              }
            }
          }
        ],
        $where: {
          numberOfEducators: {
            $gt: 0
          },
          // createdAt: {
          //   $lte: schoolEnd,
          //   $gte: schoolStart
          // }
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  if (
    allSchools.state === PayloadStates.FETCHING ||
    previousSchools.state === PayloadStates.FETCHING
  ) {
    return (
      <MetricLoader
        name={name}
      />
    );
  }

  return (
    <DeltaMetric
      name={name}
      value={allSchools.meta.totalCount}
      delta={allSchools.meta.totalCount - previousSchools.meta.totalCount}
      titleOnly={titleOnly}
    />
  );
}
