import React, { useState } from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import REQUIRED_FIELDS from '../../projects/REQUIRED_FIELDS';
import REQUIRED_FIELDS_PUBLIC from '../../projects/REQUIRED_FIELDS_PUBLIC';
import _ from 'lodash';
import moment from 'moment-timezone';
import ChartLoader from '../../dashboard/_charts/ChartLoader';
import DonutChart from '../../dashboard/_charts/DonutChart';
import colors from 'tailwindcss/colors';
import TagTypes from '../../../constants/TagTypes';

export default function ProjectSubjectYear(props) {
  const { subjects, network, schoolStart, schoolEnd, subtitle } = props;

  const projects = useConnect('project.find', {
    where: {
      eager: {
        $where: {
          $and: REQUIRED_FIELDS.$and,
          networkId: network?.id,
          createdAt: {
            $lte: schoolEnd,
            $gte: schoolStart
          }
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  const requests = subjects.data.map(function(subject) {
    return {
      label: subject.data.name,
      projects: useConnect('project.find', {
        where: {
          eager: {
            $where: {
              $and: REQUIRED_FIELDS.$and,
              networkId: network?.id,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              },
              'tags.name': subject.data.name
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      })
    };
  }).concat({
    label: 'No subject selected',
    projects: useConnect('project.find', {
      where: {
        eager: {
          $aggregations: [
            {
              type: 'count',
              alias: 'numberOfSubjects',
              relation: 'tags',
              $where: {
                type: TagTypes.Subject
              }
            }
          ],
          $where: {
            $and: REQUIRED_FIELDS.$and,
            networkId: network?.id,
            createdAt: {
              $lte: schoolEnd,
              $gte: schoolStart
            },
            numberOfSubjects: {
              $equals: 0
            }
          }
        }
      },
      pagination: {
        pageSize: 1
      }
    })
  });

  const loading = _.reduce(requests, function(result, request) {
    return result || request.projects.state === PayloadStates.FETCHING;
  }, false);

  if (loading || projects.state === PayloadStates.FETCHING) {
    return (
      <ChartLoader
        title="Projects by Subject"
        subtitle={subtitle || 'For school year'}
      />
    );
  }

  const series = requests.map(r => r.projects.meta.totalCount);
  // const series = requests.map(r => projects.meta.totalCount);
  const labels = requests.map(r => r.label);

  const defaultColors = [
    // colors.slate['400'],
    // colors.gray['400'],
    // colors.zinc['400'],
    // colors.neutral['400'],
    // colors.stone['400'],
    colors.red['400'],
    colors.orange['400'],
    // colors.amber['400'],
    colors.yellow['400'],
    colors.lime['400'],
    // colors.green['400'],
    colors.emerald['400'],
    colors.teal['400'],
    colors.cyan['400'],
    colors.sky['400'],
    colors.blue['400'],
    colors.indigo['400'],
    colors.violet['400'],
    // colors.purple['400'],
    colors.fuchsia['400'],
    colors.pink['400'],
    colors.rose['400']
  ];

  return (
    <DonutChart
      title="Projects by Subject"
      subtitle={subtitle || 'For school year'}
      labels={labels}
      series={series}
      colors={defaultColors}
      total={projects.meta.totalCount}
    />
  );
}
