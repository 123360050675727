import React from 'react';
import { PayloadStates } from '@lore/utils';
import ChartLoader from '../../states/_charts/ChartLoader';
import DonutChart from '../../states/_charts/DonutChart';
import colors from 'tailwindcss/colors';
import { partnerResponses, stewardResponses } from './QuestionDeclineReasonYear';
import useQuestions from './_hooks/useQuestions';
export default function QuestionDeclinerYear(props) {
  const { state, network, schoolStart, schoolEnd, subtitle } = props;

  const partnerQuestions = useQuestions(state, network, schoolStart, schoolEnd, {
    responseMessage: {
      $or: partnerResponses.map(function(response) {
        return {
          $ilike: `%${response}%`
        }
      })
    }
  });

  const stewardQuestions = useQuestions(state, network, schoolStart, schoolEnd, {
    responseMessage: {
      $or: stewardResponses.map(function(response) {
        return {
          $ilike: `%${response}%`
        }
      })
    }
  });

  const requests = [
    {
      label: 'Declined by Partner',
      questions: partnerQuestions
    },
    {
      label: 'Declined by Steward',
      questions: stewardQuestions
    }
  ]

  const loading = _.reduce(requests, function(result, request) {
    return (
      result ||
      request.questions.state === PayloadStates.FETCHING
    );
  }, false)

  if (loading) {
    return (
      <ChartLoader
        title="Breakdown of who declined the invitation"
        subtitle={subtitle || 'For school year'}
      />
    );
  }

  const series = requests.map(r => r.questions.meta.totalCount);
  const labels = requests.map(r => r.label);

  return (
    <DonutChart
      title="Breakdown of who declined the invitation"
      subtitle={subtitle || 'For school year'}
      labels={labels}
      series={series}
      colors={[
        colors.emerald['400'],
        colors.yellow['400']
      ]}
    />
  );
}
