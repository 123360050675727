import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Message from './Message';
import ProjectMessage from './ProjectMessage';
import EventMessage from './EventMessage';
import OfferMessage from './OfferMessage';
import useQuery from './useQuery';
import useQueryMeta from '../../feed/useQueryMeta';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const { network, params, pagination } = useQueryMeta({ pageSize: PAGE_SIZE });

  const result = useQuery({
    network,
    pagination,
    params,
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Feed']}
      title="Messages"
      description="List of messages sent over the specified time frame."
      pageSize={PAGE_SIZE}
      result={result}
      row={function(message, index) {
        if (message.data.projectId) {
          return (
            <ProjectMessage
              key={message.id}
              message={message}
              last={result.data.length === index + 1}
            />
          );
        }

        if (message.data.eventId) {
          return (
            <EventMessage
              key={message.id}
              message={message}
              last={result.data.length === index + 1}
            />
          );
        }

        if (message.data.offerId) {
          return (
            <OfferMessage
              key={message.id}
              message={message}
              last={result.data.length === index + 1}
            />
          );
        }

        return (
          <Message
            key={message.id}
            message={message}
            last={result.data.length === index + 1}
          />
        );
      }}
    />
  );
}
