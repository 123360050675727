import React from 'react';
import PageHeading from '../../@communityshare/ui/PageHeading';
import ParentLayout from '../settings/Layout';
import useNetwork from '../../hooks/useNetwork';
import { Helmet } from 'react-helmet-async';
import MobileNavigation from '../settings/MobileNavigation';
import useDialogLauncher from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../dialogs_new/DialogManager';
import SecondaryAction from '../_components/actions/SecondaryAction';
import Team from './Team';
import NotAuthorizedEmptyState from '../manage-networks/NotAuthorizedEmptyState';
import { useUser } from '@lore/auth';

export default function Layout(props) {
  const network = useNetwork();
  const launch = useDialogLauncher();
  const currentUser = useUser();

  function onClick() {
    launch(DialogManager.StaffInvite.Config());
  }

  if (!currentUser.data.isStaff) {
    return (
      <NotAuthorizedEmptyState />
    );
  }

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Manage: Staff</title>
      </Helmet>
      <MobileNavigation />
      <PageHeading
        breadcrumbs={['Manage']}
        title="Staff"
        description={(
          <>
            This is a list of accounts that have staff access. You can grant accounts staff access as
            well as remove access for current staff. Staff accounts have permission to view all platform
            data as well as steward any network. Be careful when giving accounts this access. It is
            intended to only be given to people working for CommunityShare.
          </>
        )}
      >
        <div>
          <SecondaryAction onClick={onClick} secret={true}>
            Add Staff Member
          </SecondaryAction>
        </div>
      </PageHeading>
      <Team />
    </div>
  );
}
