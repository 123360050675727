import React from 'react';
import LazyMetrics from '../LazyMetrics';
import ActivityByMonth from './ActivityByMonth';
import OfferActivityYear from './OfferActivityYear';
import OfferResponseYear from './OfferResponseYear';
import TotalYear from './TotalYear';
import OfferStatusYear from './OfferStatusYear';
import ClaimsByMonth from './ClaimsByMonth';
import ClaimCreatorYear from './ClaimCreatorYear';
import ClaimDeclinerYear from './ClaimDeclinerYear';
import ClaimDeclineReasonYear from './ClaimDeclineReasonYear';

export default function Offers(props) {
  const { params } = props;

  return (
    <>
      <LazyMetrics
        // title="Offers"
        columns={1}
      >
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
          <OfferResponseYear {...params} />
          <OfferStatusYear {...params} />
        </div>
        <ActivityByMonth {...params} />
        <OfferActivityYear {...params} />
        {/*<div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">*/}
        {/*  <TotalYear {...params} />*/}
        {/*</div>*/}
      </LazyMetrics>
      <LazyMetrics
        title="Invitations - Offers"
        columns={1}
      >
        <ClaimsByMonth {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
          <ClaimCreatorYear {...params} />
          <ClaimDeclinerYear {...params} />
        </div>
        <ClaimDeclineReasonYear {...params} />
      </LazyMetrics>
    </>
  );
}
