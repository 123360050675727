import React, { useState, useCallback, useEffect } from 'react';
import { parse, stringify } from 'query-string';
import SearchFilter from '../_fields_v2/SearchFilter';
import { getSearchUtils } from '../../utils/query-utils';
import _ from 'lodash';

export default function StewardFiltersDialog(props) {
  const { router } = props;

  const { location, history } = router;

  const [search, setSearch] = useState(parse(location.search));

  const debounceOnSubmit = useCallback(_.debounce(onSubmit, 250), []);

  useEffect(() => {
    setSearch(parse(location.search));
  }, [location.search]);

  const filterFields = {
    // title: 'title',
    name: 'name',
    // network: 'network'
  };

  function onSubmit(nextSearch) {
    history.push({
      pathname: location.pathname,
      search: stringify({
        ...nextSearch,
        page: 1
      })
    });
  }

  function onChange(nextSearch) {
    setSearch(nextSearch);
    debounceOnSubmit(nextSearch);
  }

  const searchUtils = getSearchUtils(search, onChange);

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        <SearchFilter
          label="Steward name"
          tooltip="Find stewards whose first or last name matches one of the words you enter."
          value={searchUtils(filterFields.name).value}
          onChange={searchUtils(filterFields.name).onChangeValue}
        />
        {/*<SearchFilter*/}
        {/*  label="Network"*/}
        {/*  tooltip="Find stewards affiliated with a network whose name contains the phrase you type."*/}
        {/*  value={searchUtils(filterFields.network).value}*/}
        {/*  onChange={searchUtils(filterFields.network).onChangeValue}*/}
        {/*/>*/}
        {/*<SearchFilter*/}
        {/*  label="Site"*/}
        {/*  tooltip="Find stewards affiliated with a site whose name contains the phrase you type."*/}
        {/*  value={searchUtils(filterFields.school).value}*/}
        {/*  onChange={searchUtils(filterFields.school).onChangeValue}*/}
        {/*/>*/}
      </div>
    </>
  );
};
