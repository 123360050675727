import React, { useState } from 'react';
import Table from './Table';
import { parse, stringify } from 'query-string';
import useRouter from '../../hooks/useRouter';
import initialColumns from './columns';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useQuery from './useQuery';

export default function Team(props) {
  const { location, history } = useRouter();

  const PAGE_SIZE = 50;

  const [columns, setColumns] = useState(initialColumns);

  const selectedColumns = columns.filter(column => column.selected);

  const users = useQuery({
    // search: parse(location.search),
    pageSize: PAGE_SIZE
  });

  if (users.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <Table
      pageSize={PAGE_SIZE}
      columns={selectedColumns}
      select={function (getState) {
        return users;
      }}
      row={function (user, index) {
        return (
          <tr key={index}>
            {selectedColumns.map(function (column) {
              if (!column.row) {
                return null;
              }

              return (
                <column.row
                  key={column.id}
                  user={user}
                />
              );
            })}
          </tr>
        );
      }}
    />
  );
}
