import { extractArrayFromQuery, mapAttributes } from '../../../utils/query';

import networks from './networks';
import activities from './activities';
import status from './status';
import costs from './costs';
import createdDates from './createdDates';
import instructions from './instructions';
import name from './name';
import organization from './organization';
import published from './published';
import searchOffer from './searchOffer';
import title from './title';
import locationCondition from './locationCondition';

function tag() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          'tagNodes.tagId': {
            $in_subquery: values
          }
        }
      }
    }
  };
}

function tagTree() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          'tagTrees.parentId': {
            $in: values
          }
        }
      }
    }
  };
}

const attributes = {
  networks,
  activities,
  ages: tag(),
  commitments: tag(),
  costs,
  createdDates,
  days: tag(),
  expertiseTypes: tag(),
  groups: tag(),
  guidances: tag(),
  instructions,
  interactionTypes: tag(),
  locationCondition,
  name,
  organization,
  participants: tag(),
  published,
  searchOffer,
  status,
  subjects: tag(),
  supplies: tag(),
  times: tag(),
  title
};

export const attributeKeys = _.keys(attributes);

export default mapAttributes(attributes);
