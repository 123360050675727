import React from 'react';
import PropTypes from 'prop-types';
import UserAvatar from '../../components/UserAvatar';
import Card from '../../components/Card';

User.propTypes = {
  user: PropTypes.object.isRequired
};

export default function User(props) {
  const { user } = props;

  return (
    <Card>
      <div className="flex items-center px-4 py-4 sm:px-6">
        <div className="min-w-0 flex-1 flex items-center">
          <div className="flex-shrink-0">
            <UserAvatar
              user={user}
              large={true}
            />
          </div>
          <div className="min-w-0 flex-1 px-4 ">
            <div>
              <p className="text-sm font-medium text-indigo-600x truncate">
                {user.data.firstName} {user.data.lastName}
              </p>
              <p className="mt-1 flex items-center text-sm text-gray-500">
                {/*<EnvelopeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />*/}
                <span className="truncate">
                  {user.data.email}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
