import React  from 'react';
import PropTypes from 'prop-types';
import { PhoneIcon, EnvelopeIcon, BuildingOfficeIcon } from '@heroicons/react/24/outline';
import IconSection from '../../../components/IconSection';
import SubSectionHeader from '../../../components_profiles/SubSectionHeader';
import MissingField from './UserMissingField';

function TextSection(props) {
  return (
    <div>
      <div className="text-gray-700 font-semibold">
        {props.title}
      </div>
      <div className="text-gray-500">
        {props.subtitle}
      </div>
    </div>
  );
}

Demographics.propTypes = {
  user: PropTypes.object.isRequired
};

export default function Demographics(props) {
  const { user } = props;

  const missing = '[missing]';

  return (
    <div className="space-y-3">
      <SubSectionHeader title="Demographics" />
      <div className="space-y-2">
        <IconSection
          title="Gender"
          subtitle={user.data.gender || missing}
        />
        <IconSection
          title="Ethnicity"
          subtitle={user.data.ethnicity || missing}
        />
        <IconSection
          title="Birth Year"
          subtitle={user.data.yearOfBirth || missing}
        />
        <IconSection
          title="ZIP Code"
          subtitle={user.data.zipCode || (
            <MissingField name="ZIP Code" />
          )}
        />
        <IconSection
          title="Timezone"
          subtitle={user.data.timezone || (
            <MissingField name="Timezone" />
          )}
        />
      </div>
    </div>
  );
}
