import React from 'react';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import IconSection from '../../../@communityshare/ui/card/IconSection';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import titleCase from '../../../utils/titleCase';

export default function District(props) {
  const { site } = props;

  const school = useConnect('school.byId', {
    id: site.data.schoolId
  });

  return (
    <IconSection
      icon={BuildingOfficeIcon}
      title={school.state === PayloadStates.FETCHING ? '...' :
        titleCase(school.data.agencyName) || '[district missing]'}
    />
  );
}
