import { useConnect } from '@lore/query-connect';

export const ResponseAnswers = {
  NotAtAll: 'Not at all',
  Somewhat: 'Somewhat',
  Significantly: 'Significantly',
  NotAGoal: 'Not a goal',
  NoAnswer: 'No answer'
};

export const ResponseAnswerMap = {
  [ResponseAnswers.NotAtAll]: 'Not at all',
  [ResponseAnswers.Somewhat]: 'Somewhat',
  [ResponseAnswers.Significantly]: 'Significantly',
  [ResponseAnswers.NotAGoal]: 'Not a goal',
  [ResponseAnswers.NoAnswer]: {
    $exists: false
  }
};

export const ResponseFields = {
  EngagedRating: 'engagedRating',
  SkillsRating: 'skillsRating',
  AwarenessRating: 'awarenessRating',
  UnderstandingRating: 'understandingRating',
  PracticedRating: 'practicedRating'
};

export default function useEventEvaluationEducatorResponse(props) {
  const {
    network,
    schoolStart,
    schoolEnd,
    responseField = '',
    responseAnswer
  } = props;

  return useConnect('evaluationEventEducator.find', {
    where: {
      eager: {
        $where: {
          [responseField]: responseAnswer,
          completed: true,
          networkId: network?.id,
          createdAt: {
            $lte: schoolEnd,
            $gte: schoolStart
          }
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });
}
