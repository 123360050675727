import React from 'react';
import { ShareIcon } from '@heroicons/react/24/outline';
import IconSection from '../../../@communityshare/ui/card/IconSection';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';

export default function Networks(props) {
  const { site } = props;

  const networkSites = useConnect('networkSite.find', {
    where: {
      eager: {
        $where: {
          siteId: site.id
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  return (
    <IconSection
      icon={ShareIcon}
      title={networkSites.state === PayloadStates.FETCHING ?
        '...' :
        networkSites.meta.totalCount
      }
    />
  );
}
