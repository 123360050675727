import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../forms/_common/FormField';
import { OutlineButton } from '../../@communityshare/ui/Button';
import { SolidButton } from '../../@communityshare/ui/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onClose,
    user
  } = props;

  return (
    <FormTemplate
      textAlign="left"
      title={`Revoke staff access for ${user.data.firstName} ${user.data.lastName}?`}
      subtitle={(
        <div className="space-y-2 text-left">
          <div>
          This will remove their ability to access the steward platform, unless they
          also happen to be a steward for a network.
          </div>
          <div>
            If they are, they will still be
            able to access the steward platform, but will only be able to act on data
            related to the network(s) they steward.
          </div>
        </div>
      )}
      onClose={onClose}
      alert={alert}
      // body={(
      //   <>
      //     <FormField name="text" data={data} errors={showErrors && errors} onChange={onChange}>
      //       {(field) => (
      //         <TextAreaField
      //           className="sm:col-span-6"
      //           label="Note"
      //           placeholder="Write something..."
      //           // description="Some text about a thing I like"
      //           rows={3}
      //           value={field.value}
      //           error={field.error}
      //           onChange={field.onChange}
      //         />
      //       )}
      //     </FormField>
      //   </>
      // )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onClose}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Revoke
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
