import React from 'react';
import { DialogManager } from '../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import User from './User';
import { useConnect } from '@lore/query-connect';
import _ from 'lodash';
import mapResponse from '../../utils/mapResponse';
import { getDistanceFromLatLonInMiles } from '../../components/User/utils';
import { PayloadStates } from '@lore/utils';

export default function ProjectUser(props) {
  const {
    project,
    user,
    children
  } = props;

  const launch = useDialogLauncher();

  const questions = useConnect('question.find', {
    where: {
      eager: {
        $where: {
          projectId: project.id
        }
      }
    },
    pagination: {
      pageSize: 1000
    }
  });

  const site = useConnect('site.byId', {
    id: project.data.siteId
  });

  const question = _.find(questions.data, function(question) {
    return question.data.userId === user.id;
  });

  const selected = !!question;

  function onClick() {
    launch(DialogManager.UserView.Config(user.id, {
      modelName: 'project',
      modelId: project.id
    }));
  }

  const distance = getDistanceFromLatLonInMiles(site.data, user.data);
  const distanceText = distance > 1 ?
    `${distance.toLocaleString()} miles` :
    `1 mile`;

  return (
    <User
      user={user}
      selected={selected}
      footer={question ? mapResponse(question.data.response) : undefined}
      distance={site.state === PayloadStates.FETCHING ? '...' : distanceText}
      children={children}
      onClick={onClick}
    />
  );
}
