import React from 'react';
import { PayloadStates } from '@lore/utils';
import ChartLoader from '../../dashboard/_charts/ChartLoader';
import DonutChart from '../../dashboard/_charts/DonutChart';
import colors from 'tailwindcss/colors';
import { educatorResponses, stewardResponses } from './ClaimDeclineReasonYear';
import useClaims from './_hooks/useClaims';
export default function ClaimDeclinerYear(props) {
  const { network, schoolStart, schoolEnd, subtitle } = props;

  const educatorClaims = useClaims(network, schoolStart, schoolEnd, {
    responseMessage: {
      $or: educatorResponses.map(function(response) {
        return {
          $ilike: `%${response}%`
        }
      })
    }
  });

  const stewardClaims = useClaims(network, schoolStart, schoolEnd, {
    responseMessage: {
      $or: stewardResponses.map(function(response) {
        return {
          $ilike: `%${response}%`
        }
      })
    }
  });

  const requests = [
    {
      label: 'Declined by Educator',
      claims: educatorClaims
    },
    {
      label: 'Declined by Steward',
      claims: stewardClaims
    }
  ]

  const loading = _.reduce(requests, function(result, request) {
    return (
      result ||
      request.claims.state === PayloadStates.FETCHING
    );
  }, false)

  if (loading) {
    return (
      <ChartLoader
        title="Breakdown of who declined the invitation"
        subtitle={subtitle || 'For school year'}
      />
    );
  }

  const series = requests.map(r => r.claims.meta.totalCount);
  const labels = requests.map(r => r.label);

  return (
    <DonutChart
      title="Breakdown of who declined the invitation"
      subtitle={subtitle || 'For school year'}
      labels={labels}
      series={series}
      colors={[
        colors.emerald['400'],
        colors.yellow['400']
      ]}
    />
  );
}
