import React from 'react';
import Header from './Header';
import Date from './Date';
import Creator from './Creator';
import classNames from 'classnames';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import Partners from '../Row/Partners';
import Notes from '../Row/Notes';
import StatusTags from '../Row/StatusTags';
import Bookmark from '../../_components/Bookmark';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import Slots from '../../../dialogs_new/event_manage/Header/Slots';
import Filled from '../../../dialogs_new/event_manage/Header/Filled';
import Site from '../Row/Site';
import { useUser } from '@lore/auth';
import Network from './Network';

export default function Event(props) {
  const { event, className, children } = props;

  const activity = useConnect('tag.byId', {
    id: event.data.activityId
  });

  const currentUser = useUser();

  const launch = useDialogLauncher();

  function onClick() {
    if (props.onClick) {
      props.onClick();
    } else {
      launch(DialogManager.EventManage.Config(event.id));
    }
  }

  return (
    <div className={classNames(
      'flex flex-col bg-white',
      'shadow-cs-flat rounded-cs-10',
      '-m-0.5 border-2 border-cs-gray-100',
      'relative overflow-hidden',
      // 'hover:bg-cs-orange-50 cursor-pointer',
      className
    )}>
      {/*<Link to={`/events/${event.id}`} className="absolute w-full h-full" />*/}
      <div className="absolute z-10 top-4 right-4 pt-0.5x pr-0.5x">
        <Bookmark
          type="event"
          resource={event}
        />
      </div>
      <div className="flex flex-col py-4 px-4 space-y-2 hover:bg-cs-orange-50 cursor-pointer" onClick={onClick}>
        <StatusTags event={event} />
        <div className="pr-10">
          <Header event={event} />
        </div>
        <div className="flex flex-col sm:flex-row items-start justify-between">
          <div className="flex flex-1 flex-wrap gap-x-4 gap-y-2 mb-2 sm:mb-0">
            <Date event={event} />
            <Slots event={event} />
            <Filled event={event} />
            <Creator event={event} />
            <Site event={event} />
            {currentUser.data.isStaff && (
              <Network event={event} />
            )}
          </div>
          <Partners event={event} />
        </div>
      </div>
      {/*{false && (*/}
      {/*  <div className={classNames(*/}
      {/*    'w-full py-4 px-4',*/}
      {/*    'bg-cs-gray-50',*/}
      {/*    '-mt-px border-t border-cs-gray-100',*/}
      {/*  )}>*/}
      {/*    <Participation event={event} />*/}
      {/*  </div>*/}
      {/*)}*/}
      {/*<Participation event={event} />*/}
      {children}
      <div className="bg-gray-50 p-4">
        <Notes event={event}/>
      </div>
    </div>
  );
}
