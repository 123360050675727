import React, { useState, useCallback, useEffect } from 'react';
import { parse, stringify } from 'query-string';
import SearchFilter from '../_fields_v2/SearchFilter';
import { getSearchUtils } from '../../utils/query-utils';
import _ from 'lodash';

export default function PartnerFiltersDialog(props) {
  const { router } = props;

  const { location, history } = router;

  const [search, setSearch] = useState(parse(location.search));

  const debounceOnSubmit = useCallback(_.debounce(onSubmit, 250), []);

  useEffect(() => {
    setSearch(parse(location.search));
  }, [location.search]);

  const filterFields = {
    name: 'name',
    state: 'state',
    district: 'district'
  };

  function onSubmit(nextSearch) {
    history.push({
      pathname: location.pathname,
      search: stringify({
        ...nextSearch,
        page: 1
      })
    });
  }

  function onChange(nextSearch) {
    setSearch(nextSearch);
    debounceOnSubmit(nextSearch);
  }

  const searchUtils = getSearchUtils(search, onChange);

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        <SearchFilter
          label="Name"
          tooltip="Find sites with a name that contains the phrase you type."
          value={searchUtils(filterFields.name).value}
          onChange={searchUtils(filterFields.name).onChangeValue}
        />
        <SearchFilter
          label="State"
          tooltip="Find sites with a state that contains the acronym you type."
          value={searchUtils(filterFields.state).value}
          onChange={searchUtils(filterFields.state).onChangeValue}
        />
        <SearchFilter
          label="District"
          tooltip="Find sites with a school district that contains the phrase you type."
          value={searchUtils(filterFields.district).value}
          onChange={searchUtils(filterFields.district).onChangeValue}
        />
      </div>
    </>
  );
};
