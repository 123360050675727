import React  from 'react';
import moment from 'moment-timezone';
import SupportingTextColumn from '../../_components/table/columns/SupportingTextColumn';

export default function Accepted(props) {
  const {
    networkSteward,
    stewardInvitation,
    user
  } = props;

  if (stewardInvitation?.data.accepted && !networkSteward) {
    return (
      <SupportingTextColumn
        title="Yes, but..."
        subtitle="Not linked to network"
      />
    );
  }

  if (stewardInvitation) {
    return (
      <SupportingTextColumn
        title={stewardInvitation.data.accepted ? 'Yes' : 'No'}
        subtitle={stewardInvitation.data.accepted ? moment(stewardInvitation.data.acceptedAt).fromNow() : ''}
      />
    );
  }

  return (
    <SupportingTextColumn
      title=""
      subtitle=""
    />
  );
}
