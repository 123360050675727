import React from 'react';
// import Educators from '../../../pages/people-educators/Partners';
import Educators from '../../../pages/educators/Educators';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import useMissingFields from '../../offer_manage/useMissingFields';
import EmptyState from '../../_common/EmptyState';
import Results from '../../../pages/_common_results/Results';
import User from '../../../pages/_common_user/OfferUser';
import Filters from '../../../forms/filters_educators';
import { NewspaperIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';

export default function Layout(props) {
  const { offer, router } = props;

  const { missingFields } = useMissingFields(offer);

  const creator = useConnect('user.byId', {
    id: offer.data.creatorId
  });

  if (
    missingFields.state === PayloadStates.FETCHING ||
    creator.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  if (missingFields.data.length > 0) {
    return (
      <EmptyState
        title="Offer is missing required fields."
        subtitle="They must be provided before you can invite educators."
      />
    );
  }

  return (
    <div className="space-y-8">
      <Filters router={router} />
      <Educators router={router} site={creator}>
        {function(result) {
          return (
            <Results result={result} resource="educators" emptyIcon={NewspaperIcon}>
              {function(user, index) {
                return (
                  <User
                    key={index}
                    offer={offer}
                    user={user}
                  />
                );
              }}
            </Results>
          );
        }}
      </Educators>
    </div>
  );
};
