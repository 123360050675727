import React from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import useNetwork from '../../../hooks/useNetwork';
import useRouter from '../../../hooks/useRouter';

export default function useStewardshipFilter(props) {
  const { location } = useRouter();
  const query = parse(location.search);

  const network = useNetwork();

  if (query.networks === '0') {
    return [
      { 'user.networkId': network.id },
    ];
  }

  if (network) {
    return [
      { 'user.networkId': network.id }
    ];
  }
};
