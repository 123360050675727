import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Event from '../../events/Event';
import useQuery from './useQuery';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Events']}
      title="Active Events"
      description={(
        <span>
          These are upcoming events that are currently being displayed to partners. CommunityShare
          recommends monitoring these events (looking to see that there are enough partners to
          fill the slots, promoting through social media and other outreach, connecting with the
          educator to get a sense of event progress). You can make notes visible only to your team.
        </span>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function(event) {
        return (
          <Event key={event.id} event={event} />
        );
      }}
    />
  );
}
