import React from 'react';
import Card from '../../../components/Card';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import Title from '../../projects/Row/Title';
import Description from '../../projects/Row/Description';
import DateRange from '../../../dialogs_new/project_manage/Header/DateRange';
import Creator from '../../../dialogs_new/project_manage/Header/Creator';
import Site from '../../projects/Row/Site';
import Partners from '../../projects/Row/Partners';
import Testimonials from './Testimonials';
import Images from './Images';

export default function Evaluation(props) {
  const { evaluation } = props;

  const project = useConnect('project.byId', {
    id: evaluation.data.projectId
  });

  const launch = useDialogLauncher();

  function onClick(project) {
    launch(DialogManager.EvaluationProjectEducatorStory.Config(evaluation.id));
  }

  const partnerEvaluations = useConnect('evaluationProjectPartner.find', {
    where: {
      eager: {
        $where: {
          projectId: project.id,
          completed: true
        }
      }
    }
  });

  if (project.state === PayloadStates.FETCHING) {
    return (
      <Card>
        <Loader />
      </Card>
    );
  }

  return (
    <Card className="overflow-hidden divide-y divide-gray-200">
      <div className="flex flex-col p-4 space-y-2">
        <div className="space-y-2">
          <div className="cursor-pointer hover:text-gray-500 space-y-1 pr-10" onClick={() => onClick(project)}>
            <Title project={project} />
            <Description project={project} />
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex flex-wrap gap-x-4 gap-y-2">
            <DateRange project={project} />
            <Creator project={project} />
            <Site project={project} />
            <Images evaluation={evaluation} />
            <Testimonials project={project} />
          </div>
          <Partners project={project} />
        </div>
      </div>
    </Card>
  );
}
