import React from 'react';
import MissingField from '../../../@communityshare/ui/alerts/MissingField';

export default function UserMissingField(props) {
  const { name } = props;

  return (
    <MissingField
      name={name}
      description={(
        <>
          Field must be provided before profile will be visible to others.
        </>
      )}
    />
  );
}
