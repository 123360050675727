import React from 'react';
import { useConnect } from '@lore/query-connect';

export default function useSchools(network, schoolStart, schoolEnd, extra = {}, eager = {}) {
  return useConnect('site.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfEducators',
            relation: 'users',
            $where: {
              isEducator: true,
              // 'networks.id': network?.id
              networkId: network?.id
            }
          },
          {
            type: 'count',
            alias: 'numberOfRoles',
            relation: 'roles',
            $where: {
              'user.isEducator': true,
              'user.networkId': network?.id,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              },
            }
          }
        ],
        $where: {
          numberOfEducators: {
            $gt: 0
          },
          numberOfRoles: {
            $gt: 0
          },
          // createdAt: {
          //   $lte: schoolEnd,
          //   $gte: schoolStart
          // },
          ...extra
        },
        ...eager
      },
    },
    pagination: {
      pageSize: 1
    }
  });
}
