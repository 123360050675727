import { extractArrayFromQuery } from '../../../utils/query';
import REQUIRED_FIELDS_PUBLIC from '../REQUIRED_FIELDS_PUBLIC';
import MISSING_FIELDS from '../MISSING_FIELDS';
import REQUIRED_FIELDS from '../REQUIRED_FIELDS';
import moment from 'moment-timezone';

export default {
  param: extractArrayFromQuery,
  query: function (values, timestamp) {
    if (values.length > 0) {
      return {
        $or: values.map(function(value) {
          const whereMap = {
            1: {
              $and: REQUIRED_FIELDS_PUBLIC.$and,
              completed: false,
              cancelled: false
            },
            2: {
              completed: true
            },
            3: {
              cancelled: true
            },
            4: {
              $and: {
                $or: MISSING_FIELDS,
              },
              completed: false,
              cancelled: false
            },
            5: {
              $and: REQUIRED_FIELDS.$and,
              $or: [
                {
                  specificDate: 'Yes',
                  date: {
                    $lte: moment(timestamp).toISOString()
                  }
                },
                {
                  specificDate: 'No',
                  endDate: {
                    $lte: moment(timestamp).toISOString()
                  }
                }
              ],
              completed: false,
              cancelled: false
            },
            6: {
              $and: REQUIRED_FIELDS.$and,
              $or: [
                {
                  specificDate: 'Yes',
                  date: {
                    $gte: moment(timestamp).toISOString()
                  }
                },
                {
                  specificDate: 'No',
                  endDate: {
                    $gte: moment(timestamp).toISOString()
                  }
                }
              ],
              completed: false,
              cancelled: false,
              published: false
            }
          };

          return whereMap[value] || {};
        })
      }
    }
  }
}
