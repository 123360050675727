import getQueryUtils from '../../pages/projects/queryUtils';

export default function useStewardshipFilter(search = {}) {
  const queryUtils = getQueryUtils(search, [
    'networks'
  ]);

  const values = queryUtils.params.networks;

  if (values.length > 0) {
    return {
      networkId: {
        $in: values
      }
    };
  }

  return {};
};
