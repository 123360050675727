import React from 'react';
import { GlobeAltIcon } from '@heroicons/react/24/outline';
import IconSection from '../../../@communityshare/ui/card/IconSection';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';

export default function Networks(props) {
  const { user } = props;

  const networks = useConnect('network.find', {
    where: {
      eager: {
        $where: {
          'networkStewards.userId': user.id
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'name desc'
    }
  });

  if (networks.state === PayloadStates.FETCHING) {
    return (
      <IconSection
        icon={GlobeAltIcon}
        title="..."
      />
    );
  }

  // return (
  //   <IconSection
  //     icon={GlobeAltIcon}
  //     title={(
  //       <div className="flex space-x-1">
  //         <div>
  //           {networks.meta.totalCount}
  //         </div>
  //         {networks.data.map(function(network) {
  //           return (
  //             <img
  //               key={network.id}
  //               className="w-8 h-8"
  //               src={network.data.logo}
  //               alt="Logo"
  //             />
  //           );
  //         })}
  //       </div>
  //     )}
  //   />
  // );

  return (
    <div className="flex items-center space-x-1">
      {networks.data.map(function(network) {
        return (
          <img
            key={network.id}
            className="w-8 h-8"
            src={network.data.logo}
            alt="Logo"
          />
        );
      })}
    </div>
  );
}
