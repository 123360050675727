import { useState } from 'react';
import { useConnect } from '@lore/query-connect';
import { parse } from 'query-string';
import moment from 'moment-timezone';
import getQueryUtils, { attributeKeys } from './queryUtils';

export default function useQuery(props) {
  const {
    search = {},
    pageSize = 10
  } = props;

  const [timestamp] = useState(moment().toISOString());

  const queryUtils = getQueryUtils(search, attributeKeys, timestamp);

  return useConnect('user.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: `numberOfNetworksStewarded`,
            relation: 'networkStewards'
          }
        ],
        $where: {
          $and: [...queryUtils.where],
          numberOfNetworksStewarded: {
            $gt: 0
          }
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: pageSize,
      order: search.order || 'createdAt desc'
    }
  }, {
    keepPreviousData : true
  });
}
