import React, { useState } from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import REQUIRED_FIELDS from '../../events/REQUIRED_FIELDS';
import REQUIRED_FIELDS_PUBLIC from '../../events/REQUIRED_FIELDS_PUBLIC';
import _ from 'lodash';
import moment from 'moment-timezone';
import ChartLoader from '../../dashboard/_charts/ChartLoader';
import DonutChart from '../../dashboard/_charts/DonutChart';
import colors from 'tailwindcss/colors';

export default function EventStatusYear(props) {
  const { network, schoolStart, schoolEnd, subtitle } = props;

  const [timestamp] = useState(moment().toISOString());

  const requests = [
    {
      label: 'Completed',
      events: useConnect('event.find', {
        where: {
          eager: {
            $where: {
              $and: REQUIRED_FIELDS.$and,
              networkId: network?.id,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              },
              completed: true
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      })
    },
    {
      label: 'Canceled',
      events: useConnect('event.find', {
        where: {
          eager: {
            $where: {
              $and: REQUIRED_FIELDS.$and,
              networkId: network?.id,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              },
              cancelled: true
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      })
    },
    {
      label: 'Active',
      events: useConnect('event.find', {
        where: {
          eager: {
            $where: {
              $and: REQUIRED_FIELDS_PUBLIC.$and,
              networkId: network?.id,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              }
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      })
    },
    {
      label: 'Expired',
      events: useConnect('event.find', {
        where: {
          eager: {
            $where: {
              $and: REQUIRED_FIELDS.$and,
              networkId: network?.id,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              },
              completed: false,
              cancelled: false,
              endDate: {
                $lt: timestamp
              }
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      })
    }
  ];

  const loading = _.reduce(requests, function(result, request) {
    return result || request.events.state === PayloadStates.FETCHING;
  }, false);

  if (loading) {
    return (
      <ChartLoader
        title="Event Status"
        subtitle={subtitle || 'For school year'}
      />
    );
  }

  const series = requests.map(r => r.events.meta.totalCount);
  const labels = requests.map(r => r.label);

  return (
    <DonutChart
      title="Event Status"
      subtitle={subtitle || 'For school year'}
      labels={labels}
      series={series}
      colors={[
        colors.emerald['400'],
        colors.red['400'],
        colors.blue['400'],
        colors.yellow['400']
      ]}
    />
  );
}
