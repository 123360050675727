import React from 'react';
import PropTypes from 'prop-types';
import { OutlineButton } from '../../@communityshare/ui/Button';
import { SolidButton } from '../../@communityshare/ui/Button';
import StringField from '../../forms/_fields_v2/StringField';
import FormTemplate from '../../dialogs_common/FormTemplate';
import FormField from '../../forms/_common/FormField';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';
import SelectField from '../../forms/_fields_v2/SelectField';
import Site from './Site';
import { parse, stringify } from 'query-string';
import { useConnect } from '@lore/query-connect';
import Filters from '../../forms/filters_sites';
import useQuery from '../../pages/manage-sites/useQuery';
import classNames from 'classnames';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onClose,
    network,
    router
  } = props;

  // const sites = useConnect('site.find', {
  //   where: {
  //     eager: {
  //       $where: {
  //         // 'networkStewards.userId': !currentUser.data.isStaff ? currentUser.id : undefined
  //       }
  //     }
  //   }
  // });

  const search = parse(router.location.search);

  const sites = useQuery({
    search
  })

  return (
    <FormTemplate
      title="Add site to network"
      subtitle={(
        <>
          Which site would you like to add to the
          network <span className="font-semibold text-cs-gray-700">{network.data.name}</span>?
        </>
      )}
      onClose={onClose}
      alert={alert}
      body={(
        <div className="space-y-6 pb-8">
          <Filters router={router} />
          <div className={classNames(
            'flex flex-col space-y-2',
            sites._isPreviousData && 'opacity-50'
          )}>
            {sites.data.map(function (site) {
              return (
                <Site
                  key={site.id || site.cid}
                  site={site}
                  onClick={onSubmit}
                />
              );
            })}
          </div>
        </div>
      )}
      // footer={(
      //   <FormTemplateButtonFooter>
      //     <OutlineButton onClick={onClose}>
      //       Cancel
      //     </OutlineButton>
      //     <SolidButton onClick={onSubmit}>
      //       Create site
      //     </SolidButton>
      //   </FormTemplateButtonFooter>
      // )}
    />
  );
}
