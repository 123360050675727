import React  from 'react';
import { useConnect } from '@lore/query-connect';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import Tooltip from '../../../components/Tooltip';

export default function Site(props) {
  const { event } = props;

  let site = useConnect('site.byId', {
    id: event.data.siteId
  }, {
    enabled: !!event.data.siteId
  });

  if (!event.data.siteId) {
    site = {
      data: {
        name: ''
      }
    };
  }

  return (
    <div className="flex items-center space-x-1">
      <Tooltip tooltip="Site" icon={false}>
        <BuildingOfficeIcon className="w-5 h-5 text-gray-500" />
      </Tooltip>
      <div className="text-sm text-gray-500">
        {site.data.name}
      </div>
    </div>
  );
}
