import React from 'react';
import useNetwork from '../../../hooks/useNetwork';
import useGetCount from '../../explore/useGetCount';

// Projects
import starredProjects from '../../projects-filtered/starred/useQuery';
import expiringProjects from '../../projects-filtered/expiring/useQuery';
import draftProjects from '../../projects-filtered/incomplete/useQuery.last4weeks';
import newProjects from '../../projects-filtered/new/useQuery';
import activeProjects from '../../projects-filtered/active/useQuery';
import partnersInvitedProjects from '../../projects-filtered/partners-invited/useQuery';
import partnersConnectedProjects from '../../projects-filtered/partners-connected/useQuery';
import wantsContactProjects from '../../projects-filtered/wants-contact/useQuery';
import resourcesNeededProjects from '../../projects-filtered/resources-needed/useQuery';
import fundingRequestedProjects from '../../projects-filtered/funding-requested/useQuery';
import expiredNotClosedProjects from '../../projects-filtered/expired-not-closed/useQuery';
import missingEducatorEvaluationProjects from '../../projects-filtered/missing-educator-evaluation/useQuery';
import completedProjects from '../../projects-filtered/completed/useQuery';
import schoolYearProjects from '../../projects-filtered/school-year/useQuery';

export default function getNavigationLinks() {
  return [
    { name: 'All', href: `/projects` },
    // { name: 'All (Table)', href: `/projects` },
    { name: 'Starred', href: `/projects/starred`, count: useGetCount(starredProjects) },
    { name: 'New', href: `/projects/new`, count: useGetCount(newProjects), highlight: true },
    { name: 'Incomplete', href: `/projects/draft`, count: useGetCount(draftProjects) },
    // { name: 'Wants Contact', href: `/projects/wants-contact`, count: useGetCount(wantsContactProjects), highlight: true },
    { name: 'No Partners Invited', href: `/projects/partners-invited`, count: useGetCount(partnersInvitedProjects), highlight: true },
    { name: 'No Partners Connected', href: `/projects/partners-connected`, count: useGetCount(partnersConnectedProjects) },
    { name: 'Active Projects', href: `/projects/active`, count: useGetCount(activeProjects) },
    { name: 'Needs Resources', href: `/projects/resources-needed`, count: useGetCount(resourcesNeededProjects) },
    { name: 'Requested Funding', href: `/projects/funding-requested`, count: useGetCount(fundingRequestedProjects), highlight: true },
    { name: 'Expiring', href: `/projects/expiring`, count: useGetCount(expiringProjects), highlight: true },
    { name: 'Expired but not Closed', href: `/projects/expired-not-closed`, count: useGetCount(expiredNotClosedProjects), highlight: true },
    { name: 'Missing Educator Evaluation', href: `/projects/missing-educator-evaluation`, count: useGetCount(missingEducatorEvaluationProjects) },
    { name: 'Completed', href: `/projects/completed`, count: useGetCount(completedProjects) },
    { name: 'All Projects this School Year', href: `/projects/school-year`, count: useGetCount(schoolYearProjects) }
  ];
}
