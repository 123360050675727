import React from 'react';
import usePartners from './usePartners';
import { MISSING_PARTNER_FIELDS } from '../../../../utils/user/MISSING_FIELDS';

export default function useIncompletePartners(state, network, schoolStart, schoolEnd, currentTimestamp) {
  return usePartners(state, network, schoolStart, schoolEnd, {
    inactive: false,
    $or: MISSING_PARTNER_FIELDS.$or,
    $and: {
      $or: [
        {
          sleepingStartingAt: {
            $gte: currentTimestamp
          }
        },
        {
          sleepingEndingAt: {
            $lte: currentTimestamp
          }
        }
      ]
    }
  });
}
