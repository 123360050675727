import React from 'react';
import LazyMetrics from '../LazyMetrics';
import SharedOffer from './SharedOffer';
import InvitedToProject from './InvitedToProject';
import InvitedToEvent from './InvitedToEvent';
import InvitedToProjectAndResponded from './InvitedToProjectAndResponded';
import InvitedToEventAndResponded from './InvitedToEventAndResponded';
import CreatedInviteToProject from './CreatedInviteToProject';
import CreatedInviteToEvent from './CreatedInviteToEvent';
import EngagementByMonth from './EngagementByMonth';
import ContributionYear from './ContributionYear';
import PartnerGrowthByMonth from './PartnerGrowthByMonth';
import CompositePartnerGrowth from './CompositePartnerGrowth';
import PartnerStatusYear from './PartnerStatusYear';

export default function Partners(props) {
  const { params } = props;

  return (
    <>
      <LazyMetrics
        // title="Growth"
        columns={1}
      >
        {/*<PartnerGrowthByMonth {...params} />*/}
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
          <PartnerStatusYear
            subtitle="For signups up to and including this school year"
            {...params}
            schoolStart={undefined}
          />
          <PartnerStatusYear
            subtitle="For new signups this school year"
            {...params}
          />
        </div>
        <PartnerGrowthByMonth {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <CompositePartnerGrowth {...params} showTotals={false} />
        </div>
      </LazyMetrics>
      <LazyMetrics
        title="Engagement"
        columns={1}
      >
        <EngagementByMonth {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <SharedOffer {...params} />
          <InvitedToProject {...params} />
          <InvitedToEvent {...params} />
          <InvitedToProjectAndResponded {...params} />
          <InvitedToEventAndResponded {...params} />
          <CreatedInviteToProject {...params} />
          <CreatedInviteToEvent {...params} />
          <ContributionYear {...params} showTotals={false} />
        </div>
      </LazyMetrics>
    </>
  );
}
