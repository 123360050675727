import React  from 'react';
import moment from 'moment-timezone';
import TextColumn from '../../_components/table/columns/TextColumn';

export default function Sent(props) {
  const {
    networkSteward,
    stewardInvitation,
    user
  } = props;

  const createdAt = stewardInvitation ?
    stewardInvitation.data.createdAt :
    networkSteward.data.createdAt;

  return (
    <TextColumn>
      {moment(createdAt).fromNow()}
    </TextColumn>
  );
}
