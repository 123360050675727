import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogConfig';
import Dialog from '../network_site_delete';
import { useQueryClient } from '@tanstack/react-query';

export default function Launcher(props) {
  const config = useDialogConfig();
  const queryClient = useQueryClient();

  const networkSite = useConnect('networkSite.byId', {
    id: config.params.networkSiteId
  }, {
    enabled: !!config.params.networkSiteId
  });

  const network = useConnect('network.byId', {
    id: networkSite.data.networkId
  }, {
    enabled: !!networkSite.data.networkId
  });

  const site = useConnect('site.byId', {
    id: networkSite.data.siteId
  }, {
    enabled: !!networkSite.data.siteId
  });

  if (
    networkSite.state === PayloadStates.FETCHING ||
    network.state === PayloadStates.FETCHING ||
    site.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  return (
    <Dialog
      networkSite={networkSite}
      network={network}
      site={site}
      onClose={props.onClose}
      onSubmit={function(request) {
        queryClient.invalidateQueries({ queryKey: ['networkSite'] });
        queryClient.invalidateQueries({ queryKey: ['site'] });
      }}
    />
  );
};
