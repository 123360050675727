import React from 'react';
import classNames from 'classnames';
import { useConfig } from '@lore/config';
import Header from './Header';
import Sites from './Sites';
import Networks from './Networks';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import UserAvatar from '../../../components/UserAvatar';
import Impersonate from '../../../components/Impersonate';

export default function Network(props) {
  const { user, className, children } = props;

  return (
    <div className={classNames(
      'flex flex-col bg-white',
      'shadow-cs-flat rounded-cs-10',
      '-m-0.5 border-2 border-cs-gray-100',
      'relative overflow-hidden',
      // 'hover:bg-cs-orange-50 cursor-pointer',
      className
    )}>
      <div className="flex py-4 px-4 space-x-4">
        <div>
          <UserAvatar user={user} large={true} />
        </div>
        <div className="flex flex-col space-y-2">
          <div className="pr-10">
            <Header user={user} />
          </div>
          <div className="flex flex-col sm:flex-row items-start justify-between">
            <div className="flex flex-1 flex-wrap gap-x-4 gap-y-2 mb-2 sm:mb-0">
              <Networks user={user} />
              {/*<Stewards network={network} />*/}
            </div>
            {/*<Projects network={network}/>*/}
          </div>
        </div>
        <div className="flex-1 w-full">
          <div className="flex justify-end">
            <Impersonate user={user} />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}
