import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogConfig';
import Dialog from '../network_update';
import { useQueryClient } from '@tanstack/react-query';

export default function Launcher(props) {
  const config = useDialogConfig();
  const queryClient = useQueryClient();

  const network = useConnect('network.byId', {
    id: config.params.networkId
  });

  if (network.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <Dialog
      network={network}
      onClose={props.onClose}
      onSubmit={function(request) {
        queryClient.invalidateQueries({ queryKey: ['network'] });
      }}
    />
  );
};
