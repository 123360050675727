import React from 'react';
import PropTypes from 'prop-types';
import SendgridMessageDeliveryStatus from './SendgridMessageDeliveryStatus';
import PostmarkMessageDeliveryStatus from './PostmarkMessageDeliveryStatus';

MessageDeliveryStatus.propTypes = {
  message: PropTypes.object.isRequired
};

export default function MessageDeliveryStatus(props) {
  const { message } = props;

  if (message.data.postmarkMessageId) {
    return (
      <PostmarkMessageDeliveryStatus message={message} />
    );
  }

  if (message.data.sendgridMessageId) {
    return (
      <SendgridMessageDeliveryStatus message={message} />
    );
  }

  return 'No delivery information';
};
