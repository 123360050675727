import React, { useState } from 'react';
import PageHeading from '../../@communityshare/ui/PageHeading';
import useNetwork from '../../hooks/useNetwork';
import useTags from '../../hooks/useTags';
import TagTypes from '../../constants/TagTypes';
import { Helmet } from 'react-helmet-async';
import getTimestampsForNetwork from '../states/getTimestampsForNetwork';
import PayloadStates from '../../constants/PayloadStates';
import moment from 'moment-timezone';
import MobileNavigation from '../states/MobileNavigation';
import Loader from '../../components/Loader';
import { useParams } from 'react-router-dom';

export default function ParentLayout(props) {
  const { children, title } = props;

  const routeParams = useParams();

  const [currentTimestamp] = useState(moment().toISOString());

  const network = useNetwork();
  const activities = useTags(TagTypes.Activity);
  const subjects = useTags(TagTypes.Subject);

  function empty() {
    return {
      state: PayloadStates.RESOLVED,
      data: [],
      meta: {
        totalCount: 0
      }
    }
  }

  const params = {
    network,
    activities,
    subjects,
    timestamps: getTimestampsForNetwork(network),
    empty,
    currentTimestamp,
    state: routeParams.state
  };

  if (
    activities.state === PayloadStates.FETCHING ||
    subjects.state === PayloadStates.FETCHING
  ) {
    return (
      <div key={`${routeParams.state}:${title}`} className="p-8 space-y-8">
        <Helmet>
          <title>States: All School Years: {title}</title>
        </Helmet>
        <MobileNavigation />
        <PageHeading
          breadcrumbs={['States', 'All School Years']}
          title={title}
        />
        <Loader />
      </div>
    );
  }

  return (
    <div key={`${routeParams.state}:${title}`} className="p-8 space-y-8">
      <Helmet>
        <title>States: All School Years: {title}</title>
      </Helmet>
      <MobileNavigation />
      <PageHeading
        breadcrumbs={['States', 'All School Years']}
        title={title}
      />
      <div className="space-y-12">
        {children(params)}
      </div>
    </div>
  );
}
