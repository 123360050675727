import React from 'react';
import _ from 'lodash';
import { useUser } from '@lore/auth';
import useNetwork from '../../../hooks/useNetwork';
import useGetCount from '../../explore/useGetCount';

// Educators
import relatedEducators from '../../educators-filtered/related/useQuery';
import relatedAffiliationEducators from '../../educators-filtered/related-affiliation/useQuery';
import starredEducators from '../../educators-filtered/starred/useQuery';
import incompleteEducators from '../../educators-filtered/incomplete/useQuery';
import newEducators from '../../educators-filtered/new/useQuery';
import sleepingEducators from '../../educators-filtered/sleeping/useQuery';
import inactiveEducators from '../../educators-filtered/inactive/useQuery';
import noneCreatedEducators from '../../educators-filtered/none-created/useQuery';
import multipleCompletedEducators from '../../educators-filtered/multiple-completed/useQuery';

export default function getNavigationLinks() {
  const currentUser = useUser();

  return _.remove([
    { name: 'All', href: `/educators` },
    { name: 'Connected to your network', href: `/educators/related-affiliation`, count: useGetCount(relatedAffiliationEducators) },
    { name: 'Creating in your network', href: `/educators/related`, count: useGetCount(relatedEducators) },
    currentUser.data.isSuperuser ?
      { name: '[Engagement]', href: `/educators/engagement` } :
      null,
    { name: 'Starred', href: `/educators/starred`, count: useGetCount(starredEducators) },
    { name: 'New', href: `/educators/new`, count: useGetCount(newEducators), highlight: true },
    { name: 'Sleeping', href: `/educators/sleeping`, count: useGetCount(sleepingEducators) },
    { name: 'Inactive', href: `/educators/inactive`, count: useGetCount(inactiveEducators) },
    { name: 'Incomplete Profile', href: `/educators/incomplete`, count: useGetCount(incompleteEducators) },
    { name: 'No Requests', href: `/educators/none-created`, count: useGetCount(noneCreatedEducators) },
    { name: '3+ Completed', href: `/educators/multiple-completed`, count: useGetCount(multipleCompletedEducators) }
  ]);
}
