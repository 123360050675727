import React from 'react';
import LazyMetrics from '../LazyMetrics';
import ActivityByMonth from './ActivityByMonth';
import EventActivityYear from './EventActivityYear';
import TotalYear from './TotalYear';
import EventStatusYear from './EventStatusYear';
import EventResponseYear from './EventResponseYear';
import StudentsYear from './StudentsYear';
import InvitationsByMonth from './InvitationsByMonth';
import InvitationCreatorYear from './InvitationCreatorYear';
import InvitationDeclinerYear from './InvitationDeclinerYear';
import InvitationDeclineReasonYear from './InvitationDeclineReasonYear';

export default function Events(props) {
  const { params } = props;

  return (
    <>
      <LazyMetrics
        title="Events"
        columns={1}
      >
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
          <EventStatusYear {...params} />
          <EventResponseYear {...params} />
        </div>
        <ActivityByMonth {...params} />
        <EventActivityYear {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {/*<TotalYear {...params} />*/}
          <StudentsYear {...params} />
        </div>
      </LazyMetrics>
      <LazyMetrics
        title="Invitations - Events"
        columns={1}
      >
        <InvitationsByMonth {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
          <InvitationCreatorYear {...params} />
          <InvitationDeclinerYear {...params} />
        </div>
        <InvitationDeclineReasonYear {...params} />
      </LazyMetrics>
    </>
  );
}
