import React from 'react';
import { useConnect } from '@lore/query-connect';

export default function useInvitations(state, network, schoolStart, schoolEnd, extra = {}) {
  return useConnect('invitation.find', {
    where: {
      eager: {
        $where: {
          // 'event.networkId': network?.id,
          'event.site.state': {
            $ilike: state
          },
          createdAt: {
            $lte: schoolEnd,
            $gte: schoolStart
          },
          ...extra
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });
}
