import React from 'react';
import LazyMetrics from '../../states-year/LazyMetrics';
import EngagementByMonth from '../../states-year/Partners/EngagementByMonth';
import SharedOffer from '../../states-year/Partners/SharedOffer';
import InvitedToProject from '../../states-year/Partners/InvitedToProject';
import InvitedToEvent from '../../states-year/Partners/InvitedToEvent';
import InvitedToProjectAndResponded from '../../states-year/Partners/InvitedToProjectAndResponded';
import InvitedToEventAndResponded from '../../states-year/Partners/InvitedToEventAndResponded';
import CreatedInviteToProject from '../../states-year/Partners/CreatedInviteToProject';
import CreatedInviteToEvent from '../../states-year/Partners/CreatedInviteToEvent';
import ContributionYear from '../../states-year/Partners/ContributionYear';
import PartnerGrowthByMonth from '../../states-year/Partners/PartnerGrowthByMonth';
import CompositePartnerGrowth from '../../states-year/Partners/CompositePartnerGrowth';
import PartnerStatusYear from '../../states-year/Partners/PartnerStatusYear';

export default function Partners(props) {
  const { params } = props;

  return (
    <>
      <LazyMetrics
        title="Partners"
        columns={1}
      >
        {/*<PartnerGrowthByMonth {...params} />*/}
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
          <PartnerStatusYear {...params} />
        </div>
        <PartnerGrowthByMonth {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <CompositePartnerGrowth {...params} titleOnly={true} />
        </div>
      </LazyMetrics>
      <LazyMetrics title="Partner Engagement" columns={1}>
        <EngagementByMonth {...params} />
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <SharedOffer {...params} titleOnly={true} />
          <InvitedToProject {...params} titleOnly={true} />
          <InvitedToEvent {...params} titleOnly={true} />
          <InvitedToProjectAndResponded {...params} titleOnly={true} />
          <InvitedToEventAndResponded {...params} titleOnly={true} />
          <CreatedInviteToProject {...params} titleOnly={true} />
          <CreatedInviteToEvent {...params} titleOnly={true} />
          <ContributionYear {...params} titleOnly={true} />
        </div>
      </LazyMetrics>
    </>
  );
}
