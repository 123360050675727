import _ from 'lodash';

export function removeEmpty(array) {
  return _.filter(array, function(item) {
    return !!item;
  });
}

export function extractArrayFromQuery(query, param) {
  if (query[param]) {
    return query[param].split(',').map(id => Number(id));
  }

  return [];
}

export function extractFilterFromQuery(query, param) {
  if (query[param] && query[param] === 'all') {
    return 'all';
  }

  return 'any';
}

export function addOrRemoveNumberFromArray(array, value) {

  const isSelected = !!_.find(array, function(id) {
    return id === value;
  });

  if (isSelected) {
    array = array.filter(function(id) {
      return id !== value;
    });
  } else {
    array.push(value);
  }

  return array;
}

export function insertOrRemoveNumberFromArray(query, param, value) {
  const nextQuery = _.extend({}, query);
  const extractedArray = extractArrayFromQuery(nextQuery, param);
  const updatedArray = addOrRemoveNumberFromArray(extractedArray, value);
  if (updatedArray.length > 0) {
    nextQuery[param] = updatedArray.join(',');
  } else {
    delete nextQuery[param];
  }
  return nextQuery;
}

export function setNumbersFromArray(query, param, values) {
  // return _.extend({}, query, {
  //   [param]: values.join(',')
  // });
  const nextQuery = _.extend({}, query);

  if (values.length > 0) {
    nextQuery[param] = values.join(',');
  } else {
    delete nextQuery[param];
  }

  return nextQuery;
}

export function changeFilter(query, param, value) {
  return _.extend({}, query, {
    [`${param}Filter`]: value
  });
}

export default function getSearchParamsFromQuery(query) {
  return {
    networks: query.networks || '',
    search: query.search || '',
    searchPartner: query.searchPartner || '',
    searchEducator: query.searchEducator || '',
    searchOffer: query.searchOffer || '',

    name: query.name || '',

    title: query.title || '',
    educator: query.educator || '',
    partner: query.partner || '',

    statuses: extractArrayFromQuery(query, 'statuses'),
    testimonials: extractArrayFromQuery(query, 'testimonials'),
    recommendPlatforms: extractArrayFromQuery(query, 'recommendPlatforms'),

    supportedRatings: extractArrayFromQuery(query, 'supportedRatings'),
    engagedRatings: extractArrayFromQuery(query, 'engagedRatings'),
    skillsRatings: extractArrayFromQuery(query, 'skillsRatings'),
    awarenessRatings: extractArrayFromQuery(query, 'awarenessRatings'),
    understandingRatings: extractArrayFromQuery(query, 'understandingRatings'),
    practicedRatings: extractArrayFromQuery(query, 'practicedRatings'),

    offerStatements: extractArrayFromQuery(query, 'offerStatements'),
    learnStatements: extractArrayFromQuery(query, 'learnStatements'),
    understandingStatements: extractArrayFromQuery(query, 'understandingStatements'),
    connectionStatements: extractArrayFromQuery(query, 'connectionStatements'),
    volunteerStatements: extractArrayFromQuery(query, 'volunteerStatements'),

    organization: query.organization || '',
    state: query.state || '',
    school: query.school || '',
    district: query.district || '',

    // advanced fields
    filter: query.filter === 'or' ? 'or' : 'and',
    profileConditions: query.profileConditions ? JSON.parse(query.profileConditions) : [],

    status: extractArrayFromQuery(query, 'status'),
    createdDates: extractArrayFromQuery(query, 'createdDates'),

    hours: extractArrayFromQuery(query, 'hours'),
    contributions: extractArrayFromQuery(query, 'contributions'),
    projects: extractArrayFromQuery(query, 'projects'),
    events: extractArrayFromQuery(query, 'events'),
    genders: extractArrayFromQuery(query, 'genders'),
    ethnicities: extractArrayFromQuery(query, 'ethnicities'),

    activities: extractArrayFromQuery(query, 'activities'),
    activityFilter: extractFilterFromQuery(query, 'activityFilter'),

    activityTypes: extractArrayFromQuery(query, 'activityTypes'),
    activityTypeFilter: extractFilterFromQuery(query, 'activityTypeFilter'),

    ages: extractArrayFromQuery(query, 'ages'),
    ageFilter: extractFilterFromQuery(query, 'ageFilter'),

    commitments: extractArrayFromQuery(query, 'commitments'),
    commitmentFilter: extractFilterFromQuery(query, 'commitmentFilter'),

    costs: extractArrayFromQuery(query, 'costs'),
    costFilter: extractFilterFromQuery(query, 'costFilter'),

    instructions: extractArrayFromQuery(query, 'instructions'),
    instructionFilter: extractFilterFromQuery(query, 'instructionFilter'),

    days: extractArrayFromQuery(query, 'days'),
    dayFilter: extractFilterFromQuery(query, 'dayFilter'),

    experiences: extractArrayFromQuery(query, 'experiences'),
    experienceFilter: extractFilterFromQuery(query, 'experienceFilter'),

    expertises: extractArrayFromQuery(query, 'expertises'),
    expertiseFilter: extractFilterFromQuery(query, 'expertiseFilter'),

    expertiseTypes: extractArrayFromQuery(query, 'expertiseTypes'),
    expertiseTypeFilter: extractFilterFromQuery(query, 'expertiseTypeFilter'),

    grades: extractArrayFromQuery(query, 'grades'),
    gradeFilter: extractFilterFromQuery(query, 'gradeFilter'),

    groups: extractArrayFromQuery(query, 'groups'),
    groupFilter: extractFilterFromQuery(query, 'groupFilter'),

    guidances: extractArrayFromQuery(query, 'guidances'),
    guidanceFilter: extractFilterFromQuery(query, 'guidanceFilter'),

    interactionTypes: extractArrayFromQuery(query, 'interactionTypes'),
    interactionTypeFilter: extractFilterFromQuery(query, 'interactionTypeFilter'),

    participants: extractArrayFromQuery(query, 'participants'),
    participantFilter: extractFilterFromQuery(query, 'participantFilter'),

    practices: extractArrayFromQuery(query, 'practices'),
    practiceFilter: extractFilterFromQuery(query, 'practiceFilter'),

    subjects: extractArrayFromQuery(query, 'subjects'),
    subjectFilter: extractFilterFromQuery(query, 'subjectFilter'),

    supplies: extractArrayFromQuery(query, 'supplies'),
    supplyFilter: extractFilterFromQuery(query, 'supplyFilter'),

    times: extractArrayFromQuery(query, 'times'),
    timeFilter: extractFilterFromQuery(query, 'timeFilter'),

    trainings: extractArrayFromQuery(query, 'trainings'),
    trainingFilter: extractFilterFromQuery(query, 'trainingFilter'),

    profileFilter: extractFilterFromQuery(query, 'profileFilter'),

    locationCondition: query.locationCondition ? JSON.parse(query.locationCondition) : {
      radius: 5
    }
  };
}
