import { extractTextFromQuery } from '../../../utils/query';

export default {
  param: extractTextFromQuery,
  query: function (text) {
    const words = text.trim().split(' ');

    if (text) {
      return {
        'state': {
          $ilike: `%${text}%`
        }
        // $or: words.map(function(word){
        //   return {
        //     'school.name': {
        //       $ilike: `%${word}%`
        //     }
        //   };
        // })
      }
    }
  }
}
