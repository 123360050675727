import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useUser } from '@lore/auth';
import avatar from '../../assets/images/avatar.svg';
import { Link } from 'react-router-dom';
import { useConnect } from '@lore/query-connect';
import { useConfig } from '@lore/config';
import { useQueryClient } from '@tanstack/react-query';
import useRouter from '../hooks/useRouter';
import useNetwork from '../hooks/useNetwork';
import classNames from 'classnames';
import { stringify } from 'query-string';

function MenuItem(props) {
  const { to, link = false, children } = props;

  return (
    <Menu.Item>
      {({ active }) => link ? (
        <a
          href={to}
          className={classNames(
            active ? 'bg-cs-gray-100 text-cs-gray-900' : 'text-cs-gray-900',
            'block px-4 py-2 text-base'
          )}
        >
          {children}
        </a>
      ) : (
        <Link
          to={to}
          className={classNames(
            active ? 'bg-cs-gray-100 text-cs-gray-900' : 'text-cs-gray-900',
            'block px-4 py-2 text-base'
          )}
        >
          {children}
        </Link>
      )}
    </Menu.Item>
  );
}

export default function AccountThin(props) {
  const currentUser = useUser();
  const config = useConfig();
  const queryClient = useQueryClient();

  const root = config.custom.platformUrl();

  const { history, location } = useRouter();

  const network = useNetwork();
  const networks = useConnect('network.find', {
    where: {
      eager: {
        $where: {
          'networkStewards.userId': currentUser.id
        }
      }
    },
    pagination: {
      order: 'name asc'
    }
  });

  function onSwitchNetwork(nextNetwork) {
    // const extension = location.pathname.split(`/c/${network.data.subdomain}/`)[1];
    // const pathname = `/${extension}`;
    // history.push(pathname);

    //
    if (network?.id === nextNetwork.id) {
      history.push({
        pathname: location.pathname
      });
    } else {
      history.push({
        pathname: location.pathname,
        search: stringify({
          networks: nextNetwork.id
        })
      });
    }

    queryClient.invalidateQueries();
  }

  const _networks = {
    ...networks,
    data: [{
      id: null,
      data: {
        name: 'None'
      }
    }].concat(networks.data)
  };

  return (
    <Menu as="div" className="relativex inline-blockx text-leftx w-fullx">
      <div>
        <Menu.Button className={classNames(
          'bg-white rounded-cs-10 p-2x',
          'h-14 w-14',
          'shadow-cs-flat -m-px border border-cs-gray-200',
          'hover:bg-cs-gray-100',
          'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-cs-orange-500'
        )}>
          <div className="flex items-center justify-center">
            <img
              className="w-12 h-12 rounded-fullx rounded-cs-10"
              // src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80"
              src={currentUser.data.avatarUrl || avatar}
              // src={avatar}
              alt=""
            />
            {/*<span className="flex-1 flex flex-col min-w-0">*/}
            {/*  <span className="text-cs-gray-900 text-lg font-semibold">*/}
            {/*    {`${currentUser.data.firstName} ${currentUser.data.lastName}`.trim() || 'Profile'}*/}
            {/*  </span>*/}
            {/*  <span className="text-cs-gray-500 text-sm truncate">*/}
            {/*    Steward*/}
            {/*  </span>*/}
            {/*</span>*/}
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          // className="z-10 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
          className={classNames(
            // 'z-10 origin-top absolute right-0 left-0',
            'z-10 origin-bottom-left absolute right-0x left-20 bottom-8',
            'mt-1 rounded-md shadow-lg bg-white',
            'divide-y divide-gray-200',
            'ring-1 ring-black ring-opacity-5 focus:outline-none',
          )}
        >
          {false && _networks.data.length > 1 && (
            <div className="py-1">
              <div
                className={classNames(
                  'text-cs-gray-500',
                  'block px-4 py-2 text-base cursor-pointer'
                )}
              >
                Switch network to
              </div>
              {_networks.data.map(function(nextNetwork, index) {
                // const isActive = network?.id === nextNetwork.id;
                const isActive = network?.id ?
                  network.id === nextNetwork.id :
                  !network && !nextNetwork.id;

                return (
                  <Menu.Item key={index}>
                    {({active}) => (
                      <div
                        onClick={!isActive ? () => onSwitchNetwork(nextNetwork) : undefined}
                        // onClick={() => onSwitchNetwork(nextNetwork)}
                        className={classNames(
                          isActive ? 'bg-cs-orange-50' :
                          active ? 'bg-cs-gray-100 text-cs-gray-900' : 'text-cs-gray-900',
                          'block px-4 py-2 text-base cursor-pointer'
                        )}
                      >
                        <span className={classNames(
                          isActive ? 'text-cs-orange-500 font-semibold' :
                          '',
                        )}>
                          {nextNetwork.data.name}
                        </span>
                      </div>
                    )}
                  </Menu.Item>
                );
              })}
            </div>
          )}
          <div className="py-1">
            <MenuItem to={`${root}`}>
              Switch to platform
            </MenuItem>
          </div>
          <div className="py-1">
            <MenuItem to={`${root}/profile/view`}>
              View profile
            </MenuItem>
            <MenuItem to={`${root}/profile/edit`}>
              Edit profile
            </MenuItem>
          </div>
          <div className="py-1">
            <MenuItem to="/logout">
              Logout
            </MenuItem>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
