import React from 'react';
import PropTypes from 'prop-types';
import CloseDialogButton from './CloseDialogButton';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

FormTemplate.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  alert: PropTypes.node,
  body: PropTypes.node,
  footer: PropTypes.node,
  onClose: PropTypes.func,
  onPrevious: PropTypes.func,
  showClose: PropTypes.bool
};

FormTemplate.defaultProps = {
  showClose: false
};

export default function FormTemplate(props) {
  const {
    icon,
    title,
    subtitle,
    alert,
    body,
    footer,
    onClose,
    onPrevious,
    className,
    showClose,
    textAlign = 'center'
  } = props;

  const textAlignMap = {
    center: 'text-center',
    left: 'text-left',
    right: 'text-right'
  };

  return (
    <div className={className}>
      <div className={classNames(
        'pt-8 px-8',
        textAlignMap[textAlign]
      )}>
        {(showClose || onPrevious) && (
          <div className="mb-8 md:mb-4" />
        )}
        {icon}
        <h5 className="text-3xl text-cs-gray-900 font-semibold">
          {title}
        </h5>
        {subtitle ? (
          <h6 className="text-base mt-2 text-cs-gray-500">
            {subtitle}
          </h6>
        ) : null}
        {showClose && (
          <CloseDialogButton onClick={onClose} />
        )}
        {onPrevious ? (
          <div className="absolute p-4 -m-4 cursor-pointer top-16 left-10" onClick={onPrevious}>
            <ArrowLeftIcon className="w-8 h-8 text-cs-orange-500 stroke-2" />
          </div>
        ) : null}
      </div>
      {alert}
      {body && (
        <div className="pt-8 px-8">
          {body}
        </div>
      )}
      {footer && (
        <div className="flex items-center justify-end px-8 py-8">
          {footer}
        </div>
      )}
    </div>
  );
}
