import React from 'react';
import PageHeading from '../../@communityshare/ui/PageHeading';
import ParentLayout from '../settings/Layout';
import EducatorForm from './Form.educator';
import PartnerForm from './Form.partner';
import Loader from '../../components/Loader';
import { PayloadStates } from '@lore/utils';
import useNetwork from '../../hooks/useNetwork';
import { Helmet } from 'react-helmet-async';
import { useQueryClient } from '@tanstack/react-query';
import MobileNavigation from '../settings/MobileNavigation';
import NoNetworkSelected from '../settings/NoNetworkSelected';

export default function Layout(props) {
  const network = useNetwork();
  const queryClient = useQueryClient();

  if (!network) {
    return (
      <NoNetworkSelected />
    );
  }

  return (
    <ParentLayout>
      <div className="p-8 space-y-8">
        <Helmet>
          <title>Settings: Banner</title>
        </Helmet>
        <MobileNavigation />
        <PageHeading
          breadcrumbs={['Settings']}
          title="Banner"
          description={(
            <>
              <span className="block text-left">
                Have something you want to share with all educators or partners on the platform? You
                can add a message here and it will be displayed to each audience.
              </span>
            </>
          )}
        />
        {/*<div className="px-4 py-4 sm:px-6 lg:px-8">*/}
        {network.state === PayloadStates.FETCHING ? (
          <Loader />
        ) : (
          <>
            <EducatorForm
              network={network}
              onSubmit={function(request) {
                queryClient.invalidateQueries({ queryKey: ['network'] });
              }}
            />
            <PartnerForm
              network={network}
              onSubmit={function(request) {
                queryClient.invalidateQueries({ queryKey: ['network'] });
              }}
            />
          </>
        )}
      </div>
    </ParentLayout>
  );
}
