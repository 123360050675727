/**
 * This component checks if the user is staff. If not, it automatically inserts
 * a default network into the query paramters to enforce network selection for
 * non-staff stewards.
 */

import React, { useEffect } from 'react';
import { useUser } from '@lore/auth';
import usePageRouter from '../../hooks/@lore/dialogs-routable/_hooks/usePageRouter';
import useNetwork from '../hooks/useNetwork';
import { parse, stringify } from 'query-string';
import _ from 'lodash';

export default function EnforceNetwork(props) {
  const { networkStewards } = props;

  const network = useNetwork();
  const currentUser = useUser();
  const { location, history } = usePageRouter();

  useEffect(() => {
    if (currentUser.data.isStaff) {
      return;
    }

    const isSteward = !!_.find(networkStewards.data, ns => ns.data.networkId === network?.id);

    if (isSteward) {
      return;
    }

    const networkSteward = networkStewards.data[0];

    history.push({
      pathname: location.pathname,
      search: stringify({
        ...parse(location.search),
        networks: networkSteward.data.networkId
      })
    });
  }, [currentUser.id, location.href]);

  return null;
};
