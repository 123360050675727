import React, { useState } from 'react';
import _ from 'lodash';
import { PayloadStates } from '@lore/utils';
import moment from 'moment-timezone';
import colors from 'tailwindcss/colors';
import LineChart from '../../dashboard/_charts/LineChart';
import ChartLoader from '../../dashboard/_charts/ChartLoader';
import useOrganizations from './_hooks/useOrganizations';

export default function OrganizationCumulativeGrowthByMonth(props) {
  const { network, timestamps, empty, schoolStart } = props;

  const [currentTimestamp] = useState(moment().toISOString());

  const organizations = timestamps.map(function(timestamp, index) {
    if (moment(currentTimestamp).diff(timestamp.start) < 0) {
      return {
        ...timestamp,
        results: {
          organizations: empty()
        }
      }
    }

    return {
      ...timestamp,
      results: {
        organizations: useOrganizations(network, undefined, timestamp.end)
      }
    }
  });

  const loading = _.reduce(organizations, function(result, data) {
    return (
      result ||
      data.results.organizations.state === PayloadStates.FETCHING
    );
  }, false);

  if (loading) {
    return (
      <ChartLoader
        title="Organizations in your network"
        subtitle=""
      />
    );
  }

  const series = [
    {
      name: 'New Organizations',
      data: organizations.map(e => e.results.organizations.meta.totalCount)
    }
  ];

  return (
    <LineChart
      title="Organizations in your network"
      subtitle="Over school year"
      labels={organizations.map(e => e.label)}
      series={series}
      colors={[
        colors.blue['400'],
        colors.emerald['400'],
        colors.yellow['400']
      ]}
    />
  );
}
