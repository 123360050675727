import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import CancelledTag from '../../../pages/_components/CancelledTag';
import CompletedTag from '../../../pages/_components/CompletedTag';
import ExpiredTag from '../../../pages/_components/ExpiredTag';
import DateRange from './DateRange';
import Visibility from './Visibility';
import Creator from './Creator';
import Organization from './Organization';
import ShareLink from './ShareLink';
import Partners from '../../../pages/offers/Row/Partners';
import CreatorPhone from './CreatorPhone';
import CreatorEmail from './CreatorEmail';
import { useUser } from '@lore/auth';
import Network from '../../../pages/offers/Offer/Network';

export default function Header(props) {
  const { offer } = props;

  const currentUser = useUser();

  const activity = useConnect('tag.byId', {
    id: offer.data.activityId
  });

  return (
    <div className="flex flex-col space-y-6">
      <div className="space-y-2">
        <div className="flex space-x-4">
          <div className="text-sm text-gray-500">
            Offer
          </div>
          <div className="flex space-x-2">
            {/*<HiddenTag resource={offer}/>*/}
            <ExpiredTag resource={offer}/>
            <CancelledTag resource={offer}/>
            <CompletedTag resource={offer}/>
          </div>
        </div>
        <div className="text-3xl font-bold text-gray-700">
          {offer.data.title || activity.data.name || '...'}
        </div>
        <div className="text-gray-500">
          {offer.data.description}
        </div>
        <div className="flex justify-between">
          <div className="flex flex-wrap gap-x-4 gap-y-2">
            <DateRange offer={offer} />
            <Creator offer={offer} />
            <CreatorPhone offer={offer} />
            <CreatorEmail offer={offer} />
            <Organization offer={offer} />
            {currentUser.data.isStaff && (
              <Network offer={offer} />
            )}
            <Visibility offer={offer} />
            <ShareLink offer={offer} />
          </div>
          <Partners offer={offer} />
        </div>
      </div>
    </div>
  );
};
