import React from 'react';
import { PayloadStates } from '@lore/utils';
import _ from 'lodash';
import ChartLoader from '../../states/_charts/ChartLoader';
import DonutChart from '../../states/_charts/DonutChart';
import colors from 'tailwindcss/colors';
import useActivePartners from './_hooks/useActivePartners';
import useInactivePartners from './_hooks/useInactivePartners';
import useSleepingPartners from './_hooks/useSleepingPartners';
import useIncompletePartners from './_hooks/useIncompletePartners';

export default function PartnerStatusYear(props) {
  const { state, network, schoolStart, schoolEnd, subtitle, currentTimestamp } = props;

  const requests = [
    {
      label: 'Active',
      partners: useActivePartners(state, network, schoolStart, schoolEnd, currentTimestamp)
    },
    {
      label: 'Sleeping',
      partners: useSleepingPartners(state, network, schoolStart, schoolEnd, currentTimestamp)
    },
    {
      label: 'Inactive',
      partners: useInactivePartners(state, network, schoolStart, schoolEnd, currentTimestamp)
    },
    {
      label: 'Incomplete',
      partners: useIncompletePartners(state, network, schoolStart, schoolEnd, currentTimestamp)
    }
  ];

  const loading = _.reduce(requests, function(result, request) {
    return result || request.partners.state === PayloadStates.FETCHING;
  }, false);

  if (loading) {
    return (
      <ChartLoader
        title="Partner Status"
        subtitle={subtitle || 'For school year'}
      />
    );
  }

  const series = requests.map(r => r.partners.meta.totalCount);
  const labels = requests.map(r => r.label);

  return (
    <DonutChart
      title="Partner Status"
      subtitle={subtitle || 'For school year'}
      labels={labels}
      series={series}
      colors={[
        colors.emerald['400'],
        colors.blue['400'],
        colors.red['400'],
        colors.yellow['400']
      ]}
    />
  );
}
