import React from 'react';
import { useConnect } from '@lore/query-connect';
import useStewardshipFilter from '../../projects/useStewardshipFilter';
import REQUIRED_FIELDS from '../../projects/REQUIRED_FIELDS';
import { PayloadStates } from '@lore/utils';
import MetricLoader from '../MetricLoader';
import moment from 'moment-timezone';
import ComparisonMetric from '../ComparisonMetric';

export default function SharedProject(props) {
  const { network, schoolStart, schoolEnd, titleOnly = false } = props;

  const stewardshipFilter = useStewardshipFilter();

  const previousSchoolStart = moment(schoolStart).subtract(1, 'year').toISOString();

  const previousUsers = useConnect('user.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfProjects',
            relation: 'projects',
            $where: {
              // published: true,
              activityId: {
                $not: null
              },
              networkId: network?.id,
              createdAt: {
                $lte: schoolStart,
                $gte: titleOnly ? undefined : previousSchoolStart
              },
              $and: REQUIRED_FIELDS.$and
            }
          }
        ],
        $where: {
          numberOfProjects: {
            $gte: 1
          },
          isEducator: true,
          $or: stewardshipFilter
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  const newUsers = useConnect('user.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfProjects',
            relation: 'projects',
            $where: {
              // published: true,
              activityId: {
                $not: null
              },
              networkId: network?.id,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              },
              $and: REQUIRED_FIELDS.$and
            }
          }
        ],
        $where: {
          numberOfProjects: {
            $gte: 1
          },
          isEducator: true,
          $or: stewardshipFilter
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  if (
    previousUsers.state === PayloadStates.FETCHING ||
    newUsers.state === PayloadStates.FETCHING
  ) {
    return (
      <MetricLoader
        name="Shared a Project"
      />
    );
  }

  return (
    <ComparisonMetric
      name="Shared a Project"
      value={newUsers.meta.totalCount}
      previousValue={previousUsers.meta.totalCount}
      titleOnly={titleOnly}
    />
  );
}
