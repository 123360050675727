import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import useQuery from './useQuery';
import useSchoolYearParams from '../../evaluations/useSchoolYearParams';
import { parse } from 'query-string';
import Results from './Results';
import { DialogManager } from '../../../dialogs_new/DialogManager';

export default function Layout(props) {
  const { location } = useRouter();

  const params = useSchoolYearParams();

  const PAGE_SIZE = 12;

  const result = useQuery({
    location,
    search: parse(location.search),
    pageSize: PAGE_SIZE,
    network: params.network,
    schoolStart: params.schoolStart,
    schoolEnd: params.schoolEnd
  });

  return (
    <ParentLayout
      location={location}
      extra={{
        schoolStart: params.schoolStart,
        schoolEnd: params.schoolEnd,
        // variant: 'photos'
      }}
      filterDialog={DialogManager.FiltersUploadEvaluationEventEducator}
      breadcrumbs={['Evaluations', 'Events: Educators']}
      title="Photos"
      description={(
        <>
          Photos uploaded by an educator as part of an event evaluation.
        </>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      // row={function(upload) {
      //   const url = getUrlForUpload(upload);
      //   return (
      //     <Image
      //       key={upload.id}
      //       image={{
      //         id: upload.id,
      //         data: {
      //           imageUrl: url,
      //           // width: 0,
      //           // height: 0
      //         }
      //       }}
      //       // imageHeight="h-64 sm:h-48 lg:h-40"
      //     />
      //   );
      // }}
      render={(
        <Results result={result} />
      )}
    />
  );
}
