import React from 'react';
import PageHeading from '../../@communityshare/ui/PageHeading';
import ParentLayout from '../settings/Layout';
import Form from './Form';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useNetwork from '../../hooks/useNetwork';
import { Helmet } from 'react-helmet-async';
import { useQueryClient } from '@tanstack/react-query';
import MobileNavigation from '../settings/MobileNavigation';
import NoNetworkSelected from '../settings/NoNetworkSelected';

export default function Layout(props) {
  const network = useNetwork();
  const queryClient = useQueryClient();

  if (!network) {
    return (
      <NoNetworkSelected />
    );
  }

  return (
    <ParentLayout>
      <div className="p-8 space-y-8">
        <Helmet>
          <title>Settings: Training</title>
        </Helmet>
        <MobileNavigation />
        <PageHeading
          breadcrumbs={['Settings']}
          title="Training"
          description={(
            <>
              <span className="block mb-2">
                What information do you want all community partners to know before communicating with
                educators or students? This is the place to add that information! We recommend hosting a
                webpage with any videos, handbooks, or general guidance you want to provide.
              </span>
              <span className="block mb-2">
                In the box below, enter the URL where this training information is located. CommunityShare
                will automatically send an email with this link when a partner signs up inviting them to
                view your training materials.
              </span>
              <span className="block text-left">
                As we grow, we'll add the ability to add other trainings in this Admin system.
              </span>
            </>
          )}
        />
        {network.state === PayloadStates.FETCHING ? (
          <Loader />
        ) : (
          <Form
            network={network}
            onSubmit={function(request) {
              queryClient.invalidateQueries({ queryKey: ['network'] });
            }}
          />
        )}
      </div>
    </ParentLayout>
  );
}
