import React from 'react';
import useGetCount from '../../explore/useGetCount';

import evaluationsProjectEducatorAll from '../../evaluations-project-educators/useQuery';
import evaluationsProjectPartnerAll from '../../evaluations-project-partners/useQuery';
import evaluationsEventEducatorAll from '../../evaluations-event-educators/useQuery';
import evaluationsEventPartnerAll from '../../evaluations-event-partners/useQuery';

import evaluationsProjectEducatorComplete from '../../evaluations-project-educators-filtered/complete/useQuery';
import evaluationsProjectEducatorPending from '../../evaluations-project-educators-filtered/pending/useQuery';
import evaluationsProjectEducatorPhotos from '../../evaluations-project-educators-filtered/photos/useQuery';
import evaluationsProjectEducatorTestimonials from '../../evaluations-project-educators-filtered/testimonials/useQuery';
import evaluationsProjectEducatorStories from '../../evaluations-project-educators-filtered/stories/useQuery';

import evaluationsProjectPartnerComplete from '../../evaluations-project-partners-filtered/complete/useQuery';
import evaluationsProjectPartnerPending from '../../evaluations-project-partners-filtered/pending/useQuery';
import evaluationsProjectPartnerContributions from '../../evaluations-project-partners-filtered/contributions/useQuery';
import evaluationsProjectPartnerTestimonials from '../../evaluations-project-partners-filtered/testimonials/useQuery';

import evaluationsEventEducatorComplete from '../../evaluations-event-educators-filtered/complete/useQuery';
import evaluationsEventEducatorPending from '../../evaluations-event-educators-filtered/pending/useQuery';
import evaluationsEventEducatorPhotos from '../../evaluations-event-educators-filtered/photos/useQuery';
import evaluationsEventEducatorTestimonials from '../../evaluations-event-educators-filtered/testimonials/useQuery';
import evaluationsEventEducatorStories from '../../evaluations-event-educators-filtered/stories/useQuery';

import evaluationsEventPartnerComplete from '../../evaluations-event-partners-filtered/complete/useQuery';
import evaluationsEventPartnerPending from '../../evaluations-event-partners-filtered/pending/useQuery';
import evaluationsEventPartnerTestimonials from '../../evaluations-event-partners-filtered/testimonials/useQuery';
import useSchoolYearParams from '../useSchoolYearParams';
import useRouter from '../../../hooks/useRouter';

export function getEvaluationTypeNavigationLinks(currentNavigationLink) {
  const root = `${currentNavigationLink.href}`;

  return [
    { name: 'Projects: Educators', href: `${root}/projects/educators` },
    { name: 'Projects: Partners', href: `${root}/projects/partners` },
    { name: 'Events: Educators', href: `${root}/events/educators` },
    { name: 'Events: Partners', href: `${root}/events/partners` }
  ];
}

export function getEvaluationTypePageNavigationLinks(groupedNavigationLinks = []) {
  const { location } = useRouter();

  const linkGroup = _.find(groupedNavigationLinks, function(linkGroup) {
    const currentLink = _.find(linkGroup.links, link => link.href === location.pathname);
    return !!currentLink;
  });

  return linkGroup?.links || [];
}

export default function getNavigationLinks(network, currentUser, currentNavigationLink) {
  const root = `${currentNavigationLink.href}`;

  const params = useSchoolYearParams();

  return [
    {
      title: 'Projects: Educators',
      links: [
        { name: 'All', href: `${root}/projects/educators`, count: useGetCount(evaluationsProjectEducatorAll, params) },
        { name: 'Pending', href: `${root}/projects/educators/pending`, count: useGetCount(evaluationsProjectEducatorPending, params) },
        { name: 'Complete', href: `${root}/projects/educators/stories`, count: useGetCount(evaluationsProjectEducatorStories, params) },
        { name: 'Photos', href: `${root}/projects/educators/photos`, count: useGetCount(evaluationsProjectEducatorPhotos, params) },
        { name: 'Testimonials', href: `${root}/projects/educators/testimonials`, count: useGetCount(evaluationsProjectEducatorTestimonials, params) },
        // { name: 'All', href: `${root}/projects/educators`, count: useGetCount(evaluationsProjectEducatorAll, params) },
        // { name: 'Complete', href: `${root}/projects/educators/complete`, count: useGetCount(evaluationsProjectEducatorComplete, params) },
        // { name: 'Pending', href: `${root}/projects/educators/pending`, count: useGetCount(evaluationsProjectEducatorPending, params) }
      ]
    },
    {
      title: 'Projects: Partners',
      links: [
        { name: 'All', href: `${root}/projects/partners`, count: useGetCount(evaluationsProjectPartnerAll, params) },
        // { name: 'Pending', href: `${root}/projects/partners/pending`, count: useGetCount(evaluationsProjectPartnerPending, params) },
        { name: 'In-kind Contributions', href: `${root}/projects/partners/contributions`, count: useGetCount(evaluationsProjectPartnerContributions, params) },
        { name: 'Testimonials', href: `${root}/projects/partners/testimonials`, count: useGetCount(evaluationsProjectPartnerTestimonials, params) },
      //   { name: 'All', href: `${root}/projects/partners`, count: useGetCount(evaluationsProjectPartnerAll, params) },
      //   // { name: 'Complete', href: `${root}/projects/partners/complete`, count: useGetCount(evaluationsProjectPartnerComplete, params) },
      //   { name: 'Pending', href: `${root}/projects/partners/pending`, count: useGetCount(evaluationsProjectPartnerPending, params) }
      ]
    },
    {
      title: 'Events: Educators',
      links: [
        { name: 'All', href: `${root}/events/educators`, count: useGetCount(evaluationsEventEducatorAll, params) },
        { name: 'Pending', href: `${root}/events/educators/pending`, count: useGetCount(evaluationsEventEducatorPending, params) },
        { name: 'Complete', href: `${root}/events/educators/stories`, count: useGetCount(evaluationsEventEducatorStories, params) },
        { name: 'Photos', href: `${root}/events/educators/photos`, count: useGetCount(evaluationsEventEducatorPhotos, params) },
        { name: 'Testimonials', href: `${root}/events/educators/testimonials`, count: useGetCount(evaluationsEventEducatorTestimonials, params) },
        // { name: 'All', href: `${root}/events/educators`, count: useGetCount(evaluationsEventEducatorAll, params) },
        // { name: 'Complete', href: `${root}/events/educators/complete`, count: useGetCount(evaluationsEventEducatorComplete, params) },
        // { name: 'Pending', href: `${root}/events/educators/pending`, count: useGetCount(evaluationsEventEducatorPending, params) }
      ]
    },
    {
      title: 'Events: Partners',
      links: [
        { name: 'All', href: `${root}/events/partners`, count: useGetCount(evaluationsEventPartnerAll, params) },
        { name: 'Testimonials', href: `${root}/events/partners/testimonials`, count: useGetCount(evaluationsEventPartnerTestimonials, params) },
        // { name: 'All', href: `${root}/events/partners`, count: useGetCount(evaluationsEventPartnerAll, params) },
        // { name: 'Complete', href: `${root}/events/partners/complete`, count: useGetCount(evaluationsEventPartnerComplete, params) },
        // { name: 'Pending', href: `${root}/events/partners/pending`, count: useGetCount(evaluationsEventPartnerPending, params) }
      ]
    }
  ];
}
