import React, { useState } from 'react';
import { parse, stringify } from 'query-string';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';
import TagTypes from '../../constants/TagTypes';
import SearchFilter from '../../forms/_fields_v2/SearchFilter';
import DisclosureTagFilter from '../../forms/_fields_v2/DisclosureTagFilter';
// import SearchTags from '../../components/SearchTags';
import ClearAllFilters from '../../components/ClearAllFilters';
import { getSearchUtils } from '../../utils/query-utils';
import useQuery from '../../pages/projects/useQuery';
import useFilterCount from '../../hooks/useFilterCount';
import useStewardshipFilter from './useStewardshipFilter';
import useInteractionTypesFilterParam from './useInteractionTypesFilterParam';
import useDistanceFilterParam from './useDistanceFilterParam';
import REQUIRED_FIELDS_PUBLIC from '../../pages/projects/REQUIRED_FIELDS_PUBLIC';
import REQUIRED_FIELDS from '../../pages/projects/REQUIRED_FIELDS';
import MISSING_FIELDS from '../../pages/projects/MISSING_FIELDS';
import { SolidButton } from '../../@communityshare/ui/Button';
import DisclosureFilter from '../../forms/_fields_v2/DisclosureFilter';
import { attributeKeys } from '../../pages/projects/queryUtils';
import _ from 'lodash';
import DisclosureBaseFilter from '../../forms/_fields_v2/DisclosureBaseFilter';
import PayloadStates from '../../constants/PayloadStates';
import moment from 'moment-timezone';
import useNetworkFilter from './useNetworkFilter';

export default function ProjectFiltersDialog(props) {
  const { router } = props;

  const { location, history } = router;

  const [search, setSearch] = useState(parse(location.search));

  const [timestamp] = useState(new Date().toISOString());

  const networkFilter = useNetworkFilter(search);
  const stewardshipFilter = useStewardshipFilter(search);
  const interactionTypesFilter = useInteractionTypesFilterParam(search);
  const distanceFilter = useDistanceFilterParam(search);

  const filterFields = {
    title: 'title',
    name: 'name',
    school: 'school',
    district: 'district',
    activities: 'activities',
    expertiseTypes: 'expertiseTypes',
    subjects: 'subjects',
    ages: 'ages',
    days: 'days',
    interactionTypes: 'interactionTypes',
    participants: 'participants',
    times: 'times',
    commitments: 'commitments',
    createdDates: 'createdDates',
    status: 'status'
  };

  const filterCount = useFilterCount(search, _.keys(filterFields));

  function onSubmit() {
    history.push({
      pathname: location.pathname,
      search: stringify({
        ...search,
        page: 1
      })
    });
    props.onClose();
  }

  function onChange(nextSearch) {
    setSearch(nextSearch);
  }

  function getTagParams(tag) {
    return {
      where: {
        eager: {
          $where: {
            ...networkFilter,
            // ...stewardshipFilter,
            // ...interactionTypesFilter,
            // ...distanceFilter,
            'tagNodes.tagId': tag.id,
            // $and: REQUIRED_FIELDS_PUBLIC.$and
          },
        }
      },
      pagination: {
        pageSize: 1
      }
    };
  }

  function getActivityParams(tag) {
    return {
      where: {
        eager: {
          $where: {
            ...networkFilter,
            // ...stewardshipFilter,
            // ...interactionTypesFilter,
            // ...distanceFilter,
            activityId: tag.id,
            // $and: REQUIRED_FIELDS_PUBLIC.$and
          },
        }
      },
      pagination: {
        pageSize: 1
      }
    };
  }

  const searchUtils = getSearchUtils(search, onChange);

  const result = useQuery({
    search
  });

  return (
    <DialogTemplate className="w-screen max-w-screen-sm h-[calc(100dvh-32px)] max-h-[calc(720px)]">
      <CloseDialogButton onClick={props.onClose} />
      <div className="flex flex-col space-y-8x h-full">
        <div className="space-y-1 p-6 border-b border-cs-gray-200">
          <h2 className="text-cs-gray-900 text-2xl font-semibold">
            Filters
          </h2>
          <div className="text-cs-gray-500 text-sm">
            Narrow your search results.
          </div>
          {/*<div className="pt-4">*/}
          {/*  <SearchTags*/}
          {/*    search={search}*/}
          {/*    onChange={setSearch}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        <div className="px-6 py-8 space-y-8 overflow-y-auto h-full">
          <div className="space-y-4">
            <DisclosureFilter
              label="Search"
              value={[
                searchUtils(filterFields.title).value,
                searchUtils(filterFields.name).value,
                searchUtils(filterFields.school).value,
                searchUtils(filterFields.district).value
              ]}
            >
              <SearchFilter
                label="Project title"
                description="Find projects with a title that contains the phrase you type."
                value={searchUtils(filterFields.title).value}
                onChange={searchUtils(filterFields.title).onChangeValue}
              />
              <SearchFilter
                label="Educator name"
                description="Find projects created by an educator whose first or last name matches one of the words you enter."
                value={searchUtils(filterFields.name).value}
                onChange={searchUtils(filterFields.name).onChangeValue}
              />
              <SearchFilter
                label="School"
                description="Find projects affiliated with a school whose name contains the phrase you type."
                value={searchUtils(filterFields.school).value}
                onChange={searchUtils(filterFields.school).onChangeValue}
              />
              <SearchFilter
                label="District"
                description="Find projects affiliated with a school district whose name contains the phrase you type."
                value={searchUtils(filterFields.district).value}
                onChange={searchUtils(filterFields.district).onChangeValue}
              />
            </DisclosureFilter>
          </div>
          <DisclosureBaseFilter
            modelName="project"
            label="Completion status"
            getParams={function getStatusParams(model) {
              const whereMap = {
                1: {
                  $and: REQUIRED_FIELDS_PUBLIC.$and,
                  completed: false,
                  cancelled: false,
                  // $or: stewardshipFilter
                  ...networkFilter,
                },
                2: {
                  completed: true,
                  // $or: stewardshipFilter
                  ...networkFilter,
                },
                3: {
                  cancelled: true,
                  // $or: stewardshipFilter
                  ...networkFilter,
                },
                4: {
                  $and: {
                    $or: MISSING_FIELDS,
                  },
                  completed: false,
                  cancelled: false,
                  activityId: {
                    $exists: true
                  },
                  // $or: stewardshipFilter
                  ...networkFilter,
                },
                5: {
                  $and: REQUIRED_FIELDS.$and,
                  $or: [
                    {
                      specificDate: 'Yes',
                      date: {
                        $lte: moment(timestamp).toISOString()
                      }
                    },
                    {
                      specificDate: 'No',
                      endDate: {
                        $lte: moment(timestamp).toISOString()
                      }
                    }
                  ],
                  completed: false,
                  cancelled: false,
                  // $or: stewardshipFilter
                  ...networkFilter,
                },
                6: {
                  $and: REQUIRED_FIELDS.$and,
                  $or: [
                    {
                      specificDate: 'Yes',
                      date: {
                        $gte: moment(timestamp).toISOString()
                      }
                    },
                    {
                      specificDate: 'No',
                      endDate: {
                        $gte: moment(timestamp).toISOString()
                      }
                    }
                  ],
                  completed: false,
                  cancelled: false,
                  published: false,
                  // $or: stewardshipFilter
                  ...networkFilter,
                }
              };

              const where = whereMap[model.id] || {
                // $or: stewardshipFilter
              };

              return {
                where: {
                  eager: {
                    $where: where
                  }
                },
                pagination: {
                  pageSize: 1
                }
              };
            }}
            value={searchUtils(filterFields.status).value}
            onChange={searchUtils(filterFields.status).onChangeArray}
            tags={{
              state: PayloadStates.RESOLVED,
              data: [
                {
                  id: 1,
                  data: {
                    name: 'Active'
                  }
                },
                {
                  id: 2,
                  data: {
                    name: 'Completed'
                  }
                },
                {
                  id: 3,
                  data: {
                    name: 'Cancelled'
                  }
                },
                {
                  id: 4,
                  data: {
                    name: 'Incomplete'
                  }
                },
                {
                  id: 5,
                  data: {
                    name: 'Expired'
                  }
                },
                {
                  id: 6,
                  data: {
                    name: 'Active (but hidden)'
                  }
                }
              ]
            }}
          />
          <DisclosureTagFilter
            modelName="project"
            type={TagTypes.Activity}
            label="Activity"
            getParams={getActivityParams}
            value={searchUtils(filterFields.activities).value}
            onChange={searchUtils(filterFields.activities).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="project"
            type={TagTypes.ExpertiseType}
            label="Skills and experience"
            getParams={getTagParams}
            value={searchUtils(filterFields.expertiseTypes).value}
            onChange={searchUtils(filterFields.expertiseTypes).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="project"
            type={TagTypes.Subject}
            label="Subject areas"
            getParams={getTagParams}
            value={searchUtils(filterFields.subjects).value}
            onChange={searchUtils(filterFields.subjects).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="project"
            type={TagTypes.Age}
            label="Student ages"
            getParams={getTagParams}
            value={searchUtils(filterFields.ages).value}
            onChange={searchUtils(filterFields.ages).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="project"
            type={TagTypes.Day}
            label="Day"
            getParams={getTagParams}
            value={searchUtils(filterFields.days).value}
            onChange={searchUtils(filterFields.days).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="project"
            type={TagTypes.InteractionType}
            label="Interaction types"
            getParams={getTagParams}
            value={searchUtils(filterFields.interactionTypes).value}
            onChange={searchUtils(filterFields.interactionTypes).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="project"
            type={TagTypes.Participant}
            label="Participants"
            getParams={getTagParams}
            value={searchUtils(filterFields.participants).value}
            onChange={searchUtils(filterFields.participants).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="project"
            type={TagTypes.Time}
            label="Time"
            getParams={getTagParams}
            value={searchUtils(filterFields.times).value}
            onChange={searchUtils(filterFields.times).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="project"
            type={TagTypes.Commitment}
            label="Commitment"
            getParams={getTagParams}
            value={searchUtils(filterFields.commitments).value}
            onChange={searchUtils(filterFields.commitments).onChangeArray}
          />
          <DisclosureBaseFilter
            modelName="project"
            label="Created"
            getParams={function getCreatedParams(model) {
              // const weeks = Number(model.id.split('_')[1]);
              const weeks = model.id;

              return {
                where: {
                  eager: {
                    $where: {
                      createdAt: {
                        $gte: moment(timestamp).subtract(weeks, 'weeks').toISOString(),
                      },
                      activityId: {
                        $not: null
                      },
                      // $or: stewardshipFilter
                      ...networkFilter,
                    }
                  }
                },
                pagination: {
                  pageSize: 1
                }
              };
            }}
            value={searchUtils(filterFields.createdDates).value}
            onChange={searchUtils(filterFields.createdDates).onChangeArray}
            tags={{
              state: PayloadStates.RESOLVED,
              data: [
                // ['lte_1_weeks', 'Within 1 week'],
                // ['lte_2_weeks', 'Within 2 weeks'],
                // ['lte_4_weeks', 'Within 1 month'],
                // ['lte_8_weeks', 'Within 2 months'],
                // ['lte_13_week', 'Within 3 months'],
                // ['lte_26_week', 'Within 6 months'],
                // ['lte_52_week', 'Within the year']
                [1, 'Within 1 week'],
                [2, 'Within 2 weeks'],
                [4, 'Within 1 month'],
                [8, 'Within 2 months'],
                [13, 'Within 3 months'],
                [26, 'Within 6 months'],
                [52, 'Within the year']
              ].map(function(value) {
                return {
                  id: value[0],
                  data: {
                    name: value[1]
                  }
                }
              })
            }}
          />
        </div>
        <div className="p-6 flex items-center justify-between border-t border-cs-gray-200">
          <ClearAllFilters
            show={true}
            className="hover:opacity-70"
            onClick={() => setSearch(_.omit(search, _.keys(filterFields)))}
            count={filterCount}
          />
          <SolidButton onClick={onSubmit}>
            Show {result.meta?.totalCount} results
          </SolidButton>
        </div>
      </div>
    </DialogTemplate>
  );
};
