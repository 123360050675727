import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import useStewardshipFilter from '../../partners/useStewardshipFilter';
import ChartLoader from '../../states/_charts/ChartLoader';
import LineChart from '../../states/_charts/LineChart';
import colors from 'tailwindcss/colors';

export default function EngagementByMonth(props) {
  const { state, network, timestamps, empty, schoolStart } = props;

  const stewardshipFilter = useStewardshipFilter();

  const [currentTimestamp] = useState(moment().toISOString());

  const partners = timestamps.map(function(timestamp) {
    if (moment(currentTimestamp).diff(timestamp.start) < 0) {
      return {
        ...timestamp,
        results: {
          connect: empty(),
          yes: empty()
        }
      }
    }

    return {
      ...timestamp,
      results: {
        // connect: useConnect('question.find', {
        //   where: {
        //     eager: {
        //       $where: {
        //         createdAt: {
        //           $gte: timestamp.start,
        //           $lte: timestamp.end
        //         },
        //         response: 'Connect',
        //         $or: stewardshipFilter
        //       }
        //     }
        //   },
        //   pagination: {
        //     pageSize: 1
        //   }
        // }),
        connect: useConnect('user.find', {
          where: {
            eager: {
              $aggregations: _.remove([
                {
                  type: 'count',
                  alias: 'numberOfQuestions',
                  relation: 'questions',
                  $where: {
                    createdAt: {
                      $gte: timestamp.start,
                      $lte: timestamp.end
                    },
                    response: 'Connect'
                  }
                }
              ]),
              $where: {
                numberOfQuestions: {
                  $gte: 1
                },
                isEducator: false,
                'state': {
                  $ilike: state
                },
                // $or: stewardshipFilter
              }
            },
          },
          pagination: {
            pageSize: 1
          }
        }),
        // yes: useConnect('invitation.find', {
        //   where: {
        //     eager: {
        //       $where: {
        //         createdAt: {
        //           $gte: timestamp.start,
        //           $lte: timestamp.end
        //         },
        //         response: 'Yes',
        //         $or: stewardshipFilter
        //       }
        //     }
        //   },
        //   pagination: {
        //     pageSize: 1
        //   }
        // }),
        yes: useConnect('user.find', {
          where: {
            eager: {
              $aggregations: _.remove([
                {
                  type: 'count',
                  alias: 'numberOfInvitations',
                  relation: 'invitations',
                  $where: {
                    createdAt: {
                      $gte: timestamp.start,
                      $lte: timestamp.end
                    },
                    response: 'Yes'
                  }
                }
              ]),
              $where: {
                numberOfInvitations: {
                  $gte: 1
                },
                isEducator: false,
                'state': {
                  $ilike: state
                },
                // $or: stewardshipFilter
              }
            },
          },
          pagination: {
            pageSize: 1
          }
        }),
      }
    }
  });

  const loading = _.reduce(partners, function(result, partner) {
    return (
      result ||
      partner.results.connect.state === PayloadStates.FETCHING ||
      partner.results.yes.state === PayloadStates.FETCHING
    );
  }, false)

  if (loading) {
    return (
      <ChartLoader
        title="Partner Engagement"
        subtitle="Over school year"
      />
    );
  }

  const series = [
    {
      name: 'Connected about Project',
      data: partners.map(e => e.results.connect.meta.totalCount)
    },
    {
      name: 'Committed to Event',
      data: partners.map(e => e.results.yes.meta.totalCount)
    }
  ];

  return (
    <LineChart
      title="Partner Engagement"
      subtitle="Over school year"
      labels={partners.map(e => e.label)}
      series={series}
      colors={[
        colors.blue['400'],
        colors.emerald['400']
      ]}
    />
  );
}
