import React from 'react';
import LazyMetrics from '../LazyMetrics';
import TotalYear from './TotalYear';
import TotalAssociatedWithProject from './TotalAssociatedWithProject';
import TotalAssociatedWithEvent from './TotalAssociatedWithEvent';
import TotalAssociatedWithProjectOrEvent from './TotalAssociatedWithProjectOrEvent';
import AssociatedWithProject from './AssociatedWithProject';
import AssociatedWithEvent from './AssociatedWithEvent';
import AssociatedWithProjectOrEvent from './AssociatedWithProjectOrEvent';
import SchoolGrowthByMonth from './SchoolGrowthByMonth';
import CompositeSchoolGrowth from './CompositeSchoolGrowth';
import SchoolCumulativeGrowthByMonth from './SchoolCumulativeGrowthByMonth';

export default function Schools(props) {
  const { params } = props;

  return (
    <>
      <LazyMetrics
        // title="Schools"
      >
        <TotalYear {...params} name="In-network" />
        {/*<TotalAssociatedWithProjectOrEvent {...params} />*/}
        {/*<TotalAssociatedWithProject {...params} />*/}
        {/*<TotalAssociatedWithEvent {...params} />*/}
        <AssociatedWithProjectOrEvent {...params} />
        <AssociatedWithProject {...params} />
        <AssociatedWithEvent {...params} />
      </LazyMetrics>
      <LazyMetrics
        title="Growth"
        columns={1}
      >
        {/*<SchoolGrowthByMonth {...params} />*/}
        <SchoolCumulativeGrowthByMonth {...params} />
        {/*<div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">*/}
        {/*  <CompositeSchoolGrowth {...params} showTotals={false} />*/}
        {/*</div>*/}
      </LazyMetrics>
    </>
  );
}
