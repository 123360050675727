/**
 * Configuration file for the Filestack file picker
 *
 * This file is where you define overrides for the default file picker parameters
 */

export default {

  /**
   * API key associated with account files should be uploaded to
   */

  platformUrl: function() {
    return `https://app.communityshare.org`
  },

  emptyNetworkCreatedAt: '2014-11-22 00:00:00.000+00'

};
