import React from 'react';
import PropTypes from 'prop-types';
import { OutlineButton } from '../../@communityshare/ui/Button';
import { SolidButton } from '../../@communityshare/ui/Button';
import StringField from '../../forms/_fields_v2/StringField';
import ImageField from '../../forms/_fields_v2/ImageField';
import FormTemplate from '../../dialogs_common/FormTemplate';
import FormField from '../../forms/_common/FormField';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';
import SelectField from '../../forms/_fields_v2/SelectField';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onClose
  } = props;

  return (
    <FormTemplate
      title="Create network"
      subtitle={(
        <>
          <span className="block">
            Fill out the information below to create a new network.
          </span>
        </>
      )}
      onClose={onClose}
      alert={alert}
      body={(
        <div className="space-y-4">
          <FormField name="name" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <StringField
                label="Name"
                placeholder="Name..."
                // description="Some text about a thing I like"
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
          <FormField name="subdomain" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <StringField
                label="Subdomain"
                placeholder="Subdomain..."
                // description="Some text about a thing I like"
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
          <FormField name="logo" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <ImageField
                label="Logo"
                placeholder="Image..."
                // description="Some text about a thing I like"
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
          <FormField name="isActive" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <SelectField
                label="Active"
                value={field.value === true ? 'Yes' : 'No'}
                // placeholder="Select option..."
                options={{
                  data: ['Yes', 'No'].map(function(value) {
                    return {
                      id: value,
                      data: {
                        name: value
                      }
                    };
                  })
                }}
                optionLabel="name"
                // onChange={field.onChange}
                onChange={function(id) {
                  field.onChange(id === 'Yes' ? true : false);
                }}
              />
            )}
          </FormField>
          <FormField name="city" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <StringField
                label="City"
                placeholder="City..."
                // description="Some text about a thing I like"
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
          <FormField name="state" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <StringField
                label="State"
                placeholder="State..."
                // description="Some text about a thing I like"
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
        </div>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onClose}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Create network
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
