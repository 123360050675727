import React from 'react';
import { useConnect } from '@lore/query-connect';
import REQUIRED_FIELDS from '../../projects/REQUIRED_FIELDS';
import { PayloadStates } from '@lore/utils';
import MetricLoader from '../MetricLoader';
import DeltaMetric from '../DeltaMetric';

export default function TotalYear(props) {
  const { state, network, schoolStart, schoolEnd, titleOnly } = props;

  const allProjects = useConnect('project.find', {
    where: {
      eager: {
        $where: {
          $and: REQUIRED_FIELDS.$and,
          // networkId: network?.id,
          'site.state': {
            $ilike: state
          },
          createdAt: {
            $lte: schoolEnd
          }
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  const newProjects = useConnect('project.find', {
    where: {
      eager: {
        $where: {
          $and: REQUIRED_FIELDS.$and,
          // networkId: network?.id,
          'site.state': {
            $ilike: state
          },
          createdAt: {
            $lte: schoolEnd,
            $gte: schoolStart
          }
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  if (
    allProjects.state === PayloadStates.FETCHING ||
    newProjects.state === PayloadStates.FETCHING
  ) {
    return (
      <MetricLoader
        name="Total"
      />
    );
  }

  return (
    <DeltaMetric
      name="Total"
      value={allProjects.meta.totalCount}
      delta={newProjects.meta.totalCount}
      titleOnly={titleOnly}
    />
  );
}
