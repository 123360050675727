import React from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import useNetwork from '../../hooks/useNetwork';
import useRouter from '../../hooks/useRouter';
import { REQUIRED_EDUCATOR_FIELDS } from '../../utils/user/REQUIRED_FIELDS';

export default function useStewardshipFilter(allowPublic = false) {
  const { location } = useRouter();
  const query = parse(location.search);

  const network = useNetwork();

  if (
    allowPublic &&
    query.networks === '0'
  ) {
    return [
      { networkId: network.id },
      REQUIRED_EDUCATOR_FIELDS
    ];
  }

  if (network) {
    return [
      { networkId: network.id }
    ];
  }
};
