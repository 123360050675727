import React from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import MetricLoader from '../MetricLoader';
import DeltaMetric from '../DeltaMetric';

export default function ContributionYear(props) {
  const { network, schoolStart, schoolEnd, titleOnly, showTotals = true } = props;

  const previousSchoolStart = titleOnly ? undefined : moment(schoolStart).subtract(1, 'year').toISOString();

  const totalEvaluations = useConnect('evaluationProjectPartner.find', {
    where: {
      eager: {
        $where: {
          'project.networkId': network?.id,
          contributionValue: {
            $gt: 0
          },
          createdAt: {
            $lte: schoolEnd
          }
        }
      },
    },
    pagination: {
      pageSize: 100
    }
  });

  const previousEvaluations = useConnect('evaluationProjectPartner.find', {
    where: {
      eager: {
        $where: {
          'project.networkId': network?.id,
          contributionValue: {
            $gt: 0
          },
          createdAt: {
            $lte: schoolStart,
            $gte: previousSchoolStart
          }
        }
      },
    },
    pagination: {
      pageSize: 100
    }
  });

  const newEvaluations = useConnect('evaluationProjectPartner.find', {
    where: {
      eager: {
        $where: {
          'project.networkId': network?.id,
          contributionValue: {
            $gt: 0
          },
          createdAt: {
            $lte: schoolEnd,
            $gte: schoolStart
          }
        }
      },
    },
    pagination: {
      pageSize: 100
    }
  });

  if (
    totalEvaluations.state === PayloadStates.FETCHING ||
    previousEvaluations.state === PayloadStates.FETCHING ||
    newEvaluations.state === PayloadStates.FETCHING
  ) {
    return (
      <>
        {showTotals && (
          <>
            <MetricLoader
              name="Total in-kind contributions"
            />
          </>
        )}
        {!titleOnly && (
          <>
            <MetricLoader
              name="In-kind contributions"
            />
          </>
        )}
      </>
    );
  }

  const totalCount = _.reduce(totalEvaluations.data, function(result, evaluation) {
    return result + evaluation.data.contributionValue;
  }, 0);

  const previousCount = _.reduce(previousEvaluations.data, function(result, evaluation) {
    return result + evaluation.data.contributionValue;
  }, 0);

  const newCount = _.reduce(newEvaluations.data, function(result, evaluation) {
    return result + evaluation.data.contributionValue;
  }, 0);

  return (
    <>
      {showTotals && (
        <>
          <DeltaMetric
            name="Total in-kind contributions"
            value={totalCount}
            delta={newCount}
            currency={true}
            titleOnly={titleOnly}
          />
        </>
      )}
      {!titleOnly && (
        <>
          <DeltaMetric
            name="In-kind contributions"
            value={newCount}
            delta={newCount - previousCount}
            currency={true}
            titleOnly={titleOnly}
          />
        </>
      )}
    </>
  );
}
