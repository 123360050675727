import React from 'react';
import { useConnect } from '@lore/query-connect';

export default function useOrganization(user) {
  return useConnect('organization.first', {
    where: {
      eager: {
        $where: {
          'jobs.userId': user.id
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'createdAt asc'
    }
  });
}
