export default function(network) {
  const weeksMap = {
    '1 weeks': '1 week',
    '4 weeks': '1 month',
    '8 weeks': '2 months',
    '13 weeks': '3 months',
    '26 weeks': '6 months',
    '52 weeks': '1 year',
  };

  return [1,2,4,8,13,26,52].map(function(year) {
    const key = `${year} weeks`;
    return {
      name: weeksMap[key] || key,
      href: `/feed/${year}`
    }
  });
};
