import React from 'react';
import PropTypes from 'prop-types';
import { OutlineButton } from '../../@communityshare/ui/Button';
import { CheckIcon } from '@heroicons/react/24/outline';
import FormTemplate from '../../dialogs_common/FormTemplate';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const { onClose, data } = props;

  return (
    <FormTemplate
      icon={(
        <div className="flex justify-center mb-4">
          <CheckIcon className="text-cs-green-500 h-12 w-12" />
        </div>
      )}
      title="Network created!"
      success={true}
      onClose={onClose}
      // alert={alert}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onClose}>
            Close
          </OutlineButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
