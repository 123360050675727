import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import _ from 'lodash';
import Pagination from '../../components/pagination-new/Pagination';
import useScrollToTop from '../../hooks/useScrollToTop';
import useFilterCount from '../../hooks/useFilterCount';
import useQuery from './useQuery';
// import DistanceFilter from './DistanceFilter';
import FilterButton from '../_common_filters/FilterButton';
import PaginationInformation from '../../components/pagination-new/PaginationInformation';
// import TypeFilter from './TypeFilter';
import NetworkFilter from '../partners/NetworkFilter';
import SortBy from './SortBy';
import { attributeKeys } from '../partners/queryUtils';
import useDialogLauncher from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import useDialogConfig from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogConfig';
import { DialogManager } from '../../dialogs_new/DialogManager';
import View from '../_components/View';
import { CloudArrowDownIcon } from '@heroicons/react/24/outline';

Partners.propTypes = {
  children: PropTypes.func.isRequired
};

export default function Partners(props) {
  const { router, children } = props;
  const { location } = router;

  const search = parse(location.search);
  const filterCount = useFilterCount(search, _.filter(attributeKeys, key => [
    'networks',
    'locationCondition'
  ].indexOf(key) < 0));

  const node = useRef();
  const scrollToTop = useScrollToTop();

  const pageSize = 12;

  const result = useQuery({
    search,
    pageSize
  });

  const csvQuery = useQuery({
    search,
    pageSize,
    returnRawQuery: true
  });

  const launch = useDialogLauncher();
  const config = useDialogConfig();

  function showFilters() {
    launch(DialogManager.FiltersPartner.Config(config?.id, {
      variant: 'engagement'
    }));
  }

  function onDownload(query) {
    launch(DialogManager.CSVDownload.Config(query));
  }

  return (
    <>
      {/*<div ref={node} className="flex items-center justify-between">*/}
      {/*  <PaginationInformation*/}
      {/*    pageSize={pageSize}*/}
      {/*    totalCount={result.meta?.totalCount}*/}
      {/*  />*/}
      {/*</div>*/}
      <div ref={node} className="flex flex-wrap gap-4 justify-between items-end">
        <PaginationInformation
          pageSize={pageSize}
          totalCount={result.meta?.totalCount}
        />
        {/*<div className="flex space-x-4">*/}
        {/*  <NetworkFilter />*/}
        {/*  /!*<TypeFilter />*!/*/}
        {/*  /!*<DistanceFilter />*!/*/}
        {/*</div>*/}
        <div className="flex flex-wrap gap-4">
          <SortBy/>
          <FilterButton
            count={filterCount}
            onClick={showFilters}
          />
        </div>
      </div>
      {/*<Results result={result} />*/}
      {children(result)}
      <div className="space-y-4">
        <div className="flex flex-wrap gap-4 items-center justify-between">
          <PaginationInformation
            pageSize={pageSize}
            totalCount={result.meta?.totalCount}
          />
          <Pagination
            pageSize={pageSize}
            totalCount={result.meta?.totalCount}
            onNavigate={() => scrollToTop(node)}
          />
        </div>
        {csvQuery && result.meta?.totalCount > 0 && (
          <div className="flex items-center">
            <View
              icon={CloudArrowDownIcon}
              onClick={() => onDownload(csvQuery)}
            >
              Download results as CSV
            </View>
          </div>
        )}
      </div>
    </>
  );
}
