import React from 'react';
import EmptyState from '../../@communityshare/ui/EmptyState';
import { NoSymbolIcon } from '@heroicons/react/24/outline';

export default function NotAuthorizedEmptyState(props) {
  return (
    <div className="p-8">
      <EmptyState
        icon={NoSymbolIcon}
        title="You do not have permission to view this page."
        // subtitle="You must select a network in order to view this page."
      />
    </div>
  );
}
