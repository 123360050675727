import React from 'react';
import Date from './Date';
import Creator from './Creator';

export default function Job(props) {
  const { job, className, children } = props;

  return (
    <div className="flex flex-col sm:flex-row items-start justify-between">
      <div className="flex flex-1 flex-wrap gap-x-4 gap-y-2 mb-2 sm:mb-0">
        <Creator job={job}/>
        <Date job={job}/>
      </div>
    </div>
  );
}
