import React from 'react';
import useGetCount from '../../explore/useGetCount';

// Events
import starredEvents from '../../events-filtered/starred/useQuery';
import newEvents from '../../events-filtered/new/useQuery';
import draftEvents from '../../events-filtered/draft/useQuery.last4weeks';
import partnersEvents from '../../events-filtered/partners/useQuery';
import activeEvents from '../../events-filtered/active/useQuery';
import happeningSoonEvents from '../../events-filtered/soon/useQuery';
import expiredNotClosedEvents from '../../events-filtered/expired-not-closed/useQuery';
import missingEducatorEvaluationEvents from '../../events-filtered/missing-educator-evaluation/useQuery';
import completedEvents from '../../events-filtered/completed/useQuery';
import schoolYearEvents from '../../events-filtered/school-year/useQuery';

// Partners
import useNetwork from '../../../hooks/useNetwork';

export default function getNavigationLinks() {
  return [
    { name: 'All', href: `/events` },
    // { name: 'All (Table)', href: `/events` },
    { name: 'Starred', href: `/events/starred`, count: useGetCount(starredEvents) },
    { name: 'New', href: `/events/new`, count: useGetCount(newEvents), highlight: true },
    { name: 'Incomplete', href: `/events/draft`, count: useGetCount(draftEvents) },
    { name: 'Needs partners', href: `/events/partners`, count: useGetCount(partnersEvents), highlight: true },
    { name: 'Active Events', href: `/events/active`, count: useGetCount(activeEvents) },
    { name: 'Happening Soon', href: `/events/soon`, count: useGetCount(happeningSoonEvents), highlight: true },
    { name: 'Expired but not Closed', href: `/events/expired-not-closed`, count: useGetCount(expiredNotClosedEvents), highlight: true },
    { name: 'Missing Educator Evaluation', href: `/events/missing-educator-evaluation`, count: useGetCount(missingEducatorEvaluationEvents) },
    { name: 'Completed', href: `/events/completed`, count: useGetCount(completedEvents) },
    { name: 'All Events this School Year', href: `/events/school-year`, count: useGetCount(schoolYearEvents) }
  ];
}
