import React from 'react';
import { BuildingOffice2Icon } from '@heroicons/react/24/outline';
import IconSection from '../../../@communityshare/ui/card/IconSection';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';

export default function Sites(props) {
  const { network } = props;

  const sites = useConnect('site.find', {
    where: {
      eager: {
        $where: {
          'networks.id': network.id
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  return (
    <IconSection
      icon={BuildingOffice2Icon}
      title={sites.state === PayloadStates.FETCHING ?
        '...' :
        sites.meta.totalCount
      }
    />
  );
}
