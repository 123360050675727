import React from 'react';
import StringField from '../../forms/_fields_v2/StringField';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import useDialogLauncher from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../DialogManager';
import MiddleDot from '../../components/MiddleDot';

export default function SchoolDistrict(props) {
  const { site } = props;

  const launch = useDialogLauncher();

  const school = useConnect('school.byId', {
    id: site.data.schoolId
  }, {
    enabled: !!site.data.schoolId
  });

  function onEditSchool() {
    launch(DialogManager.SchoolUpdate.Config(site.data.schoolId));
  }

  return (
    <StringField
      label={(
        <div className="space-x-1">
          <span>School District</span>
          {site.data.schoolId && (
            <>
              <span>
                <MiddleDot/>
              </span>
              <span className="text-cs-blue-500 hover:text-cs-blue-200 cursor-pointer" onClick={onEditSchool}>
                Edit
              </span>
            </>
          )}
        </div>
      )}
      placeholder="[District missing]"
      // description="Some text about a thing I like"
      disabled={true}
      value={school.state === PayloadStates.FETCHING ? '...' : school.data.agencyName}
      onChange={() => {}}
    />
  );
}
