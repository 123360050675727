import React from 'react';
import { useConnect } from '@lore/query-connect';
import REQUIRED_FIELDS from '../../../events/REQUIRED_FIELDS';

export default function useEventStudentImpact(network, schoolStart, schoolEnd, state) {
  const events = useConnect('event.find', {
    where: {
      eager: {
        $where: {
          $and: REQUIRED_FIELDS.$and,
          // networkId: network?.id,
          'site.state': {
            $ilike: state
          },
          completed: true,
          students: {
            $gte: 0
          },
          createdAt: {
            $lte: schoolEnd,
            $gte: schoolStart
          }
        }
      }
    },
    pagination: {
      pageSize: 1000
    }
  });

  const totalCount = _.reduce(events.data, function(result, event) {
    return result + event.data.students;
  }, 0);

  return [
    events,
    totalCount
  ];
}
