import React from 'react';
import { useConnect } from '@lore/query-connect';
import useStewardshipFilter from '../../partners/useStewardshipFilter';
import { PayloadStates } from '@lore/utils';
import MetricLoader from '../MetricLoader';
import moment from 'moment-timezone';
import ComparisonMetric from '../ComparisonMetric';

export default function InvitedToOfferAndResponded(props) {
  const { state, network, schoolStart, schoolEnd, titleOnly } = props;

  const stewardshipFilter = useStewardshipFilter();

  const previousSchoolStart = moment(schoolStart).subtract(1, 'year').toISOString();

  const previousUsers = useConnect('user.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfClaims',
            relation: 'claims',
            $where: {
              offerId: {
                $not: null
              },
              response: {
                $not: 'Pending'
              },
              // 'offer.networkId': network?.id,
              'offer.creator.state': {
                $ilike: state
              },
              createdAt: {
                $lte: schoolStart,
                $gte: titleOnly ? undefined : previousSchoolStart
              }
            }
          }
        ],
        $where: {
          numberOfClaims: {
            $gte: 1
          },
          isEducator: true,
          $or: stewardshipFilter
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  const newUsers = useConnect('user.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfClaims',
            relation: 'claims',
            $where: {
              offerId: {
                $not: null
              },
              response: {
                $not: 'Pending'
              },
              // 'offer.networkId': network?.id,
              'offer.creator.state': {
                $ilike: state
              },
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              }
            }
          }
        ],
        $where: {
          numberOfClaims: {
            $gte: 1
          },
          isEducator: true,
          $or: stewardshipFilter
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  if (
    previousUsers.state === PayloadStates.FETCHING ||
    newUsers.state === PayloadStates.FETCHING
  ) {
    return (
      <MetricLoader
        name="Responded to Offer Invite"
      />
    );
  }

  return (
    <ComparisonMetric
      name="Responded to Offer Invite"
      value={newUsers.meta.totalCount}
      previousValue={previousUsers.meta.totalCount}
      titleOnly={titleOnly}
    />
  );
}
