import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import useNetwork from '../../hooks/useNetwork';
import REQUIRED_FIELDS_PUBLIC_PROJECTS from '../../pages/projects/REQUIRED_FIELDS_PUBLIC';
import REQUIRED_FIELDS_PUBLIC_EVENTS from '../../pages/events/REQUIRED_FIELDS_PUBLIC';
import Project from '../../pages/projects/Project';
import Event from '../../pages/events/Event';
import classNames from 'classnames';
import QuestionChecker from './QuestionChecker';
import InvitationChecker from './InvitationChecker';
import Loader from '../../components/Loader';

export default function ProjectsEvents(props) {
  const { name = '', title = '', onClickProject, onClickEvent, user } = props;

  const title_words = title.split(' ');
  const name_words = name.split(' ');

  const network = useNetwork();

  const projects = useConnect('project.find', {
    where: {
      eager: {
        $where: {
          networkId: network?.id,
          $and: _.remove([
            ...REQUIRED_FIELDS_PUBLIC_PROJECTS.$and,
            title && {
              $or: title_words.map(function(word){
                return {
                  title: {
                    $ilike: `%${word}%`
                  }
                };
              })
              // .concat(title_words.map(function(word){
              //   return {
              //     'activity.name': {
              //       $ilike: `%${word}%`
              //     }
              //   };
              // }))
            },
            name && {
              $or: name_words.map(function(word){
                return {
                  'creator.firstName': {
                    $ilike: `%${word}%`
                  }
                };
              }).concat(name_words.map(function(word){
                return {
                  'creator.lastName': {
                    $ilike: `%${word}%`
                  }
                };
              }))
            }
          ])
        }
      }
    },
    pagination: {
      pageSize: 5,
      order: 'createdAt desc'
    }
  });

  const events = useConnect('event.find', {
    where: {
      eager: {
        $where: {
          networkId: network?.id,
          $and: _.remove([
            ...REQUIRED_FIELDS_PUBLIC_EVENTS.$and,
            title && {
              $or: title_words.map(function(word){
                return {
                  title: {
                    $ilike: `%${word}%`
                  }
                };
              })
              // .concat(title_words.map(function(word){
              //   return {
              //     'activity.name': {
              //       $ilike: `%${word}%`
              //     }
              //   };
              // }))
            },
            name && {
              $or: name_words.map(function(word){
                return {
                  'creator.firstName': {
                    $ilike: `%${word}%`
                  }
                };
              }).concat(name_words.map(function(word){
                return {
                  'creator.lastName': {
                    $ilike: `%${word}%`
                  }
                };
              }))
            }
          ])
        }
      }
    },
    pagination: {
      pageSize: 5,
      order: 'createdAt desc'
    }
  });

  const questions = useConnect('question.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    },
    pagination: {
      pageSize: 1000,
      order: 'createdAt desc'
    }
  });

  const invitations = useConnect('invitation.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    },
    pagination: {
      pageSize: 1000,
      order: 'createdAt desc'
    }
  });

  if (
    projects.state === PayloadStates.FETCHING ||
    events.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  if (
    projects.data.length === 0 &&
    events.data.length === 0
  ) {
    return (
      <div className="text-base text-cs-gray-500">
        There are no active projects or events that match the search criteria.
      </div>
    );
  }

  return (
    <div className={classNames(
      (projects._isPreviousData || events._isPreviousData) && 'opacity-50',
      'w-full grid grid-cols-1 gap-4',
    )}>
      {projects.data.map((project) => {
        return (
          <Project
            key={project.id || project.cid}
            project={project}
            onClick={() => onClickProject(project)}
          >
            <QuestionChecker
              project={project}
              user={user}
              questions={questions}
            />
          </Project>
        );
      })}
      {events.data.map((event) => {
        return (
          <Event
            key={event.id || event.cid}
            event={event}
            onClick={() => onClickEvent(event)}
          >
            <InvitationChecker
              event={event}
              user={user}
              invitations={invitations}
            />
          </Event>
        );
      })}
    </div>
  );
}
