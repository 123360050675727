import React from 'react';
import { UserIcon } from '@heroicons/react/24/outline';
import IconSection from '../../../@communityshare/ui/card/IconSection';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';

export default function Stewards(props) {
  const { network } = props;

  const stewards = useConnect('user.find', {
    where: {
      eager: {
        $where: {
          'networkStewards.networkId': network.id
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  return (
    <IconSection
      icon={UserIcon}
      title={stewards.state === PayloadStates.FETCHING ?
        '...' :
        stewards.meta.totalCount
      }
    />
  );
}
