import React from 'react';
import { PayloadStates } from '@lore/utils';
import ChartLoader from '../../states/_charts/ChartLoader';
import DonutChart from '../../states/_charts/DonutChart';
import colors from 'tailwindcss/colors';
import useQuestions from './_hooks/useQuestions';
import _ from 'lodash';

export const PartnerDeclineReasons = {
  Swamped: `I'm swamped right now, ask me later.`,
  Schedule: `The specified days/times don't fit my schedule.`,
  Time: `The time needed to complete is more than I can give right now.`,
  Skills: `I don't have the needed skills or experience for this project.`,
  Distance: `This is too far away from my home/work.`,
  Interest: `I'm not interested in volunteering my time in this way.`
};

// starts with...
export const StewardDeclineReasons = {
  Email: `Email address for this partner no longer exists`,
  Delay: `Partner has not responded to invitation`,
  Available: 'Partner is not available for your project at this time'
};

export const partnerResponses = [
  PartnerDeclineReasons.Swamped,
  PartnerDeclineReasons.Schedule,
  PartnerDeclineReasons.Time,
  PartnerDeclineReasons.Skills,
  PartnerDeclineReasons.Distance,
  PartnerDeclineReasons.Interest,
  // StewardDeclineReasons.Email,
  // StewardDeclineReasons.Delay,
  // StewardDeclineReasons.Available,
];

export const stewardResponses = [
  // PartnerDeclineReasons.Swamped,
  // PartnerDeclineReasons.Schedule,
  // PartnerDeclineReasons.Time,
  // PartnerDeclineReasons.Skills,
  // PartnerDeclineReasons.Distance,
  // PartnerDeclineReasons.Interest,
  StewardDeclineReasons.Email,
  StewardDeclineReasons.Delay,
  StewardDeclineReasons.Available,
];

export default function QuestionDeclineReasonYear(props) {
  const { state, network, schoolStart, schoolEnd, subtitle } = props;

  const partnerRequests = partnerResponses.map(function(response) {
    return {
      label: response,
      questions: useQuestions(state, network, schoolStart, schoolEnd, {
        responseMessage: {
          $ilike: `%${response}%`
        }
      })
    };
  });

  const stewardRequests = stewardResponses.map(function(response) {
    return {
      label: response,
      isSteward: true,
      questions: useQuestions(state, network, schoolStart, schoolEnd, {
        responseMessage: {
          $ilike: `%${response}%`
        }
      })
    };
  });

  const requests = [
    ...partnerRequests,
    ...stewardRequests
  ]

  const loading = _.reduce(requests, function(result, request) {
    return result || request.questions.state === PayloadStates.FETCHING;
  }, false)

  if (loading) {
    return (
      <ChartLoader
        title="Breakdown of reasons for declining the invitation"
        subtitle={subtitle || 'For school year'}
      />
    );
  }

  const sortedRequests = _.sortBy(requests, r => -r.questions.meta.totalCount);

  const series = sortedRequests.map(r => r.questions.meta.totalCount);
  const labels = sortedRequests.map(r => r.isSteward ? `[Steward] ${r.label}`: r.label);

  return (
    <DonutChart
      title="Breakdown of reasons for declining the invitation"
      subtitle={subtitle || 'For school year'}
      labels={labels}
      series={series}
      colors={[
        // colors.slate['400'],
        // colors.gray['400'],
        // colors.zinc['400'],
        // colors.neutral['400'],
        // colors.stone['400'],
        colors.red['400'],
        colors.orange['400'],
        // colors.amber['400'],
        colors.yellow['400'],
        colors.lime['400'],
        // colors.green['400'],
        colors.emerald['400'],
        colors.teal['400'],
        colors.cyan['400'],
        colors.sky['400'],
        colors.blue['400'],
        colors.indigo['400'],
        colors.violet['400'],
        // colors.purple['400'],
        colors.fuchsia['400'],
        colors.pink['400'],
        colors.rose['400']
      ]}
    />
  );
}
