import React from 'react';

export default function Header(props) {
  const { user } = props;

  return (
    <div className="flex flex-col space-y-2">
      <div className="space-y-0.5">
        <div className="text-lg font-semibold text-cs-gray-900">
          {user.data.firstName} {user.data.lastName}
        </div>
        <div className="text-base text-cs-gray-500 line-clamp-3">
          {user.data.email}
        </div>
      </div>
    </div>
  );
}
