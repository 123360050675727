import React from 'react';
import { useConnect } from '@lore/query-connect';
import REQUIRED_FIELDS from '../../offers/REQUIRED_FIELDS';
import { PayloadStates } from '@lore/utils';
import MetricLoader from '../MetricLoader';
import DeltaMetric from '../DeltaMetric';

export default function TotalYear(props) {
  const { state, network, schoolStart, schoolEnd, titleOnly } = props;

  const allOffers = useConnect('offer.find', {
    where: {
      eager: {
        $where: {
          $and: REQUIRED_FIELDS.$and,
          // networkId: network?.id,
          'creator.state': {
            $ilike: state
          },
          createdAt: {
            $lte: schoolEnd
          }
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  const newOffers = useConnect('offer.find', {
    where: {
      eager: {
        $where: {
          $and: REQUIRED_FIELDS.$and,
          networkId: network?.id,
          createdAt: {
            $lte: schoolEnd,
            $gte: schoolStart
          }
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  if (
    allOffers.state === PayloadStates.FETCHING ||
    newOffers.state === PayloadStates.FETCHING
  ) {
    return (
      <MetricLoader
        name="Total"
      />
    );
  }

  return (
    <DeltaMetric
      name="Total"
      value={allOffers.meta.totalCount}
      delta={newOffers.meta.totalCount}
      titleOnly={titleOnly}
    />
  );
}
