import React  from 'react';
import SupportingTextColumn from '../../_components/table/columns/SupportingTextColumn';

export default function Superuser(props) {
  const { user } = props;

  return (
    <SupportingTextColumn
      title={user.data.isSuperuser ? 'Yes' : ''}
      // subtitle=""
    />
  );
}
