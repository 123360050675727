import React from 'react';
import { PayloadStates } from '@lore/utils';
import ChartLoader from '../../states/_charts/ChartLoader';
import DonutChart from '../../states/_charts/DonutChart';
import colors from 'tailwindcss/colors';
import useClaimsReal from './_hooks/useClaimsReal';

export default function ClaimCreatorYear(props) {
  const { state, network, schoolStart, schoolEnd, subtitle } = props;

  const claims = useClaimsReal(state, network, schoolStart, schoolEnd, undefined, {
    offer: {}
  });

  const _creatorData = claims.data.filter(q => q.data.offer.creatorId === q.data.creatorId);
  const creatorClaims = {
    state: claims.state,
    data: _creatorData,
    meta: {
      totalCount: _creatorData.length
    }
  };

  const _stewardData = claims.data.filter(q => q.data.offer.creatorId !== q.data.creatorId);
  const stewardClaims = {
    state: claims.state,
    data: _stewardData,
    meta: {
      totalCount: _stewardData.length
    }
  };

  const requests = [
    {
      label: 'Invited by Partner',
      claims: creatorClaims
    },
    {
      label: 'Invited by Steward',
      claims: stewardClaims
    }
  ]

  const loading = _.reduce(requests, function(result, request) {
    return (
      result ||
      request.claims.state === PayloadStates.FETCHING
    );
  }, false)

  if (loading) {
    return (
      <ChartLoader
        title="Breakdown of who invited the educator"
        subtitle={subtitle || 'For school year'}
      />
    );
  }

  const series = requests.map(r => r.claims.meta.totalCount);
  const labels = requests.map(r => r.label);

  return (
    <DonutChart
      title="Breakdown of who invited the educator"
      subtitle={subtitle || 'For school year'}
      labels={labels}
      series={series}
      colors={[
        colors.emerald['400'],
        colors.yellow['400']
      ]}
    />
  );
}
