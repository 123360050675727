import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import MetricLoader from '../MetricLoader';
import DeltaMetric from '../DeltaMetric';

export default function TotalYear(props) {
  const { network, schoolStart, schoolEnd, titleOnly } = props;

  const allSchools = useConnect('organization.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfEducators',
            relation: 'users',
            $where: {
              isEducator: false,
              // 'networks.id': network?.id
              networkId: network?.id
            }
          }
        ],
        $where: {
          numberOfEducators: {
            $gt: 0
          },
          createdAt: {
            $lte: schoolEnd
          }
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  const newSchools = useConnect('organization.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfEducators',
            relation: 'users',
            $where: {
              isEducator: false,
              // 'networks.id': network?.id
              networkId: network?.id
            }
          }
        ],
        $where: {
          numberOfEducators: {
            $gt: 0
          },
          createdAt: {
            $lte: schoolEnd,
            $gte: schoolStart
          }
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  if (
    allSchools.state === PayloadStates.FETCHING ||
    newSchools.state === PayloadStates.FETCHING
  ) {
    return (
      <MetricLoader
        name="Total"
      />
    );
  }

  return (
    <DeltaMetric
      name="Total"
      value={allSchools.meta.totalCount}
      delta={newSchools.meta.totalCount}
      titleOnly={titleOnly}
    />
  );
}
