import React, { useState } from 'react';
import _ from 'lodash';
import { PayloadStates } from '@lore/utils';
import moment from 'moment-timezone';
import ChartLoader from '../../states/_charts/ChartLoader';
import LineChart from '../../states/_charts/LineChart';
import colors from 'tailwindcss/colors';
import useQuestions from './_hooks/useQuestions';

export default function QuestionsByMonth(props) {
  const { state, network, timestamps, empty, schoolStart } = props;

  const [currentTimestamp] = useState(moment().toISOString());

  const educators = timestamps.map(function(timestamp, index) {
    if (moment(currentTimestamp).diff(timestamp.start) < 0) {
      return {
        ...timestamp,
        results: {
          created: empty(),
          connected: empty(),
          pending: empty(),
          declined: empty()
        }
      }
    }

    return {
      ...timestamp,
      results: {
        created: useQuestions(state, network, timestamp.start, timestamp.end),
        connected: useQuestions(state, network, timestamp.start, timestamp.end, {
          response: 'Connect'
        }),
        pending: useQuestions(state, network, timestamp.start, timestamp.end, {
          response: 'Pending'
        }),
        declined: useQuestions(state, network, timestamp.start, timestamp.end, {
          response: 'Decline'
        })
      }
    }
  });

  const loading = _.reduce(educators, function(result, educator) {
    return (
      result ||
      educator.results.created.state === PayloadStates.FETCHING ||
      educator.results.connected.state === PayloadStates.FETCHING ||
      educator.results.pending.state === PayloadStates.FETCHING ||
      educator.results.declined.state === PayloadStates.FETCHING
    );
  }, false)

  if (loading) {
    return (
      <ChartLoader
        title="Project invitation breakdown"
        subtitle="Over school year"
      />
    );
  }

  const series = [
    {
      name: 'Sent',
      data: educators.map(e => e.results.created.meta.totalCount)
    },
    {
      name: 'Connected',
      data: educators.map(e => e.results.connected.meta.totalCount)
    },
    {
      name: 'Pending',
      data: educators.map(e => e.results.pending.meta.totalCount)
    },
    {
      name: 'Declined',
      data: educators.map(e => e.results.declined.meta.totalCount)
    }
  ];

  return (
    <LineChart
      title="Project invitation breakdown"
      subtitle="Over school year"
      labels={educators.map(e => e.label)}
      series={series}
      colors={[
        colors.neutral['400'],
        colors.emerald['400'],
        colors.yellow['400'],
        colors.red['400']
      ]}
    />
  );
}
