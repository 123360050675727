import React from 'react';
import Header from './Header';
import Date from './Date';
import Creator from './Creator';
import Participation from './Participation';
import classNames from 'classnames';
import School from './School';
import useDialogLauncher from '../../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import Partners from '../Row/Partners';
import Notes from '../Row/Notes';
import StatusTags from '../Row/StatusTags';
import Bookmark from '../../_components/Bookmark';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import Activity from './Activity';
import Network from './Network';
import { useUser } from '@lore/auth';

export default function Project(props) {
  const { project, className, children, notes = true } = props;

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  });

  const currentUser = useUser();

  const launch = useDialogLauncher();

  function onClick() {
    if (props.onClick) {
      props.onClick();
    } else {
      launch(DialogManager.ProjectManage.Config(project.id));
    }
  }

  return (
    <div className={classNames(
      'flex flex-col bg-white',
      'shadow-cs-flat rounded-cs-10',
      '-m-0.5 border-2 border-cs-gray-100',
      'relative overflow-hidden',
      // 'hover:bg-cs-orange-50 cursor-pointer',
      className
    )}>
      {/*<Link to={`/projects/${project.id}`} className="absolute w-full h-full" />*/}
      <div className="absolute z-10 top-4 right-4 pt-0.5x pr-0.5x">
        <Bookmark
          type="project"
          resource={project}
        />
      </div>
      <div className="flex flex-col py-4 px-4 space-y-2 hover:bg-cs-orange-50 cursor-pointer" onClick={onClick}>
        {/*<div className="flex space-x-2 min-h-5">*/}
        {/*  /!*<Activity activity={activity} />*!/*/}
        {/*  <StatusTags project={project} />*/}
        {/*</div>*/}
        <StatusTags project={project} />
        <div className="pr-10">
          <Header project={project} />
        </div>
        <div className="flex flex-col sm:flex-row items-start justify-between">
          <div className="flex flex-1 flex-wrap gap-x-4 gap-y-2 mb-2 sm:mb-0">
            <Date project={project}/>
            {/*<Time project={project} />*/}
            <Creator project={project}/>
            <School project={project}/>
            {currentUser.data.isStaff && (
              <Network project={project}/>
            )}
          </div>
          {/*<Distance project={project} />*/}
          <Partners project={project}/>
        </div>
      </div>
      {/*{false && (*/}
      {/*  <div className={classNames(*/}
      {/*    'w-full py-4 px-4',*/}
      {/*    'bg-cs-gray-50',*/}
      {/*    '-mt-px border-t border-cs-gray-100',*/}
      {/*  )}>*/}
      {/*    <Participation project={project} />*/}
      {/*  </div>*/}
      {/*)}*/}
      {/*<Participation project={project} />*/}
      {children}
      {notes && (
        <div className="bg-gray-50 p-4">
          <Notes project={project}/>
        </div>
      )}
    </div>
  );
}
