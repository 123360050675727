import React from 'react';
import { useConfig } from '@lore/config';

export default function Header(props) {
  const { network } = props;

  const { tenant } = useConfig();

  return (
    // <div className="flex space-x-4 ">
    //   <div>
    //     <img
    //       src={tenant.networkLogo[network.data.subdomain]}
    //       className="w-12 h-12 object-contain"
    //       alt="Logo"
    //     />
    //   </div>
      <div className="flex flex-col space-y-2">
        <div className="space-y-0.5">
          <div className="text-lg font-semibold text-cs-gray-900">
            {network.data.name}
          </div>
          <div className="text-base text-cs-gray-500 line-clamp-3">
            {network.data.city}, {network.data.state}
          </div>
        </div>
      </div>
    // </div>
  );
}
