import React from 'react';
import ParentLayout from '../Layout';
import useRouter from '../../../hooks/useRouter';
import Organization from './Organization';
import useQuery from './useQuery';

export default function Layout(props) {
  const { location } = useRouter();

  const PAGE_SIZE = 10;

  const result = useQuery({
    location,
    PAGE_SIZE
  });

  return (
    <ParentLayout
      breadcrumbs={['Explore', 'Sites']}
      title="Orgs/Roles to cleanup"
      description={(
        <div className="space-y-2">
          <div>
            These are educator that have roles that are not linked to a site. Instead they are linked
            to organizations, because prior to Aug 2023, educators specified their sites by looking
            them on via Google Maps (similar to partners).
          </div>
          <div>
            In order to map to the new data models (and be valid), one of these actions needs to be
            taken: profile is deleted, profile is switched to a partner, role is deleted, or the
            organization is added to the list of sites (which will allow the role to map to a site).
          </div>
        </div>
      )}
      pageSize={PAGE_SIZE}
      result={result}
      row={function (organization) {
        return (
          <Organization
            key={organization.id}
            organization={organization}
          />
        );
      }}
    />
  );
}
