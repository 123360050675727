import { useState } from 'react';
import { useConnect } from '@lore/query-connect';
import { parse } from 'query-string';
import moment from 'moment-timezone';
import useStewardshipFilter from './useStewardshipFilter';
import getQueryUtils, { attributeKeys } from './queryUtils';
import _ from 'lodash';

export default function useQuery(props) {
  const {
    search = {},
    pageSize = 4,
    returnRawQuery = false,
    distance = {}
  } = props;

  const [timestamp] = useState(moment().toISOString());
  const stewardshipFilter = useStewardshipFilter();

  const queryUtils = getQueryUtils(search, attributeKeys, timestamp);

  const query = {
    where: {
      distance: _.pick(distance, ['latitude', 'longitude']),
      eager: {
        $aggregations: [
          // {
          //   type: 'count',
          //   alias: 'numberOfPartnerInteractions',
          //   relation: 'partnerInteractions'
          // },
          {
            type: 'sum',
            alias: 'numberOfHoursServed',
            field: 'hours',
            relation: 'partnerProjectEvaluations'
          },
          // {
          //   type: 'sum',
          //   alias: 'numberOfStudentsServed',
          //   field: 'served',
          //   relation: 'partnerInteractions.evaluations'
          // },
          {
            type: 'sum',
            alias: 'totalOfContributionsProvided',
            field: 'contributionValue',
            relation: 'partnerProjectEvaluations'
          }
        ],
        $where: {
          isEducator: false,
          $and: [...queryUtils.where],
          $or: stewardshipFilter
        }
      }
    },
    pagination: {
      page: search.page || '1',
      pageSize: pageSize,
      order: search.order || 'createdAt desc'
    }
  };

  const result = useConnect('user.find', query, {
    enabled: !returnRawQuery,
    keepPreviousData: true
  });

  if (returnRawQuery) {
    return query;
  }

  return result;
}
