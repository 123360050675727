import React from 'react';
import PropTypes from 'prop-types';
import * as filestack from 'filestack-js';
import { useConfig } from '@lore/config';
import classNames from 'classnames';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import Tooltip from '../../pages/_post-opportunity/forms/Tooltip';
import { PhotoIcon } from '@heroicons/react/24/outline';

ImageField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.node,
  placeholder: PropTypes.string,
  description: PropTypes.node,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.node,
  optional: PropTypes.bool
};

ImageField.defaultProps = {
  disabled: false
};

export default function ImageField(props) {
  const {
    value,
    defaultValue,
    label,
    placeholder,
    description,
    disabled,
    onChange,
    error,
    inputRef,
    optional = false,
    required = false,
    tooltip,
    icon,
    shadow = true,
    border = true,
    focus = true
  } = props;

  const config = useConfig();

  function onEditImage() {
    const {
      apiKey,
      imageMax,
      acceptImages
    } = config.filestack;

    const client = filestack.init(apiKey);
    client.picker({
      accept: acceptImages,
      imageMax: imageMax,
      uploadInBackground: false,
      onUploadDone: function(result) {
        // console.log(JSON.stringify(result.filesUploaded));
        const uploadedFile = result.filesUploaded[0];

        if (uploadedFile) {
          onChange(uploadedFile.url);
        }
      }
    }).open();
  }

  return (
    <div>
      {label && (
        <div className="relative flex space-x-0.5 text-sm text-cs-gray-500 mb-1">
          {required && (
            <span className="inline-block text-cs-orange-500">*</span>
          )}
          <span className="flex items-center space-x-1">
            <div className="text-base text-cs-gray-900 font-normal">
              {label}
            </div>
            {tooltip && (
              <div>
              <Tooltip tooltip={tooltip} label={true} />
              </div>
            )}
          </span>
          {optional && (
            <div className="absolute top-0 right-0 text-cs-gray-300 text-sm leading-6">
              Optional
            </div>
          )}
        </div>
      )}
      <div className="relative">
        {icon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <props.icon className="h-5 w-5 text-cs-gray-400" aria-hidden="true" />
          </div>
        )}
        <div ref={inputRef}>
          {value ? (
            <img
              src={value}
              className={classNames(
                'w-24 h-24 object-contain',
                shadow && 'shadow-cs-flat',
                border && '-m-px border rounded-md',
                border && !error && 'border-cs-gray-300',
                border && error && 'border-red-500',
                disabled ? 'cursor-not-allowed' : 'hover:opacity-70 cursor-pointer',
              )}
              alt="Logo"
              onClick={disabled ? undefined : onEditImage}
            />
          ) : (
            <PhotoIcon
              className={classNames(
                'w-24 h-24 object-contain',
                'p-4 text-cs-gray-400',
                shadow && 'shadow-cs-flat',
                border && '-m-px border rounded-md',
                border && !error && 'border-cs-gray-300',
                border && error && 'border-red-500',
                disabled ? 'cursor-not-allowed' : 'hover:opacity-70 cursor-pointer',
              )}
              alt="Logo"
              onClick={disabled ? undefined : onEditImage}
            />
          )}
        </div>
        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      {error ? (
        <div className="mt-2 text-sm text-red-500">
          {error}
        </div>
      ) : null}
      {description ? (
        <div className="text-sm text-cs-gray-500 font-normal mt-2">
          {description}
        </div>
      ) : null}
    </div>
  );
}
