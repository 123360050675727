import React from 'react';
import useNetwork from '../../../hooks/useNetwork';
import useGetCount from '../../explore/useGetCount';
import allNetworks from '../../manage-networks/useQuery';
import allSites from '../../manage-sites/useQuery';
import allStaff from '../../manage-staff/useQuery';
import allStewards from '../../manage-stewards/useQuery';
import allLeads from '../../manage-leads/useQuery';

export default function getNavigationLinks() {
  return [
    { name: 'Networks', href: `/manage/networks`, count: useGetCount(allNetworks) },
    { name: 'Sites', href: `/manage/sites`, count: useGetCount(allSites) },
    { name: 'Staff', href: `/manage/staff`, count: useGetCount(allStaff) },
    { name: 'Stewards', href: `/manage/stewards`, count: useGetCount(allStewards) },
    { name: 'Leads', href: `/manage/leads`, count: useGetCount(allLeads) }
  ];
}
