import React from 'react';
import { PayloadStates } from '@lore/utils';
import MetricLoader from '../MetricLoader';
import DeltaMetric from '../DeltaMetric';
import useProjectStudentImpact from './_hooks/useProjectStudentImpact';
import useEventStudentImpact from './_hooks/useEventStudentImpact';
import moment from 'moment-timezone';
import ComparisonMetric from '../ComparisonMetric';

export default function ImpactYear(props) {
  const { network, schoolStart, schoolEnd, titleOnly, showTotals = true } = props;

  const previousSchoolStart = titleOnly ? undefined : moment(schoolStart).subtract(1, 'year').toISOString();

  const [totalProjects, totalProjectsCount] = useProjectStudentImpact(network, undefined, schoolEnd);
  const [previousProjects, previousProjectsCount] = useProjectStudentImpact(network, previousSchoolStart, schoolStart);
  const [newProjects, newProjectsCount] = useProjectStudentImpact(network, schoolStart, schoolEnd);

  const [totalEvents, totalEventsCount] = useEventStudentImpact(network, undefined, schoolEnd);
  const [previousEvents, previousEventsCount] = useEventStudentImpact(network, previousSchoolStart, schoolStart);
  const [newEvents, newEventsCount] = useEventStudentImpact(network, schoolStart, schoolEnd);


  if (
    totalProjects.state === PayloadStates.FETCHING ||
    previousProjects.state === PayloadStates.FETCHING ||
    newProjects.state === PayloadStates.FETCHING ||
    totalEvents.state === PayloadStates.FETCHING ||
    previousEvents.state === PayloadStates.FETCHING ||
    newEvents.state === PayloadStates.FETCHING
  ) {
    return (
      <>
        {showTotals && (
          <>
            <MetricLoader
              name="Total students impacted"
            />
            <MetricLoader
              name="Total students impacted by projects"
            />
            <MetricLoader
              name="Total students impacted by events"
            />
          </>
        )}
        {!titleOnly && (
          <>
            <MetricLoader
              name="Students impacted"
            />
            <MetricLoader
              name="Students impacted by projects"
            />
            <MetricLoader
              name="Students impacted by events"
            />
          </>
        )}
      </>
    );
  }

  return (
    <>
      {showTotals && (
        <>
          <DeltaMetric
            name="Total students impacted"
            value={totalProjectsCount + totalEventsCount}
            delta={newProjectsCount + newEventsCount}
            titleOnly={titleOnly}
          />
          <DeltaMetric
            name="Total students impacted by projects"
            value={totalProjectsCount}
            delta={newProjectsCount}
            titleOnly={titleOnly}
          />
          <DeltaMetric
            name="Total students impacted by events"
            value={totalEventsCount}
            delta={newEventsCount}
            titleOnly={titleOnly}
          />
        </>
      )}
      {!titleOnly && (
        <>
          <ComparisonMetric
            name="Students impacted"
            value={newProjectsCount + newEventsCount}
            previousValue={previousProjectsCount + previousEventsCount}
            titleOnly={titleOnly}
          />
          <ComparisonMetric
            name="Students impacted by projects"
            value={newProjectsCount}
            previousValue={previousProjectsCount}
            titleOnly={titleOnly}
          />
          <ComparisonMetric
            name="Students impacted by events"
            value={newEventsCount}
            previousValue={previousEventsCount}
            titleOnly={titleOnly}
          />
        </>
      )}
    </>
  );
}
