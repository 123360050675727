import { extractArrayFromQuery, mapAttributes } from '../../../utils/query';

import createdDates from './createdDates';
import educatorInteractions from './educatorInteractions';
import name from './name';
import organization from './organization';
import district from './district';
import projects from './projects';
import events from './events';
import searchEducator from './searchEducator';

function tag() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          'tagNodes.tagId': {
            $in_subquery: values
          }
        }
      }
    }
  };
}

function tagTree() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          'tagTrees.parentId': {
            $in: values
          }
        }
      }
    }
  };
}

const attributes = {
  activities: tag(),
  createdDates,
  // educatorInteractions,
  grades: tag(),
  // locationCondition,
  name,
  organization,
  district,
  practices: tag(),
  projects,
  events,
  searchEducator,
  subjects: tag()
};

export const attributeKeys = _.keys(attributes);

export default mapAttributes(attributes);
