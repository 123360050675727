import React from 'react';
import { DialogManager } from '../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../hooks/@lore/dialogs-routable/_hooks/useDialogLauncher';
import User from './User';
import { useConnect } from '@lore/query-connect';
import _ from 'lodash';
import mapResponse from '../../utils/mapResponse';
import { getDistanceFromLatLonInMiles } from '../../components/User/utils';
import { PayloadStates } from '@lore/utils';

export default function OfferUser(props) {
  const {
    offer,
    user,
    children
  } = props;

  const launch = useDialogLauncher();

  const claims = useConnect('claim.find', {
    where: {
      eager: {
        $where: {
          offerId: offer.id
        }
      }
    },
    pagination: {
      pageSize: 1000
    }
  });

  const creator = useConnect('user.byId', {
    id: offer.data.creatorId
  });

  const claim = _.find(claims.data, function(claim) {
    return claim.data.userId === user.id;
  });

  const selected = !!claim;

  function onClick() {
    launch(DialogManager.UserView.Config(user.id, {
      modelName: 'offer',
      modelId: offer.id
    }));
  }

  const distance = getDistanceFromLatLonInMiles(creator.data, user.data);
  const distanceText = distance > 1 ?
    `${distance.toLocaleString()} miles` :
    `1 mile`;

  return (
    <User
      user={user}
      selected={selected}
      footer={claim ? mapResponse(claim.data.response) : undefined}
      distance={creator.state === PayloadStates.FETCHING ? '...' : distanceText}
      children={children}
      onClick={onClick}
    />
  );
}
