import getPageNavigationLinks from './pageNavigationLinks';
import useNetwork from '../../../hooks/useNetwork';
import { useUser } from '@lore/auth';

export default function useNavigationLinks(props) {
  const network = useNetwork();
  const currentUser = useUser();

  const pageNavigationLinks = getPageNavigationLinks(network, currentUser);

  return {
    pageLinks: pageNavigationLinks
  };
}
