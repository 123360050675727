import { extractArrayFromQuery, mapAttributes } from '../../../utils/query';

import networks from './networks';
import educator from './educator';
import recommendPartners from './recommendPartners';
import school from './school';
import statuses from './statuses';
import testimonials from './testimonials';
import title from './title';
import trainings from './trainings';
import ratings from './ratings';
import activities from './activities';

function projectTag() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          'evaluationProjectEducatorProjectTagNodes.tagId': {
            $in_subquery: values
          }
        }
      }
    }
  };
}

const attributes = {
  networks,
  educator,
  // evalExperiences,
  // partner,
  recommendPartners,
  school,
  statuses,
  testimonials,
  title,
  trainings,

  supportedRatings: ratings('supportedRating'),
  engagedRatings: ratings('engagedRating'),
  skillsRatings: ratings('skillsRating'),
  awarenessRatings: ratings('awarenessRating'),
  understandingRatings: ratings('understandingRating'),
  practicedRatings: ratings('practicedRating'),

  activities,
  ages: projectTag(),
  commitments: projectTag(),
  // days: projectTag(),
  expertiseTypes: projectTag(),
  groups: projectTag(),
  guidances: projectTag(),
  interactionTypes: projectTag(),
  // participants: projectTag(),
  subjects: projectTag(),
  // times: projectTag(),
};

export const attributeKeys = _.keys(attributes);

export default mapAttributes(attributes);
