import React from 'react';
import PropTypes from 'prop-types';
import classNames from '../../utils/classNames';
import { useConfig } from '@lore/config';
import Card from '../../components/Card';

Community.propTypes = {
  network: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

export default function Community(props) {
  const {
    network,
    onClick
  } = props;

  const { tenant } = useConfig();

  return (
    <Card
      background={false}
      border={false}
      className={classNames(
        'flex p-6 space-x-4 cursor-pointer max-w-lg hover:bg-cs-orange-50',
        '-m-px border border-cs-gray-200 bg-white'
      )}
      onClick={() => onClick(network)}
    >
      <div>
        <img
          src={tenant.networkLogo[network.data.subdomain]}
          className="w-12 h-12 object-contain"
          alt="Logo"
        />
      </div>
      <div className="flex-1">
        <h2 className="text-base font-semibold text-cs-gray-900">
          {network.data.name}
        </h2>
        <div className="text-sm text-cs-gray-500">
          {network.data.city}, {network.data.state}
        </div>
      </div>
    </Card>
  );
};
