import React from 'react';
import { PayloadStates } from '@lore/utils';
import ChartLoader from '../../dashboard/_charts/ChartLoader';
import DonutChart from '../../dashboard/_charts/DonutChart';
import colors from 'tailwindcss/colors';
import useClaims from './_hooks/useClaims';
import _ from 'lodash';

export const EducatorDeclineReasons = {
  Swamped: `I'm too busy right now, but ask me later.`,
  Schedule: `I can't make the day or time work in my schedule.`,
  Time: `I can't invest the time this will take.`,
  Learners: `I don't think my learners are ready for this.`,
  Distance: `This is located too far away for me and my learners.`,
  Fit: `This just isn't a good fit for my curriculum or needs.`
};

// starts with...
export const StewardDeclineReasons = {
  Email: `Email address for this educator no longer exists`,
  Delay: `Educator has not responded to invitation`,
  Available: 'Educator is not available for your offer at this time'
};

export const educatorResponses = [
  EducatorDeclineReasons.Swamped,
  EducatorDeclineReasons.Schedule,
  EducatorDeclineReasons.Time,
  EducatorDeclineReasons.Learners,
  EducatorDeclineReasons.Distance,
  EducatorDeclineReasons.Fit,
  // StewardDeclineReasons.Email,
  // StewardDeclineReasons.Delay,
  // StewardDeclineReasons.Available,
];

export const stewardResponses = [
  // EducatorDeclineReasons.Swamped,
  // EducatorDeclineReasons.Schedule,
  // EducatorDeclineReasons.Time,
  // EducatorDeclineReasons.Learners,
  // EducatorDeclineReasons.Distance,
  // EducatorDeclineReasons.Fit,
  StewardDeclineReasons.Email,
  StewardDeclineReasons.Delay,
  StewardDeclineReasons.Available,
];

export default function ClaimDeclineReasonYear(props) {
  const { network, schoolStart, schoolEnd, subtitle } = props;

  const educatorRequests = educatorResponses.map(function(response) {
    return {
      label: response,
      claims: useClaims(network, schoolStart, schoolEnd, {
        responseMessage: {
          $ilike: `%${response}%`
        }
      })
    };
  });

  const stewardRequests = stewardResponses.map(function(response) {
    return {
      label: response,
      isSteward: true,
      claims: useClaims(network, schoolStart, schoolEnd, {
        responseMessage: {
          $ilike: `%${response}%`
        }
      })
    };
  });

  const requests = [
    ...educatorRequests,
    ...stewardRequests
  ]

  const loading = _.reduce(requests, function(result, request) {
    return result || request.claims.state === PayloadStates.FETCHING;
  }, false)

  if (loading) {
    return (
      <ChartLoader
        title="Breakdown of reasons for declining the invitation"
        subtitle={subtitle || 'For school year'}
      />
    );
  }

  const sortedRequests = _.sortBy(requests, r => -r.claims.meta.totalCount);

  const series = sortedRequests.map(r => r.claims.meta.totalCount);
  const labels = sortedRequests.map(r => r.isSteward ? `[Steward] ${r.label}`: r.label);

  return (
    <DonutChart
      title="Breakdown of reasons for declining the invitation"
      subtitle={subtitle || 'For school year'}
      labels={labels}
      series={series}
      colors={[
        // colors.slate['400'],
        // colors.gray['400'],
        // colors.zinc['400'],
        // colors.neutral['400'],
        // colors.stone['400'],
        colors.red['400'],
        colors.orange['400'],
        // colors.amber['400'],
        colors.yellow['400'],
        colors.lime['400'],
        // colors.green['400'],
        colors.emerald['400'],
        colors.teal['400'],
        colors.cyan['400'],
        colors.sky['400'],
        colors.blue['400'],
        colors.indigo['400'],
        colors.violet['400'],
        // colors.purple['400'],
        colors.fuchsia['400'],
        colors.pink['400'],
        colors.rose['400']
      ]}
    />
  );
}
