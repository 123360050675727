import React  from 'react';
import UserAvatar from '../../../components/UserAvatar';

export default function Name(props) {
  const { user } = props;

  return (
    <td className="px-6 py-4 whitespace-nowrapx">
      <div className="flex items-center">
        <div className="flex-shrink-0 h-10 w-10">
          <UserAvatar user={user} />
        </div>
        <div className="ml-4">
          <div className="text-sm font-medium text-gray-900">
            {user.data.firstName} {user.data.lastName}
          </div>
          <div className="text-sm text-gray-500">
            {user.data.email}
          </div>
        </div>
      </div>
    </td>
  );
}
